
import axios from '@/plugins/axios.js';
import { Provide, Component, Mixins } from 'vue-property-decorator';
import moment from 'moment';
import CourseClassifyMixins from '@/views/shared/CourseClassifyMixins.vue';
import DataSummaryTrendsChart from './widgets/DataSummaryTrendsChart.vue';

@Component({
    components: {
        DataSummaryTrendsChart,
    },
})
export default class PartnerDataSummary extends Mixins(CourseClassifyMixins) {
    @Provide() private partners: any = [];
    @Provide() private dailyTrends: any = {};
    @Provide() private totalTrends: any = [];
    @Provide() private queriedCondition: { [index: string]: any } = {
        name: null,
        code: null,
        dateTimeRange: [
            moment().startOf('month').format('YYYY-MM-DD HH:mm'),
            moment().format('YYYY-MM-DD HH:mm'),
        ],
        classifies: [],
        addresses: [],
        sort: { prop: 'joinedStudentCount', order: 'DESC' },
        page: 1,
    };
    @Provide() private pageSize: number = 10;
    @Provide() private loading: boolean = true;
    @Provide() private total: number = 0;
    @Provide() private addressesOptions: any = [];
    @Provide() private pickerOptions = {
        shortcuts: [
            {
                text: '今天',
                onClick(picker) {
                    const start = moment().startOf('day').format('YYYY-MM-DD HH:mm');
                    const end = moment().format('YYYY-MM-DD HH:mm');
                    picker.$emit('pick', [start, end]);
                },
            },
            {
                text: '前一天',
                onClick(picker) {
                    const start = moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm');
                    const end = moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD HH:mm');
                    picker.$emit('pick', [start, end]);
                },
            },
            {
                text: '本月',
                onClick(picker) {
                    const start = moment().startOf('month').format('YYYY-MM-DD HH:mm');
                    const end = moment().format('YYYY-MM-DD HH:mm');
                    picker.$emit('pick', [start, end]);
                },
            },
            {
                text: '前一月',
                onClick(picker) {
                    const start = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm');
                    const end = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm');
                    picker.$emit('pick', [start, end]);
                },
            },
        ],
    };
    private mounted() {
        this.getSummary();
    }
    private changePage(page: number) {
        this.queriedCondition.page = page;
        this.getSummary();
    }
    private changeSort(val: any) {
        this.queriedCondition.sortProp = val.prop;
        this.queriedCondition.sortOrder = val.order === 'ascending' ? 'ASC' : 'DESC';
        this.queriedCondition.page = 1;
        this.getSummary();
    }
    private querySummary() {
        this.queriedCondition.page = 1;
        this.getSummary();
    }
    private getSummary() {
        if (!this.queriedCondition.dateTimeRange) {
            return this.$message.error('请选择筛选时间');
        }
        if (moment(this.queriedCondition.dateTimeRange[1]).diff(moment(this.queriedCondition.dateTimeRange[0]), 'days') > 100) {
            return this.$message.error('汇总时间最大可选时间跨度为 100 天');
        }
        this.loading = true;
        axios.get('/api/v1/partner-data-summary/list', {
            ...this.queriedCondition,
            start: this.queriedCondition.dateTimeRange[0],
            end: this.queriedCondition.dateTimeRange[1],
            province: this.queriedCondition.addresses[0],
            city: this.queriedCondition.addresses[1],
            district: this.queriedCondition.addresses[2],
        }).then(({ partners, dailyTrends, courseClassify, addresses, total }) => {
            this.partners = partners;
            this.dailyTrends = dailyTrends;
            this.totalTrends = [dailyTrends];
            this.courseClassify = courseClassify;
            this.addressesOptions = this.updateAddressesOptions(addresses);
            this.total = total;
            this.loading = false;
        }).catch((error) => {
            this.$message.error(error);
        });
    }
    private updateAddressesOptions(addresses) {
        const addressesOptions: any = [];
        for (const province of addresses) {
            let option = { value: province.name, label: province.name, children: new Array() };
            for (const city of province.city_list) {
                let subOption = { value: city.name, label: city.name, children: new Array() };
                for (const district of city.district_list) {
                    subOption.children.push({ value: district.name, label: district.name });
                }
                option.children.push(subOption);
            }
            addressesOptions.push(option);
        }
        return addressesOptions;
    }
}
