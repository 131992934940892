
import Vue from 'vue';
import axios from '../../plugins/axios.js';
import { Provide, Component } from 'vue-property-decorator';
import InputSimpleCustomer from './widgets/InputSimpleCustomer.vue';
import InputMultipleCustomers from './widgets/InputMultipleCustomers.vue';
import InputRelatedCustomer from './widgets/InputRelatedCustomer.vue';
@Component({
    components: {
        InputSimpleCustomer,
        InputMultipleCustomers,
        InputRelatedCustomer,
    },
})
export default class CustomerManagement extends Vue {
    @Provide() private inputMethods: any = [];
    @Provide() private activeTab: string = '';

    private mounted() {
        this.getCustomerInputMethods();
    }

    private getCustomerInputMethods() {
        axios.get('/api/v1/crm/customer-input-methods').then((inputMethods) => {
            this.inputMethods = inputMethods;
            if (this.inputMethods.length > 0) {
                this.activeTab = this.inputMethods[0].value;
            }
        }).catch((error) => {
            this.$message.error(error);
        });
    }
}
