
import Vue from 'vue';
import moment from 'moment';
import axios from '../../plugins/axios.js';
import { Provide, Component } from 'vue-property-decorator';

@Component({})

export default class ProblemSetEvaluationManage extends Vue {
    @Provide() private records = [];
    @Provide() private evaluationSummary: any = [];
    @Provide() private queriedCondition: { [index: string]: any } = {
        uuid: '',
        dateTimeRange: [
            moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm'),
            moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD HH:mm'),
        ],
        page: 1,
    };
    @Provide() private pageSize: number = 10;
    @Provide() private total: number = 0;
    @Provide() private areRecordsLoading: boolean = true;
    @Provide() private areSummaryLoading: boolean = false;
    @Provide() private pickerOptions = {
        shortcuts: [
            {
                text: '上一周',
                onClick(picker) {
                    const start = moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD HH:mm');
                    const end = moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD HH:mm');
                    picker.$emit('pick', [start, end]);
                },
            },
        ],
    };

    private mounted() {
        this.queryRecordsAndSummary();
    }

    private changePage(page: number) {
        this.queriedCondition.page = page;
        this.getRecords();
    }

    private queryRecordsAndSummary() {
        if (!this.queriedCondition.dateTimeRange) {
            return this.$message.error('请选择筛选时间');
        }
        this.getRecords();
        this.getEvaluationSummary();
    }

    private getRecords() {
        const params = {
            uuid: this.queriedCondition.uuid,
            start: this.queriedCondition.dateTimeRange[0],
            end: this.queriedCondition.dateTimeRange[1],
            page: this.queriedCondition.page,
        };
        this.areRecordsLoading = true;
        axios.get('/api/v1/problem-set-evaluation-manage/records', params).then(({ records, total }) => {
            this.records = records;
            this.total = total;
            this.areRecordsLoading = false;
        }).catch((error) => {
            this.areRecordsLoading = false;
            this.$message.error(error);
        });
    }

    private getEvaluationSummary() {
        const params = {
            uuid: this.queriedCondition.uuid,
            start: this.queriedCondition.dateTimeRange[0],
            end: this.queriedCondition.dateTimeRange[1],
        };
        this.areSummaryLoading = true;
        axios.get('/api/v1/problem-set-evaluation-manage/summary', params).then((summary) => {
            this.evaluationSummary = summary;
            this.areSummaryLoading = false;
        }).catch((error) => {
            this.areSummaryLoading = false;
            this.$message.error(error);
        });
    }

    private changeTimeFormat(time) {
        const formattedTime = moment.duration(time, 's');
        const Hours = formattedTime.get('hours');
        const Minutes = formattedTime.get('minutes');
        const Seconds = formattedTime.get('seconds');
        return `${Hours}:${Minutes}:${Seconds}`;
    }
}
