
import Vue from 'vue';
import * as echarts from 'echarts/core';
import { LineChart, BarChart } from 'echarts/charts';
import { TooltipComponent, GridComponent, ToolboxComponent, LegendComponent  } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { UniversalTransition } from 'echarts/features';
import { Provide, Component, Prop, Watch, Ref } from 'vue-property-decorator';
echarts.use([
  TooltipComponent,
  GridComponent,
  LineChart,
  BarChart,
  UniversalTransition,
  CanvasRenderer,
  ToolboxComponent,
  LegendComponent,
]);
@Component({})
export default class TransactionsTrendsChart extends Vue {
    @Prop() private transactionsTrends!: any;
    @Provide() private trendsChart: any = null;
    @Provide() private trends: any = {
        color: ['#5470C6'],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
            },
        },
        grid: {
            left: '5%',
            right: '5%',
        },
        legend: {
            data: ['上年同期成功交易量', '成功交易量', '上年同期成功交易额', '发起交易额', '成功交易额'],
            selected: {
                上年同期成功交易量: false,
                上年同期成功交易额: false,
            },
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true,
                },
                data: [],
            },
        ],
        yAxis: [
            {
                type: 'value',
                position: 'left',
                axisLine: {
                    show: true,
                },
            },
            {
                type: 'value',
                position: 'right',
                axisLine: {
                    show: true,
                },
            },
        ],
        series: [
            {
                name: '上年同期成功交易量',
                type: 'bar',
                yAxisIndex: 1,
                data: [],
                itemStyle: {
                    normal: {
                        color: '#ffc300',
                    },
                },
                z: 100,
            },
            {
                name: '成功交易量',
                type: 'bar',
                yAxisIndex: 1,
                data: [],
                itemStyle: {
                    normal: {
                        color: '#f33b29',
                    },
                },
                z: 100,
            },
            {
                name: '上年同期成功交易额',
                type: 'line',
                symbol: 'none',
                data: [],
                itemStyle: {
                    normal: {
                        color: '#0180ff',
                        lineStyle: {
                            color: '#0180ff',
                            type: 'dashed',
                        },
                    },
                },
            },
            {
                name: '发起交易额',
                type: 'line',
                symbol: 'none',
                itemStyle: {
                    normal: {
                        color: '#bff199',
                        lineStyle: {
                            color: '#bff199',
                        },
                    },
                },
                areaStyle: {
                    color: '#bff199',
                },
                data: [],
            },
            {
                name: '成功交易额',
                type: 'line',
                data: [],
                symbol: 'none',
                itemStyle: {
                    normal: {
                        color: '#41b146',
                        lineStyle: {
                            color: '#41b146',
                        },
                    },
                },
                areaStyle: {
                    color: '#41b146',
                },
            },
        ],
    };
    @Ref() private readonly trendsChartConatiner;
    @Watch('transactionsTrends', { deep: true })
    private refreshChart(trends) {
        this.trends.xAxis[0].data = trends.dates;
        this.trends.series[0].data = trends.lastYearPaidCounts;
        this.trends.series[1].data = trends.currentYearPaidCounts;
        this.trends.series[2].data = trends.lastYearPaidAmounts;
        this.trends.series[3].data = trends.currentYearInitiatedAmounts;
        this.trends.series[4].data = trends.currentYearPaidAmounts;
        this.trendsChart.setOption(this.trends);
    }
    private mounted() {
        this.initTrendsChart();
    }
    private initTrendsChart() {
        // 执行图表对象的初始化
        this.trendsChart = echarts.init(this.trendsChartConatiner);
        // 为图标表对象赋值
        this.trendsChart.setOption(this.trends);
        // 当尺寸发生变化时，图像也跟随变化
        window.addEventListener('resize', this.trendsChart.resize);
    }
    private beforeDestroy() {
        window.removeEventListener('resize', this.trendsChart.resize);
    }
}
