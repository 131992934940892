
import { Provide, Component, Ref, Vue } from 'vue-property-decorator';
import axios from '../../../plugins/axios.js';
@Component({})
export default class ReferralInputEc extends Vue {
    @Provide() private customer: { [index: string]: any } = {
        referrerPhone: '',
        phone: '',
        name: '',
        remark: '',
    };
    @Provide() private customerRules: { [index: string]: object[] } = {
        referrerPhone: [
            { required: true, message: '请输入转介绍人的手机号', trigger: 'blur' },
            { validator: this.validatePhone, trigger: 'blur' },
        ],
        phone: [
            { required: true, message: '请输入被转介绍人的手机号', trigger: 'blur' },
            { validator: this.validatePhone, trigger: 'blur' },
        ],
        name: [
            { required: true, message: '请填写客户姓名', trigger: 'blur' },
        ],
    };
    @Provide() private isSubmitting: boolean = false;
    @Ref() private readonly customerForm;

    private validatePhone(rule: any, value: any, callback: any) {
        if (!value) {
            return callback();
        }
        const phoneReg = /^\d{11}$|^\+\d{8,}$/;
        if (!phoneReg.test(value)) {
            callback(new Error('请填写正确的手机号码'));
        }
        callback();
    }

    private submit() {
        this.customerForm.validate((valid) => {
            if (!valid) {
                return;
            }
            this.isSubmitting = true;
            axios.post('/api/v1/referral/customer', this.customer).then(() => {
                this.isSubmitting = false;
                this.customer = {
                    referrerPhone: '',
                    phone: '',
                    name: '',
                    remark: '',
                };
                this.$message({ type: 'success', message: '客户录入成功' });
            }).catch((error) => {
                this.isSubmitting = false;
                this.$message.error(error);
            });
        });
    }
}
