
import Vue from 'vue';
import axios from '@/plugins/axios.js';
import { Provide, Component } from 'vue-property-decorator';
import moment from 'moment';
@Component({})
export default class VoucherUsers extends Vue {
    @Provide() private voucher: any = {};
    @Provide() private users: any = [];
    @Provide() private pageSize: number = 10;
    @Provide() private loading: boolean = true;
    @Provide() private total: number = 0;
    @Provide() private showDialog: boolean = false;
    @Provide() private queriedCondition: any = {
        voucherUsedStatus: [],
        page: 1,
    };

    private created() {
        this.$on('open', (voucher) => {
            this.voucher = voucher;
            this.showDialog = true;
            this.getVoucherUsers();
        });
    }

    private returnVoucherExpiredAt(expiredAt) {
        return moment(expiredAt).format('YYYY-MM-DD HH:mm:ss');
    }

    private changePage(page: number) {
        this.queriedCondition.page = page;
        this.getVoucherUsers();
    }

    private changeFilter(filter: any) {
        if (filter.hasOwnProperty('voucherUsedStatus')) {
            this.queriedCondition.voucherUsedStatus = filter.voucherUsedStatus;
        }
        this.queriedCondition.page = 1;
        this.getVoucherUsers();
    }

    private getVoucherUsers() {
        this.loading = true;
        axios.get('/api/v1/voucher/users', {
            voucherId: this.voucher.id,
            ...this.queriedCondition,
        }).then(({ total, users }) => {
            this.loading = false;
            this.total = total;
            this.users = users;
        }).catch((error) => {
            this.loading = false;
            this.$message.error(error);
        });
    }
}
