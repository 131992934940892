
import { ProvideReactive, Provide, Component, Mixins } from 'vue-property-decorator';
import axios from '../../plugins/axios.js';
import CourseClassifyMixins from '@/views/shared/CourseClassifyMixins.vue';
import LiveCourses from './widgets/LiveCourses.vue';
import NonLiveCourses from './widgets/NonLiveCourses.vue';

@Component({
    components: {
        LiveCourses,
        NonLiveCourses,
    },
})

export default class CoursesManagement extends Mixins(CourseClassifyMixins) {
    @ProvideReactive() private advisorsOptions: any[] = [];
    @ProvideReactive() private liveSubClassifiesOptions: any[] = [];
    @ProvideReactive() private nonLiveClassifiesOptions: any[] = [];
    @Provide() private loading: boolean = false;
    @Provide() private coursesType: any = 'liveCourses';

    public created() {
        this.getCommonFilterOptions();
    }

    public getCommonFilterOptions() {
        this.loading = true;
        axios.get('/api/v1/courses-management/filter-options').then((response) => {
            this.advisorsOptions = this.generateAdvisorsOptions(response.advisors);
            this.liveSubClassifiesOptions = this.generateSubClassifiesOptions(response.liveCoursesSubClassifies);
            this.nonLiveClassifiesOptions = this.formattedClassifiesOptions(response.nonLiveCoursesClassify);
            this.courseClassify = response.courseClassify;
            this.loading = false;
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    public generateAdvisorsOptions(advisors) {
        const options: any = [];
        for (const advisor of advisors) {
            options.push({
                text: advisor.name,
                value: advisor.uid,
            });
        }
        return options;
    }

    public generateSubClassifiesOptions(classifies) {
        const options: any = [];
        for (const key in classifies) {
            if (!classifies.hasOwnProperty(key)) {
                continue;
            }
            options.push({
                text: classifies[key],
                value: parseInt(key, 0),
            });
        }
        return options;
    }
}
