
import Vue from 'vue';
import _ from 'lodash';
import axios from '../../../plugins/axios.js';
import { InjectReactive, Prop, Provide, Component, Ref } from 'vue-property-decorator';

@Component
export default class UpdateRole extends Vue {
    @InjectReactive() public departmentPermissionsTree;
    @Prop() private departmentId;

    @Provide() private showDialog: boolean = false;
    @Provide() private submitting: boolean = false;
    @Provide() private role: { [index: string]: any } = {
        id: 0,
        name: '',
        specification: '',
        permissions: [],
    };
    @Provide() private isAddingRole: boolean = false;
    @Provide() private rolePermissions: any = [];
    @Provide() private loading: boolean = false;

    @Ref() private readonly permissionsTree;

    private created() {
        this.$on('open', (role) => {
            this.showDialog = true;
            this.role = _.cloneDeep(role);
            this.isAddingRole = role.id === 0;
            this.requestRolePermissions();
        });
    }

    private requestRolePermissions() {
        if (this.isAddingRole || this.role.isManager) {
            return;
        }
        this.loading = true;
        axios.get('/api/v1/authority-manage/role-permissions', {
            roleId: this.role.id,
        }).then((permissions) => {
            this.rolePermissions = permissions;
            this.loading = false;
            this.$nextTick(() => {
                this.permissionsTree.setCheckedKeys(permissions);
            });
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    private updateRole() {
        if (!this.role.name) {
            return this.$message.error('请输入角色名');
        }
        if (!this.role.specification) {
            return this.$message.error('请输入角色权限说明');
        }
        let permissions = this.rolePermissions;
        if (!this.role.isManager) {
            permissions = this.permissionsTree.getCheckedKeys().filter((permission) => permission);
            if (permissions.length === 0) {
                return this.$message.error('请为该角色配置至少 1 个功能权限');
            }
        }
        const url = this.isAddingRole ? '/api/v1/authority-manage/add-role' : '/api/v1/authority-manage/edit-role';
        this.submitting = true;
        axios.post(url, {
            ...this.role,
            permissions,
            departmentId: this.departmentId,
        }).then(() => {
            this.$message({
                type: 'success',
                message: '更新角色成功',
            });
            this.showDialog = false;
            this.$emit('refresh-roles');
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.submitting = false;
        });
    }
}
