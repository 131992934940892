
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { InjectReactive, Prop, Provide, Component, Ref } from 'vue-property-decorator';
import UpdateMember from '../modals/UpdateMember.vue';

import _ from 'lodash';

@Component({
    components: {
        UpdateMember,
    },
})
export default class DepartmentMembers extends Vue {
    @InjectReactive() public isAdmin;
    @Prop() private departmentId;

    @Provide() private isLoading: boolean = false;
    @Provide() private members: any[] = [];
    @Ref() private readonly updateMemberModal;

    private getDepartmentMembers() {
        this.isLoading = true;
        axios.get('/api/v1/authority-manage/members', {
            departmentId: this.departmentId,
        }).then((members) => {
            this.members = members;
            this.$emit('members-loaded', members);
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.isLoading = false;
        });
    }

    private openAddMemberModal() {
        this.openUpdateMemberModal({
            uuid: '',
            phone: '',
            nickname: '',
            name: '',
            roles: [],
        });
    }

    private openUpdateMemberModal(member) {
        this.updateMemberModal.$emit('open', member);
    }

    private openConfirmDeleteMemberModal(member) {
        this.$confirm('成员移除后将无法找回，是否继续？', '移除成员', {
            confirmButtonText: '确定',
            confirmButtonClass: 'el-button--danger',
            cancelButtonText: '取消',
        }).then(() => {
            this.requestDeleteMember(member);
        }).catch();
    }

    private requestDeleteMember(member) {
        axios.post('/api/v1/authority-manage/delete-member', {
            ...member,
        }).then(() => {
            this.$message({
                type: 'success',
                message: '移除成员成功',
            });
            this.getDepartmentMembers();
        });
    }

    private refreshMembers() {
        this.getDepartmentMembers();
        this.$emit('members-updated');
    }
}
