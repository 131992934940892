
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Prop, Watch, Ref } from 'vue-property-decorator';
import _ from 'lodash';
import { VueCropper } from 'vue-cropper';

@Component({
    components: {
        VueCropper,
    },
})
export default class UpdateInstructor extends Vue {
    @Prop() private selectedInstructor;
    @Prop() private certificateStatuses!: object[];
    @Provide() private showDialog: boolean = false;
    @Provide() private instructorAvatar: any = [
        {
            name: 'avatar.jpg',
            url: '',
        },
    ];
    @Provide() private instructor: any = {
        id: 0,
        name: '',
        remarks: '',
        phone: '',
        avatar: '',
        certificateStatus: null,
        certificateNumber: '',
        introduction: '',
        trialLectureVideo: '',
    };
    @Provide() private rules: { [index: string]: object[] } = {
        name: [
            { required: true, message: '请输入教师名称', trigger: 'blur' },
            { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' },
        ],
        remarks: [
            { min: 0, max: 10, message: '长度在 0 到 10 个字符', trigger: 'blur' },
        ],
        certificateStatus: [
            { required: true, message: '请选择教师资格证状态', trigger: 'blur' },
        ],
        certificateNumber: [
            { required: true, message: '请填写教师资格证编号', trigger: 'blur' },
            { validator: this.validateCertificateNumber, trigger: 'blur'},
        ],
        introduction: [
            { required: true, message: '请填写教师简介', trigger: 'blur' },
        ],
    };
    @Provide() private showAvatarCut = false;
    @Provide() private option: {[index: string]: any} = {
        img: '',
        outputSize: 0.5, // 裁剪生成图片的质量
        full: false, // 是否输出原图比例的截图
        outputType: 'jpeg', // 裁剪生成图片的格式
        canMove: false, // 上传图片是否可以移动
        canScale: false, // 上传图片是否允许滚轮缩放
        canMoveBox: true, // 截图框能否拖动
        fixedBox: false, // 固定截图框大小 不允许改变
        original: false, // 上传图片按照原始比例渲染
        autoCrop: true, // 是否默认生成截图框
        // 只有自动截图开启 宽度高度才生效
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        centerBox: true, // 截图框是否被限制在图片里面
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
    };
    @Provide() private statusOfHasGotCertificate: number = 3;
    @Ref() private readonly instructorForm;
    @Ref() private readonly cropper;

    private get isAddInstructor() {
        return this.instructor.id === 0;
    }

    private get title() {
        if (this.isAddInstructor) {
            return '添加教师';
        }
        return '编辑教师';
    }
    private get updateInstructorUrl() {
        if (this.isAddInstructor) {
            return '/api/v1/instructor/add';
        }
        return '/api/v1/instructor/edit';
    }

    @Watch('selectedInstructor', { deep: true })
    private updateSelectedInstructor() {
        this.instructor = _.cloneDeep(this.selectedInstructor);
        this.instructorAvatar = [];
        if (this.selectedInstructor.avatar !== '') {
            this.instructorAvatar = [
                {
                    name: 'avatar.jpg',
                    url: this.selectedInstructor.avatar,
                },
            ];
        }
    }

    private uploadImg(file: any) {
        if (this.instructorAvatar.length >= 1) {
            this.$message.error('只能上传 1 张头像');
        } else if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
          this.$message.error('上传头像图片格式不正确');
        } else {
            this.showAvatarCut = true;
            let reader = new FileReader();
            reader.onload = (imgFile: any) => {
                let data;
                // 把Array Buffer转化为blob
                data = window.URL.createObjectURL(new Blob([imgFile.target.result]));
                this.option.img = data;
            };
            // 修改剪裁框样式为正圆
            this.$nextTick(() => {
                reader.readAsArrayBuffer(file);
                let cropperViewBox = document.getElementsByClassName('cropper-view-box')[0] as HTMLElement;
                let cropperFaceMove = document.getElementsByClassName('cropper-face')[0] as HTMLElement;
                cropperViewBox.style.borderRadius = '50%';
                cropperViewBox.style.outline = '0';
                cropperFaceMove.style.background = '0';
            });
        }
        return false;
    }
    private avatarCut() {
        this.cropper.getCropData((data) => {
            // data就是截到图片生成的base64
            let file = this.dataURLToFile(data, 'avatar.jpg');
            if (file.size > 100 * 1024) {
                this.$message.error('上传头像图片大小不能超过 100KB');
                return false;
            }
            // 上传图片
            const url = '/api/v1/common/upload-multimedia';
            const params = new FormData();
            params.append('file', file);
            axios.post(url, params).then((response: any) => {
                this.$message({
                    type: 'success',
                    message: '上传成功',
                });
                this.instructorAvatar.push({ name: file.name, url: response.file_path });
                this.instructor.avatar = response.file_path;
            }).catch((error) => {
                this.showAvatarCut = false;
                this.$message.error(error);
            });
            this.showAvatarCut = false;
        });
    }

    /**
     * 将base64转换为文件
     * @param dataUrl 图片文件 base64 数据
     * @param fileName 图片文件名称
     * @param file 图片文件
     */
    private dataURLToFile(dataUrl, fileName) {
        let arr = dataUrl.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], fileName, { type: mime });
    }

    private created() {
        this.$on('open', () => {
            this.showDialog = true;
        });
    }
    private validateCertificateNumber(rule: any, value: any, callback: any) {
        if (! (/^\d*$/).test(value)) {
            return callback(new Error('教师资格证编号只能是纯数字'));
        }
        if (value.length !== 17) {
            return callback(new Error('教师资格证编号应为 17 位数字'));
        }
        return callback();
    }
    private removeImage(file: any, files: any) {
        this.instructorAvatar = files;
        this.instructor.avatar = '';
    }
    private clearValidate() {
        this.instructorForm.clearValidate();
    }
    private updateInstructor() {
        if (this.instructor.avatar === '') {
            this.$message.error('未上传教师头像');
            return false;
        }
        this.instructorForm.validate((valid) => {
            if (!valid) {
                return false;
            }
            const params = this.instructor;
            if (params.certificateStatus !== this.statusOfHasGotCertificate) {
                params.certificateNumber = '';
            }
            this.requestUpdateInstructor(params).then(() => {
                this.$message({
                    type: 'success',
                    message: '教师信息更新成功',
                });
                this.clearForm();
                this.showDialog = false;
                this.$emit('refreshInstructors');
            }).catch((error) => {
                this.$message.error(error);
            });
        });
    }
    private requestUpdateInstructor(params) {
        if (this.isAddInstructor) {
            return axios.post('/api/v1/instructors', params);
        }
        return axios.put('/api/v1/instructors', params);
    }
    private clearForm() {
        this.instructor = {
            id: 0,
            name: '',
            remarks: '',
            phone: '',
            avatar: '' ,
            certificateStatus: 0,
            certificateNumber: '',
            trialLectureVideo: '',
            introduction: '',
        };
        this.instructorAvatar = [];
    }
}
