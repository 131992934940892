
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Prop, Watch, Ref } from 'vue-property-decorator';

interface Website {
    id: number;
    support: string;
    description: string;
    webType: number[];
}

@Component
export default class EditWebsite extends Vue {
    @Prop() private webTypeOptions!: any[];
    @Prop() private selectedWebsite!: any;
    @Provide() private showDialog: boolean = false;
    @Provide() private website: Website = {
        id: 0,
        support: '',
        description: '',
        webType: [0, 0],
    };
    @Provide() private rules: { [index: string]: object[] } = {
        webType: [
            { required: true, message: '请选择安全策略/指令类型', trigger: 'change' },
        ],
        support: [
            { required: true, message: '请填写支持的跨域网址格式', trigger: 'blur'},
        ],
    };
    @Ref() private readonly websiteForm;
    private created() {
        this.$on('open', () => {
            this.showDialog = true;
        });
    }

    @Watch('selectedWebsite', { deep: true })
    private changeWebsiteInfo() {
        this.website.id = this.selectedWebsite.id;
        this.website.support = this.selectedWebsite.support;
        this.website.description = this.selectedWebsite.description;
        this.website.webType = [this.selectedWebsite.securityPolicy, this.selectedWebsite.commandType];
    }
    private clearValidate() {
        this.websiteForm.clearValidate();
    }
    private updateWebType(value) {
        this.website.webType = value;
    }
    private submitEditWebsite() {
        this.websiteForm.validate((valid) => {
            if (!valid) {
                return false;
            }
            const url = '/api/v1/crossdomain-allowlist-management/update-website';
            const params = {
                id: this.website.id,
                security_policy: this.website.webType[0],
                command_type: this.website.webType[1],
                website_support: this.website.support,
                website_description: this.website.description,
            };
            axios.post(url, params).then(() => {
                this.$message({
                    type: 'success',
                    message: '保存成功',
                });
                this.showDialog = false;
                this.$emit('refreshWebsites');
            }).catch((error) => {
                this.$message.error(error);
            });
        });
    }
}
