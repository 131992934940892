
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Prop, Watch } from 'vue-property-decorator';

interface Image {
    show: boolean;
    size: any[];
}

interface Config {
    titleType: string;
    pcImage: Image;
    mobileImage: Image;
    hasUrl: boolean;
}

@Component
export default class AdForm extends Vue {
    // 用于显示的广告类别信息
    @Prop() private adInfo;
    // 用于显示的广告类别信息
    @Prop() private adChannels!: object[];
    // 广告类别配置信息
    @Prop() private adChannelsConfig!: Config;
    @Provide() private rules: { [index: string]: object[] } = {
        adCopy: [
            { required: true, validator: this.validateAdCopy, trigger: 'blur' },
        ],
        copyUrl: [
            { required: true, validator: this.validateCopyUrl, trigger: 'blur' },
        ],
        url: [
            { required: true, validator: this.validateUrl, trigger: 'blur' },
        ],
        channel: [
            { required: true, message: '请选择广告类型', trigger: 'blur' },
        ],
        pcImage: [
            { required: true, validator: this.validatePCImage, trigger: 'blur' },
        ],
        mobileImage: [
            { required: true, validator: this.validateMobileImage, trigger: 'blur' },
        ],
        startDateTime: [
            { required: true, message: '请设置开始时间', trigger: 'blur' },
        ],
    };

    @Watch('adInfo.channel')
    private changeAdConfig(value) {
        if (value) {
            this.adInfo.config = this.adChannelsConfig[value];
        } else {
            this.adInfo.config = {
                titleType: '',
                pcImage: {
                    show: false,
                    size: [],
                },
                mobileImage: {
                    show: false,
                    size: [],
                },
                hasUrl: false,
            };
        }
    }
    private get isAdCopyInputVisible() {
        return this.adInfo.channel && this.adInfo.config.titleType !== 'linkOnly';
    }
    private get isCopyUrlInputVisible() {
        return this.adInfo.channel && this.adInfo.config.titleType !== 'titleOnly';
    }
    private get isAdHasUrlInputVisible() {
        return this.adInfo.channel && this.adInfo.config.hasUrl;
    }
    private get isPcImageUploadVisible() {
        return this.adInfo.channel && this.adInfo.config.pcImage.show;
    }
    private get isMobileImageUploadVisible() {
        return this.adInfo.channel && this.adInfo.config.mobileImage.show;
    }
    private validateAdCopy(rule: any, value: any, callback: any) {
        if (this.adInfo.config.titleType !== 'linkOnly' && !this.adInfo.adCopy) {
            return callback(new Error('请填写广告文案'));
        }
        return callback();
    }
    private validateCopyUrl(rule: any, value: any, callback: any) {
        if (this.adInfo.config.titleType !== 'titleOnly' && !this.adInfo.copyUrl) {
            return callback(new Error('请填写链接文案'));
        }
        return callback();
    }
    private validateUrl(rule: any, value: any, callback: any) {
        if (this.adInfo.config.hasUrl && !this.adInfo.url) {
            return callback(new Error('请填写广告链接'));
        }
        return callback();
    }
    private validatePCImage(rule: any, value: any, callback: any) {
        if (this.adInfo.config.pcImage.show && !this.adInfo.pcImage.length) {
            return callback(new Error('请上传 PC 端图片'));
        }
        return callback();
    }
    private validateMobileImage(rule: any, value: any, callback: any) {
        if (this.adInfo.config.mobileImage.show && !this.adInfo.mobileImage.length) {
            return callback(new Error('请上传 Mobile 端图片'));
        }
        return callback();
    }
    private uploadPCImg(file: any) {
        if (this.adInfo.pcImage.length >= 1) {
            this.$message.error('只能上传 1 张图片');
        } else {
            const imageType = 'pcImage';
            this.checkImageSizeAndPixel(file, imageType);
        }
        return false;
    }
    private removePCImage(file: any, files: any) {
        this.adInfo.pcImage = files;
    }
    private uploadMobileImg(file: any) {
        if (this.adInfo.mobileImage.length >= 1) {
            this.$message.error('只能上传 1 张图片');
        } else {
            const imageType = 'mobileImage';
            this.checkImageSizeAndPixel(file, imageType);
        }
        return false;
    }
    private removeMobileImage(file: any, files: any) {
        this.adInfo.mobileImage = files;
    }
    private checkImageSizeAndPixel(file, imageType) {
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            this.$message.error('上传图片格式不正确');
        } else if (file.size > 1 * 1024 * 1024) {
            this.$message.error('请上传大小不超过 1 MB 的图片');
        } else {
            let width = this.adInfo.config.mobileImage.size[0];
            let height = this.adInfo.config.mobileImage.size[1];
            if (imageType === 'pcImage') {
                width = this.adInfo.config.pcImage.size[0];
                height = this.adInfo.config.pcImage.size[1];
            }
            let _URL = window.URL || window.webkitURL;
            let img = new Image();
            img.onload = (() => {
                if (width && height && (img.width !== width || img.height !== height)) {
                    return this.$message.error(`图片尺寸限制为 ${width} * ${height}`);
                }
                this.uploadImage(file, imageType);
            });
            img.src = _URL.createObjectURL(file);
        }
    }
    private uploadImage(file, imgType) {
        const url = '/api/v1/common/upload-multimedia';
        const params = new FormData();
        params.append('file', file);
        axios.post(url, params).then((response: any) => {
            this.$message({
                type: 'success',
                message: '上传成功',
            });
            if (imgType === 'pcImage') {
                this.adInfo.pcImage.push({ name: file.name, url: response.file_path });
            } else {
                this.adInfo.mobileImage.push({ name: file.name, url: response.file_path });
            }
        }).catch((error) => {
            this.$message.error(error);
        });
    }
}
