
import Vue from 'vue';
import axios from '../../plugins/axios.js';
import moment from 'moment';
import { Provide, Component, Ref } from 'vue-property-decorator';
import AddPartnerModal from './modals/AddPartner.vue';
import EditPartnerModal from './modals/EditPartner.vue';

@Component({
    components: {
        AddPartnerModal, EditPartnerModal,
    },
})

export default class PartnerManagement extends Vue {
    // 机构列表
    @Provide() private partners: any[] = [];
    // 模板课列表
    @Provide() private templateCourses: any = [];
    @Provide() private queriedCondition: { [index: string]: any } = {
        partnerName: null,
        partenrCode: null,
        account: null,
        suankeUsername: null,
        sort_prop: 'countOfCourses',
        sort_order: 'descending',
        page: 1,
    };
    @Provide() private pageSize: number = 10;
    @Provide() private loading: boolean = true;
    @Provide() private total: number = 0;
    @Provide() private selectedPartner: any = {};
    @Provide() private addressesOptions: any = [];
    @Provide() private problemAccessQuotaUnitPrice: any = null;
    @Ref() private readonly addPartnerModal;
    @Ref() private readonly editPartnerModal;

    private mounted() {
        this.getPartners();
    }
    private changePage(page: number) {
        this.queriedCondition.page = page;
        this.getPartners();
    }
    private changeSort(sort: any) {
        if (!sort.order) {
            this.queriedCondition.sort_prop = 'countOfCourses';
            this.queriedCondition.sort_order = 'descending';
        } else {
            this.queriedCondition.sort_prop = sort.prop;
            this.queriedCondition.sort_order = sort.order;
        }
        this.queriedCondition.page = 1;
        this.getPartners();
    }
    private queryApply() {
        this.queriedCondition.sort_prop = 'countOfCourses';
        this.queriedCondition.sort_order = 'descending';
        this.queriedCondition.page = 1;
        this.getPartners();
    }
    private partnerAdded() {
        this.queriedCondition = {
            partnerName: '',
            partnerCode: '',
            account: '',
            suankeUsername: '',
            sort_prop: 'countOfCourses',
            sort_order: 'descending',
            page: 1,
        };
        this.getPartners();
    }
    private getPartners() {
        this.loading = true;
        axios.get('/api/v1/partner-manage/partners', this.queriedCondition).then(({
            partners,
            total,
            templateCourses,
            addresses,
            problemAccessQuotaUnitPrice,
        }) => {
            this.partners = partners;
            this.total = total;
            this.templateCourses = templateCourses;
            this.addressesOptions = this.updateAddressesOptions(addresses);
            this.problemAccessQuotaUnitPrice = problemAccessQuotaUnitPrice;
            this.loading = false;
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    private formattedPartnerManagerAccount(partner) {
        if (partner.phone && partner.email) {
            return `${partner.phone}/${partner.email}`;
        }
        if (partner.phone) {
            return partner.phone;
        } else if (partner.email) {
            return partner.email;
        }
        return '';
    }

    private openAddPartnerModal() {
        this.addPartnerModal.$emit('open');
    }
    private editPartner(partner: any) {
        this.selectedPartner = partner;
        this.editPartnerModal.$emit('open');
    }
    private jumpToCourseManagement(partner) {
        this.$router.push({ path: '/partner-course-management', query: {
            partnerCode: partner.partnerCode,
        }});
    }
    private jumpToTransactions(partner) {
        this.$router.push({ path: '/partner-transaction', query: {
            partnerCode: partner.partnerCode,
        }});
    }
    private partnerAddress({ province, city, district }) {
        if (province && city && district) {
            return `${province} / ${city} / ${district}`;
        }
        return '';
    }
    private updateAddressesOptions(addresses) {
        const addressesOptions: any = [];
        for (const province of addresses) {
            let option = { value: province.name, label: province.name, children: new Array() };
            for (const city of province.city_list) {
                let subOption = { value: city.name, label: city.name, children: new Array() };
                for (const district of city.district_list) {
                    subOption.children.push({ value: district.name, label: district.name });
                }
                option.children.push(subOption);
            }
            addressesOptions.push(option);
        }
        return addressesOptions;
    }
    private formatContractTime(time) {
        if (time) {
            return moment(time).format('YYYY-MM-DD');
        }
        return '';
    }
}
