
import { Provide, Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import moment from 'moment';
import AddCouponMixins from './AddCouponMixins.vue';
@Component
export default class AddDefaultCoupon extends Mixins(AddCouponMixins)  {
    @Prop() private couponTemplates;
    @Provide() private rules: { [index: string]: object[] } = {
        phone: [
            { required: true, message: '请填写用户手机号', trigger: 'blur' },
        ],
        endAt: [
            { required: true, message: '请选择优惠券到期时间', trigger: 'blur' },
        ],
    };
    @Watch('selectedTemplateIndex', { deep: true })
    private updateCoupon() {
        const {
            title,
            discount,
            isIndependent,
            isUnrelatable,
            couponConstraint,
            classifyIds,
            courseIds,
            duration,
        } = this.couponTemplates[this.selectedTemplateIndex];
        this.coupon.title = title;
        this.coupon.discount = discount;
        this.coupon.isIndependent = isIndependent;
        this.coupon.isUnrelatable = isUnrelatable;
        this.coupon.couponConstraint = couponConstraint;
        this.coupon.classifyIds = classifyIds;
        this.coupon.courseIds = courseIds;
        this.coupon.endAt = moment().add(duration, 's').format('YYYY-MM-DD HH:mm');
    }
}
