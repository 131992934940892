
import { Provide, Component, Ref, Mixins} from 'vue-property-decorator';
import axios from '../../plugins/axios.js';
import AddCouponModal from './modals/AddCoupon.vue';
import AddDefaultCouponModal from './modals/AddDefaultCoupon.vue';
import moment from 'moment';
import CourseClassifyMixins from '@/views/shared/CourseClassifyMixins.vue';
const secondsOfOneHour = 60 * 60;
interface Coupon {
    couponId: number;
    title?: string;
    discount?: string;
    isIndependent?: number;
    isUnrelatable?: number;
    phone?: string;
    reason?: string;
    couponConstraint?: string;
    courseConstraint?: any[];
    categoryConstraint?: any[];
    beginAt?: string;
    endAt?: string;
}

@Component({
    components: {
        AddCouponModal,
        AddDefaultCouponModal,
    },
})
export default class CouponManagement extends Mixins(CourseClassifyMixins) {
    @Provide() private coupons: Coupon[] = [];
    @Provide() private queriedCondition: { [index: string]: any } = {
        phone: '',
        sort: 'descending',
        page: '1',
    };
    @Provide() private currentPage: number = 1;
    @Provide() private pageSize: number = 10;
    @Provide() private loading: boolean = true;
    @Provide() private totalCoupons: number = 0;
    @Provide() private couponTemplates: any[] = [];
    @Provide() private canCreateCustomCoupon: boolean = false;
    @Ref() private readonly addDefaultCouponModal;
    @Ref() private readonly addCouponModal;
    @Ref() private readonly couponsTable;

    private mounted() {
        this.getCoupons();
    }

    private isCouponDeleteButtonVisible(coupon) {
        return !this.isCouponCreatedMoreThanOneHour(coupon) && !this.isCouponExpired(coupon) && !coupon.isUsed && coupon.hasPermissionToDelete;
    }

    private isCouponCreatedMoreThanOneHour(coupon) {
        return moment(coupon.createdAt).unix() + secondsOfOneHour < moment().unix();
    }

    private isCouponExpired(coupon) {
        return moment(coupon.endAt).unix() < moment().unix();
    }
    private otherStatusContentOfCoupon(coupon) {
        if (coupon.isUsed) {
            return '已使用';
        }
        if (this.isCouponExpired(coupon)) {
            return '已过期';
        }
    }

    private openAddDefaultCouponModal() {
        this.addDefaultCouponModal.$emit('open');
    }
    private openAddCouponModal() {
        this.addCouponModal.$emit('open');
    }
    private refreshCoupons() {
        this.queriedCondition = {
            sort: 'descending',
            page: '1',
        };
        this.couponsTable.sort('couponId', 'descending');
        this.getCoupons();
    }
    private changePage(page: number) {
        this.queriedCondition.page = page.toString();
        this.getCoupons();
    }
    private queryCoupon() {
        this.queriedCondition.page = '1';
        this.getCoupons();
    }
    private getCoupons() {
        this.loading = true;
        const url = '/api/v1/sales/coupons';
        const params = {
            phone: this.queriedCondition.phone,
            sort: this.queriedCondition.sort,
            page: this.queriedCondition.page,
        };
        axios.get(url, params).then((response: any) => {
            this.loading = false;
            this.totalCoupons = response.total;
            this.currentPage = parseInt(this.queriedCondition.page, 0);
            this.courseClassify = response.courseClassify;
            this.coupons = response.coupons;
            this.couponTemplates = response.couponTemplates;
            this.canCreateCustomCoupon = response.canCreateCustomCoupon;
        }).catch((error) => {
            this.loading = false;
            this.$message.error(error);
        });
    }
    private deleteCoupon(coupon) {
        if (this.isCouponCreatedMoreThanOneHour(coupon)) {
            return this.$message.error('优惠券已发放超过1小时，无法删除');
        }
        this.$confirm('该优惠券将会被删除且无法还原，是否继续？', '删除优惠券', {
            confirmButtonText: '确定',
            confirmButtonClass: 'el-button--danger',
            cancelButtonText: '取消',
        }).then(() => {
            const url = '/api/v1/sales/delete-coupon';
            const params = {
                couponId: coupon.couponId,
            };
            axios.post(url, params).then(() => {
                this.$message({
                    type: 'success',
                    message: '删除优惠券成功',
                });
                this.getCoupons();
            });
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除',
            });
        });
    }
    private sortChange(val: any) {
        if (val.order === null) {
            this.queriedCondition.sort = 'descending';
        } else {
            this.queriedCondition.sort = val.order;
        }
        this.queriedCondition.page = '1';
        this.getCoupons();
    }
}
