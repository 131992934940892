import axios from 'axios'

axios.interceptors.response.use(
    response => {
        if (response.data.status === 'success' || response.status === 200) {
            return response.data.data ? response.data.data : response.data
        } else {
            return Promise.reject(response.data.data.fail_message ? response.data.data.fail_message : '网络异常，请刷新重试');
        }
    },
    error => {
        if (error.response && error.response.status) {
            const errorCode = error.response.status
            if (errorCode === 401) {
                window.location.href = 'https://www.jisuanke.com/mobile/login?n=' + encodeURIComponent(window.location.href)
            }
        }
        return Promise.reject(error.response.data.message)
    }
)

export default {
    get(url, params = {}) {
        return axios({
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
            method: 'get',
            baseURL: process.env.VUE_APP_API_DOMAIN,
            url: url,
            params: params,
            withCredentials: true,
        })
    },
    post(url, params = {}) {
        return axios({
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
            method: 'post',
            baseURL: process.env.VUE_APP_API_DOMAIN,
            url: url,
            data: params,
            withCredentials: true,
        })
    },
    upload(url, params = {}, onUploadProgress = null) {
        return axios({
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
            method: 'post',
            baseURL: process.env.VUE_APP_API_DOMAIN,
            url: url,
            data: params,
            onUploadProgress,
            withCredentials: true,
        })
    },
    download(url, params = {}) {
        return axios({
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
            method: 'get',
            baseURL: process.env.VUE_APP_API_DOMAIN,
            url: url,
            params: params,
            withCredentials: true,
            responseType: 'arraybuffer'
        })
    },
    put(url, params = {}) {
        return axios({
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
            method: 'put',
            baseURL: process.env.VUE_APP_API_DOMAIN,
            url,
            data: params,
            withCredentials: true,
        })
    },
    delete(url, params = null) {
        return axios({
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
            method: 'delete',
            baseURL: process.env.VUE_APP_API_DOMAIN,
            url,
            data: params,
            withCredentials: true,
        })
    },
}
