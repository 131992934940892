
import { Provide, Component } from 'vue-property-decorator';
import axios from '../../../plugins/axios.js';
import StudentsWithFinishedLiveCourses from './StudentsWithFinishedLiveCourses.vue';

@Component({})
export default class StudentsInCourse extends StudentsWithFinishedLiveCourses {
    @Provide() private course: any = {};
    @Provide() private isLiveCourse: boolean = true;
    @Provide() private downloading: boolean = false;

    private get courseId() {
        return this.$route.query.courseId;
    }
    public getStudents() {
        this.loading = true;
        axios.get('/api/v1/advisor/course-students', {
            courseId: this.courseId,
            ...this.queriedCondition,
            studentAccount: this.studentAccount,
            schoolName: this.schoolName,
        }).then(({ course, students, total }) => {
            this.loading = false;
            this.course = course;
            this.students = students;
            this.totalStudents = total;
        }).catch((error) => {
            this.loading = false;
            this.$message.error(error);
        });
    }

    private get formattedLectureTitle() {
        return this.course.isLiveCourse ? `阶段数据(直播讲次：第 ${this.course.countOfDeliveredLectures} 讲)` : '阶段数据';
    }

    private exportStudents() {
        this.downloading = true;
        axios.download('/api/v1/advisor/export-course-students', {
            courseId: this.courseId,
            ...this.queriedCondition,
        }).then((response: any) => {
            const blob = new Blob([response], {
                type: 'application/octet-stream',
            });
            const filename = `${this.courseId}学生列表.xlsx`;
            const blobURL = window.URL.createObjectURL(blob);
            const tempLink = document.createElement('a');
            tempLink.href = blobURL;
            tempLink.download = filename;
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.downloading = false;
        });
    }
}
