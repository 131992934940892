
import axios from '../../../plugins/axios.js';
import { Vue, Prop, Provide, Component } from 'vue-property-decorator';

@Component
export default class PartnerOperationApproval extends Vue {
    @Provide() public applyType: string = 'assignCourse';

    @Prop() private queriedCondition: any;

    @Provide() private applications: any = [];
    @Provide() private currentPage: number = 1;
    @Provide() private applyResult: string = '';
    @Provide() private pageSize: number = 10;
    @Provide() private loading: boolean = true;
    @Provide() private total: number = 0;
    @Provide() private isRequesting: boolean = false;

    private mounted() {
        this.getApplications();
    }
    private changePage(page: number) {
        this.currentPage = page;
        this.getApplications();
    }
    private changeFilter(filter: any) {
        if (filter.hasOwnProperty('applyResult')) {
            this.applyResult = filter.applyResult;
        }
        this.queryApplications();
    }
    private queryApplications() {
        this.currentPage = 1;
        this.getApplications();
    }
    private getApplications() {
        this.loading = true;
        axios.get('/api/v1/partner-operation-approval/applications', {
            ...this.queriedCondition,
            page: this.currentPage,
            applyResult: this.applyResult,
            applyType: this.applyType,
        }).then(({ applications, total }) => {
            this.applications = applications;
            this.total = total;
            this.loading = false;
        }).catch((error) => {
            this.$message.error(error);
        });
    }
    private approveOrRefuseApplication(application, result) {
        this.isRequesting = true;
        axios.post('/api/v1/partner-operation-approval/apply', {
            apply_id: application.applyId,
            apply_result: result,
        }).then(() => {
            application.applyResult = result;
            this.isRequesting = false;
        }).catch((error) => {
            this.$message.error(error);
            this.isRequesting = false;
        });
    }
}
