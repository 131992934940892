
import moment from 'moment';
import axios from '../../plugins/axios.js';
import { Provide, Component, Mixins, Ref } from 'vue-property-decorator';
import CourseClassifyMixins from '@/views/shared/CourseClassifyMixins.vue';
import RemarkDetailModal from './modals/preparation/RemarkDetail.vue';
import VideoDemoModal from './modals/preparation/VideoDemo.vue';

@Component({
    components: {
        RemarkDetailModal,
        VideoDemoModal,
    },
})

export default class LecturePreparationManage extends Mixins(CourseClassifyMixins) {
    @Provide() private preparations = [];
    @Provide() private advisors: any = [];
    @Provide() private instructors: any = [];
    @Provide() private queriedCondition: { [index: string]: any } = {
        courseId: '',
        classifies: [],
        instructors: [],
        advisors: [],
        lectureAt: [],
        page: 1,
    };
    @Provide() private pageSize: number = 10;
    @Provide() private total: number = 0;
    @Provide() private lessonTypes: any = [];
    @Provide() private arePreparationsLoading: boolean = true;

    @Ref() private readonly remarkDetailModal;
    @Ref() private readonly videoDemoModal;

    private mounted() {
        this.requestLecturePreparations();
    }

    private changePage(page: number) {
        this.queriedCondition.page = page;
        this.requestLecturePreparations();
    }

    private changeFilter(filter: any) {
        if (filter.hasOwnProperty('advisors')) {
            this.queriedCondition.advisors = filter.advisors;
        }
        if (filter.hasOwnProperty('instructors')) {
            this.queriedCondition.instructors = filter.instructors;
        }
        this.queriedCondition.page = 1;
        this.requestLecturePreparations();
    }

    private requestLecturePreparations() {
        this.arePreparationsLoading = true;
        axios.get('/api/v1/lecture-preparations', this.queriedCondition).then(({
            preparations,
            total,
            lessonTypes,
            courseClassify,
            advisors,
            instructors,
        }) => {
            this.preparations = preparations;
            this.total = total;
            this.lessonTypes = lessonTypes;
            this.courseClassify = courseClassify;
            this.formatCourseAdvisorFilterOptions(advisors);
            this.formatCourseInstructorFilterOptions(instructors);
            this.arePreparationsLoading = false;
        }).catch((error) => {
            this.arePreparationsLoading = false;
            this.$message.error(error);
        });
    }

    private formatCourseAdvisorFilterOptions(advisors) {
        if (this.advisors.length !== 0) {
            return;
        }
        const options: any[] = [];
        for (const key in advisors) {
            if (advisors.hasOwnProperty(key) && advisors[key].name) {
                options.push({
                    text: advisors[key].name,
                    value: advisors[key].uid,
                });
            }
        }
        this.advisors = options;
    }

    private formatCourseInstructorFilterOptions(instructors) {
        const options: any[] = [];
        for (const key in instructors) {
            if (instructors.hasOwnProperty(key) && instructors[key].name) {
                options.push({
                    text: instructors[key].name,
                    value: instructors[key].id,
                });
            }
        }
        this.instructors = options;
    }

    private stepDuration(step) {
        if (!step.hasFinished) {
            return '未完成';
        }
        const duration = moment.duration(step.duration, 's');
        const days = parseInt(duration.asDays() as any, 10);
        const hours = duration.hours();
        let minutes = duration.minutes();
        const seconds = duration.seconds();
        if (seconds > 0) {
            minutes += 1;
        }
        if (days === 0) {
            if (hours === 0) {
                return `${minutes}分钟`;
            }
            return `${hours}小时${minutes}分钟`;
        }
        return `${days}天${hours}小时${minutes}分钟`;
    }

    private auditRemark(preparation) {
        if (preparation.isAudited && preparation.remark) {
            return preparation.remark.audit;
        }
        return '';
    }

    private openRemarkDetailModal(preparation) {
        this.remarkDetailModal.$emit('open', preparation.id);
    }

    private openVideoDemoModal(preparation) {
        this.videoDemoModal.$emit('open', preparation.id);
    }

    private formattedLatestSubmittedAt(latestSubmittedAt) {
        const millsSecondsOfOneSecond = 1000;
        return latestSubmittedAt ? moment(latestSubmittedAt * millsSecondsOfOneSecond).format('YYYY-MM-DD HH:mm:ss') : '未提交';
    }
}
