import { render, staticRenderFns } from "./DataSummaryTrendsChart.vue?vue&type=template&id=2a35ee9c&scoped=true"
import script from "./DataSummaryTrendsChart.vue?vue&type=script&lang=ts"
export * from "./DataSummaryTrendsChart.vue?vue&type=script&lang=ts"
import style0 from "./DataSummaryTrendsChart.vue?vue&type=style&index=0&id=2a35ee9c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a35ee9c",
  null
  
)

export default component.exports