
import Vue from 'vue';
import axios from '../../plugins/axios.js';
import { Provide, Component, Watch, Ref } from 'vue-property-decorator';
import AddWebsiteModal from './modals/AddWebsite.vue';
import EditWebsiteModal from './modals/EditWebsite.vue';

interface Website {
    id?: number;
    securityPolicy?: number;
    commandType?: number;
    support?: string;
    description?: string;
}

@Component({
    components: {
        AddWebsiteModal, EditWebsiteModal,
    },
 })

export default class CrossdomainAllowlistManagement extends Vue {
    @Provide() private loading: boolean = true;
    // 网址列表
    @Provide() private websites: Website[] = [];
    // 网址分类选项
    @Provide() private webTypeOptions: any[] = [];
    // 网址分类
    @Provide() private webType: { [index: string]: any } = {};
    @Provide() private currentPage: number = 1;
    @Provide() private pageSize: number = 10;
    @Provide() private totalWebsites: number = 0;
    @Provide() private selectedWebsite: any = {};
    @Provide() private queriedCondition: { [index: string]: any } = {
        web_type: [],
        website_support: '',
        page: '1',
    };
    @Ref() private readonly addWebsiteModal;
    @Ref() private readonly editWebsiteModal;

    @Watch('webType', { deep: true })
    private updateWebType() {
        this.webTypeOptions = [];
        for (const securityPolicy of Object.keys(this.webType.security_policy)) {
            let option = {value: parseInt(securityPolicy, 10), label: this.webType.security_policy[securityPolicy], children: new Array()};
            for (const commandType of Object.keys(this.webType.command_type[securityPolicy])) {
                option.children.push({value: parseInt(commandType, 10), label: this.webType.command_type[securityPolicy][commandType]});
            }
            this.webTypeOptions.push(option);
        }
    }

    private mounted() {
        this.getWebsites();
    }

    private queryWebsite() {
        this.queriedCondition.page = '1';
        this.getWebsites();
    }

    private openAddWebsiteModal() {
        this.addWebsiteModal.$emit('open');
    }

    private editWebsite(selectedWebsite: Website) {
        this.selectedWebsite = selectedWebsite;
        this.editWebsiteModal.$emit('open');
    }

    private websiteAdded() {
        this.queriedCondition = {
            web_type: [],
            website_support: '',
            page: 1,
        };
        this.getWebsites();
    }

    private getWebsites() {
        this.loading = true;
        const url = '/api/v1/crossdomain-allowlist-management/';
        const params = {
            security_policy: this.queriedCondition.web_type[0],
            command_type: this.queriedCondition.web_type[1],
            website_support: this.queriedCondition.website_support,
            page: this.queriedCondition.page,
        };
        axios.get(url, params).then((response: any) => {
            this.updateWebsiteInfo(response.website_list);
            this.webType = response.web_type;
            this.totalWebsites = response.total;
            this.currentPage = parseInt(this.queriedCondition.page, 0);
            this.loading = false;
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    private updateWebsiteInfo(websites: any) {
        this.websites = [];
        for (const web of websites) {
            const website: Website = {
                id: parseInt(web.id, 0),
                securityPolicy: web.security_policy,
                commandType: web.command_type,
                support: web.website_support,
                description: web.website_description,
            };
            this.websites.push(website);
        }
    }

    private deleteWebsite(selectedWebsite: Website) {
        this.$confirm('该网站将会被删除且无法还原，是否继续？', '删除选中行', {
            confirmButtonText: '确定',
            confirmButtonClass: 'el-button--danger',
            cancelButtonText: '取消',
        }).then(() => {
            const url = '/api/v1/crossdomain-allowlist-management/delete';
            const params = { id: selectedWebsite.id };
            axios.post(url, params).then(() => {
                this.$message({
                    type: 'success',
                    message: '移除网址成功',
                });
                this.getWebsites();
            });
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消移除',
            });
        });
    }

    private changePage(page: number) {
        this.queriedCondition.page = page.toString();
        this.getWebsites();
    }
}
