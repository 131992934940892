
import Vue from 'vue';
import axios from '../../plugins/axios.js';
import { Provide, Component } from 'vue-property-decorator';

@Component({})

export default class PartnerBilling extends Vue {
    @Provide() private requesting: boolean = false;
    @Provide() private transaction: { [index: string]: any } = {
        isRecharge: 1,
        partnerCode: '',
        amount: null,
        remark: '',
    };

    private createTransaction() {
        this.requesting = true;
        axios.post('/api/v1/partner-billing/charge', this.transaction).then(() => {
            this.$message({
                type: 'success',
                message: '操作成功',
            });
            this.requesting = false;
            this.clearForm();
        }).catch((error) => {
            this.requesting = false;
            this.$message.error(error);
        });
    }

    private clearForm() {
        this.transaction = {
            isRecharge: 1,
            partnerCode: '',
            amount: null,
            remark: '',
        };
    }
}
