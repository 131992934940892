
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Ref, Prop } from 'vue-property-decorator';
import RedeemVoucherToCouponModal from '../modals/RedeemVoucherToCoupon.vue';
@Component({
    components: {
        RedeemVoucherToCouponModal,
    },
})
export default class StudentCouponVouchers extends Vue {
    @Prop() public student!: any;
    @Provide() public loading: boolean = false;
    @Provide() public vouchers: any = [];
    @Provide() public courseClassify: any = [];
    @Provide() public selectedVoucher: any = {};
    @Ref() private readonly redeemVoucherToCouponModal;

    private created() {
        this.getVouchers();
    }

    private getVouchers() {
        this.loading = true;
        axios.get('/api/v1/advisor/student-coupon-vouchers', {
            studentUuid: this.student.uuid,
        }).then(({ vouchers, courseClassify }) => {
            this.vouchers = vouchers;
            this.courseClassify = courseClassify;
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.loading = false;
        });
    }

    private openRedeemVoucherToVoucherModal(voucher) {
        this.selectedVoucher = voucher;
        this.redeemVoucherToCouponModal.$emit('open', this.courseClassify);
    }
}
