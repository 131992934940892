
import Vue from 'vue';
import { Provide, Component, Ref } from 'vue-property-decorator';
import axios from '../../plugins/axios.js';
import EditSalesModal from './modals/EditSales.vue';
import '@/views/crm/SalesSchedulingFilter';

interface SalesInfo {
    id: number;
    name?: string;
    level?: number;
    dailyGuestLimit?: number;
    isInScheduling?: boolean;
    isNotInScheduling?: boolean;
    qrcode?: string;
}

@Component({
    components: {
        EditSalesModal,
    },
})

export default class SalesScheduling extends Vue {
    @Provide() private sales: SalesInfo[] = [];
    @Provide() private queriedCondition: { [index: string]: any } = {
        name: '',
        level: '',
        sort: { prop: 'id', order: 'ascending' },
        page: '1',
    };
    @Provide() private currentPage: number = 1;
    @Provide() private pageSize: number = 10;
    @Provide() private loading: boolean = true;
    @Provide() private totalSales: number = 0;
    @Provide() private selectedSales: SalesInfo = { id: 0 };
    @Ref() private readonly editSalesModal;
    @Ref() private readonly salesTable;

    private openEditSalesModal(selectedSales: SalesInfo) {
        this.selectedSales = selectedSales;
        this.editSalesModal.$emit('open');
    }
    private mounted() {
        this.getSales();
    }
    private refreshSales() {
        const url = '/api/v1/crm/refresh-employee-list';
        axios.post(url).then(() => {
            this.getSales();
        }).catch((error) => {
            this.$message.error(error);
        });
    }
    private changeSort(val: any) {
        if (val.order === null) {
            this.queriedCondition.sort = { prop: 'id', order: 'ascending' };
        } else {
            this.queriedCondition.sort = { prop: val.prop, order: val.order };
        }
        this.queriedCondition.page = '1';
        this.getSales();
    }
    private changeFilter(filter: any) {
        if (filter.hasOwnProperty('level')) {
            this.queriedCondition.level = filter.level;
        }
        this.queriedCondition.page = '1';
        this.getSales();
    }
    private changePage(page: number) {
        this.queriedCondition.page = page.toString();
        this.getSales();
    }
    private querySales() {
        this.queriedCondition.sort = 'ascending';
        this.salesTable.sort('id', 'ascending');
        this.salesTable.clearFilter();
        this.queriedCondition.level = [];
        this.queriedCondition.page = '1';
        this.getSales();
    }
    private getSales() {
        this.loading = true;
        const url = '/api/v1/crm/employee-list';
        const params = {
            name: this.queriedCondition.name,
            sort_prop: this.queriedCondition.sort.prop,
            sort_order: this.queriedCondition.sort.order,
            reception_level: this.queriedCondition.level,
            page: this.queriedCondition.page,
        };
        axios.get(url, params).then((response: any) => {
            this.updateSalesInfo(response.crm_employees);
            this.totalSales = response.total;
            this.currentPage = parseInt(this.queriedCondition.page, 0);
        }).catch((error) => {
            this.loading = true;
            this.$message.error(error);
        });
    }
    private updateSalesInfo(salesmen: any) {
        this.sales = [];
        for (const salesman of salesmen) {
            const salesInfo: SalesInfo = {
                id: parseInt(salesman.employee_id, 0),
                name: salesman.name,
                level: salesman.reception_level,
                dailyGuestLimit: salesman.max_reception,
                isInScheduling: salesman.in_scheduling === 1,
                isNotInScheduling: salesman.notin_scheduling === 1,
                qrcode: salesman.qrcode,
            };
            this.sales.push(salesInfo);
        }
        this.loading = false;
    }
    private changeSchedulingInStatus(selectSales: SalesInfo) {
        const url = '/api/v1/crm/change-employee-in-scheduling';
        const params = {
            employee_id: selectSales.id,
            in_scheduling_status: selectSales.isInScheduling ? 1 : 0,
        };
        axios.post(url, params).catch((error) => {
            selectSales.isInScheduling = !selectSales.isInScheduling;
            this.$message.error(error);
        });
    }
    private changeSchedulingNotInStatus(selectSales: SalesInfo) {
        const url = '/api/v1/crm/change-employee-notin-scheduling';
        const params = {
            employee_id: selectSales.id,
            notin_scheduling_status: selectSales.isNotInScheduling ? 1 : 0,
        };
        axios.post(url, params).catch((error) => {
            selectSales.isNotInScheduling = !selectSales.isNotInScheduling;
            this.$message.error(error);
        });
    }
}
