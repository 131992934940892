
import Vue from 'vue';
import moment from 'moment';
import axios from '../../../../plugins/axios.js';
import { Provide, Component, Prop } from 'vue-property-decorator';
@Component({})
export default class VideoDemo extends Vue {
    @Prop() private lessonTypes: any;
    @Provide() private showDialog: boolean = false;
    @Provide() private videoDemo: any = {};
    @Provide() private isLoading: boolean = false;

    private created() {
        this.$on('open', (preparationId) => {
            this.showDialog = true;
            this.requestVideoDemo(preparationId);
        });
    }

    private requestVideoDemo(preparationId) {
        this.isLoading = true;
        axios.get('/api/v1/lecture-preparation/video-demo', {
            preparationId,
        }).then((videoDemo) => {
            this.videoDemo = videoDemo;
            this.isLoading = false;
        }).catch((error) => {
            this.isLoading = false;
            this.$message.error(error);
        });
    }

    private formattedChapterLessonTitle({ lessonType, lessonTitle }) {
        if (!lessonType || !lessonTitle) {
            return '';
        }
        return `[${this.lessonTypes[lessonType]}]${lessonTitle}`;
    }

    private formattedUploadedAt({ uploadedAt }) {
        if (!uploadedAt) {
            return '';
        }
        return moment.unix(uploadedAt).format('YYYY-MM-DD HH:mm:ss');
    }
}
