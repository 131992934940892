
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Ref } from 'vue-property-decorator';

@Component({})
export default class InputRelatedCustomer extends Vue {
    @Provide() private customer: { [index: string]: any } = {
        phone: '',
        relatedPhone: '',
        remark: '',
    };
    @Provide() private submitting: boolean = false;
    @Provide() private rules: { [index: string]: object[] } = {
        phone: [
            { required: true, message: '请输入客户原手机号', trigger: 'blur' },
            { validator: this.validatePhone, trigger: 'blur' },
        ],
        relatedPhone: [
            { required: true, message: '请输入客户新手机号', trigger: 'blur' },
            { validator: this.validatePhone, trigger: 'blur' },
        ],
        remark: [
            { max: 500, message: '备注长度不超过 500 个字符', trigger: 'blur'  },
        ],
    };

    @Ref() private readonly customerForm;

    private validatePhone(rule: any, value: any, callback: any) {
        if (!value) {
            return callback();
        }
        const phoneReg = /^\d{11}$|^\+\d{8,}$/;
        if (!phoneReg.test(value)) {
            callback(new Error('请填写正确的手机号码'));
        }
        callback();
    }

    private submitForm() {
        this.customerForm.validate((valid) => {
            if (!valid) {
                return false;
            }
            this.submitting = true;
            axios.post('/api/v1/crm/related-customer-input', this.customer).then(() => {
                this.$message({
                    type: 'success',
                    message: '客户录入成功',
                });
                this.clearForm();
            }).catch((error) => {
                this.$message.error(error);
            }).then(() => {
                this.submitting = false;
            });
        });
    }
    private clearForm() {
        this.customer = {
            phone: '',
            relatedPhone: '',
            remark: '',
        };
        this.customerForm.clearValidate();
    }
}

