
import Vue from 'vue';
import { Component, ProvideReactive, Provide, Ref } from 'vue-property-decorator';
import axios from '../../plugins/axios.js';
import StudentsWithFinishedLiveCourses from './widgets/StudentsWithFinishedLiveCourses.vue';
import StudentsWithLearningLiveCourses from './widgets/StudentsWithLearningLiveCourses.vue';
import StudentsWithAiCourses from './widgets/StudentsWithAiCourses.vue';
import StudentsWithPausedCourses from './widgets/StudentsWithPausedCourses.vue';

@Component({
    components: {
        StudentsWithFinishedLiveCourses,
        StudentsWithLearningLiveCourses,
        StudentsWithAiCourses,
        StudentsWithPausedCourses,
    },
})
export default class StudentsManagement extends Vue {
    @ProvideReactive() private userName: string = '';
    @ProvideReactive() private isAdvisorManager: boolean = false;
    @ProvideReactive() private advisorsOptions: any[] = [];
    @ProvideReactive() private stagesAndGradesOptions: any[] = [];
    @ProvideReactive() private provincesOptions: any[] = [];
    @ProvideReactive() private liveSubClassifiesOptions: any[] = [];
    @ProvideReactive() private defaultLiveCoursesSubClassifies: any[] = [];
    @ProvideReactive() private defaultAiCoursesSubClassifies: any[] = [];
    @ProvideReactive() private aiSubClassifiesOptions: any[] = [];
    @ProvideReactive() private continuousStatusesOptions: any[] = [];
    @ProvideReactive() private learningStatusesOptions: any[] = [];
    @ProvideReactive() private instructorsOptions: any[] = [];

    @ProvideReactive() private studentName: string = '';
    @ProvideReactive() private studentAccount: string = '';
    @ProvideReactive() private schoolName: string = '';

    @ProvideReactive() private currentStudentsTab: string = 'studentsWithFinishedLiveCourses';

    @ProvideReactive() private countOfStudentsWithFinishedLiveCourses: number = 0;
    @ProvideReactive() private countOfStudentsWithLearningLiveCourses: number = 0;
    @ProvideReactive() private countOfStudentsWithAiCourses: number = 0;
    @ProvideReactive() private countOfStudentsWithPausedCourses: number = 0;

    @Provide() private loading: boolean = false;
    @Ref() private studentsWithFinishedLiveCourses;
    @Ref() private studentsWithLearningLiveCourses;
    @Ref() private studentsWithAiCourses;
    @Ref() private studentsWithPausedCourses;

    public created() {
        this.getCommonFilterOptions();
    }

    public getCommonFilterOptions() {
        this.loading = true;
        axios.get('/api/v1/advisor/filter-options').then((response) => {
            this.userName = response.userName;
            this.isAdvisorManager = response.isAdvisorManager;
            this.advisorsOptions = this.generateAdvisorsOptions(response.advisors);
            this.stagesAndGradesOptions = this.generateStagesAndGradesOptions(response.statuses, response.grades);
            this.provincesOptions = this.generateProvincesOptions(response.provinces);
            this.liveSubClassifiesOptions = this.generateSubClassifiesOptions(response.liveCoursesSubClassifies);
            this.defaultLiveCoursesSubClassifies = response.defaultLiveCoursesSubClassifies;
            this.defaultAiCoursesSubClassifies = response.defaultAiCoursesSubClassifies;
            this.aiSubClassifiesOptions = this.generateSubClassifiesOptions(response.aiCoursesSubClassifies);
            this.continuousStatusesOptions = this.generateContinuousStatusesOptions(response.continuousStatuses);
            this.learningStatusesOptions = this.generateLearningStatusesOptions(response.learningStatuses);
            this.instructorsOptions = this.generateInstructorsOptions(response.instructors);
            this.loading = false;
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    public generateAdvisorsOptions(advisors) {
        const options: any = [];
        for (const advisor of advisors) {
            options.push({
                text: advisor.name,
                value: advisor.uid,
            });
        }
        return options;
    }


    public generateStagesAndGradesOptions(statuses, grades) {
        const options: any = [];
        for (const status of statuses) {
            for (const grade of grades[status.code]) {
                const unknownStatusCode = 3;
                const optionTitle = parseInt(status.code, 0) !== unknownStatusCode ? `${status.title}/${grade.title}` : status.title;
                const optionValue = `${status.code}/${grade.code}`;
                options.push({
                    text: optionTitle,
                    value: optionValue,
                });
            }
        }
        return options;
    }

    public generateProvincesOptions(provinces) {
        const options: any = [];
        for (const province of provinces) {
            options.push({
                text: province.name,
                value: province.name,
            });
        }
        return options;
    }

    public generateSubClassifiesOptions(classifies) {
        const options: any = [];
        for (const key in classifies) {
            if (!classifies.hasOwnProperty(key)) {
                continue;
            }
            options.push({
                text: classifies[key],
                value: parseInt(key, 0),
            });
        }
        return options;
    }

    public generateContinuousStatusesOptions(continuousStatuses) {
        const options: any = [];
        for (const key in continuousStatuses) {
            if (!continuousStatuses.hasOwnProperty(key)) {
                continue;
            }
            options.push({
                text: key,
                value: key,
            });
        }
        return options;
    }

    public generateLearningStatusesOptions(learningStatuses) {
        const options: any = [];
        for (const key in learningStatuses) {
            if (!learningStatuses.hasOwnProperty(key)) {
                continue;
            }
            options.push({
                text: key,
                value: learningStatuses[key],
            });
        }
        return options;
    }

    public generateInstructorsOptions(instructors) {
        const options: any[] = [];
        for (const key in instructors) {
            if (instructors.hasOwnProperty(key) && instructors[key].name) {
                options.push({
                    text: instructors[key].name,
                    value: instructors[key].id,
                });
            }
        }
        return options;
    }

    public queryStudents() {
        const userName = this.userName;
        this.userName = '';
        this.$nextTick(() => {
            this.userName = userName;
        });
    }

    public handleStudentCourseUpdated() {
        this.studentsWithFinishedLiveCourses.queryStudents();
        this.studentsWithLearningLiveCourses.queryStudents();
        this.studentsWithAiCourses.queryStudents();
        this.studentsWithPausedCourses.queryStudents();
    }
}
