
import Vue from 'vue';
import axios from '@/plugins/axios.js';
import { Component, Prop, Ref } from 'vue-property-decorator';
import Editor from 'jellies-editor';

@Component({
  components: {
    Editor,
  },
})
export default class MarkdownEditor extends Vue {
    @Prop() private placeholder!: any;
    @Ref() private readonly editor;

    private getValue() {
        return this.editor.getHTML();
    }
    private setValue(value) {
        if (value || value === '') {
            this.editor.setHTML(value);
        }
    }

    private uploadImage($file) {
        return new Promise((resolve) => {
            const formdata = new FormData();
            formdata.append('file', $file);
            axios.upload('/api/v1/common/upload-multimedia', formdata).then(({ file_path }) => {
                resolve(file_path);
            }).catch((error) => {
                this.$message.error(error);
            });
        });
    }
}
