
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Prop, Ref } from 'vue-property-decorator';
import _ from 'lodash';

@Component({})
export default class UpdateDepartment extends Vue {
    @Prop() private permissions;

    @Provide() private isAddDepartment: boolean = true;
    @Provide() private showDialog: boolean = false;
    @Provide() private submitting: boolean = false;

    @Provide() private department: { [index: string]: any } = {
        id: 0,
        title: '',
    };
    @Ref() private readonly permissionsTree;

    private created() {
        this.$on('open', (data) => {
            this.showDialog = true;
            this.updateSelectedDepartment(data);
        });
    }

    private updateSelectedDepartment({ department, departmentPermissions }) {
        this.isAddDepartment = department.id === 0;
        this.department = _.cloneDeep(department);
        this.$nextTick(() => {
            this.permissionsTree.setCheckedKeys(departmentPermissions);
        });
    }

    private updateDepartment() {
        if (!this.department.title) {
            return this.$message.error('请填写部门名');
        }
        if (this.isAddDepartment && !this.department.administrator_role) {
            return this.$message.error('请填写管理员角色名');
        }
        const permissions = this.permissionsTree.getCheckedKeys().filter((permission) => permission);
        if (permissions.length < 1) {
            return this.$message.error('请为该部门配置至少 1 个功能权限');
        }
        if (!this.isAddDepartment) {
            this.editDepartmentConfirm(permissions);
            return;
        }
        this.requestUpdate(permissions);
    }

    private editDepartmentConfirm(permissions) {
        this.$confirm('若为该部门增加了权限，需要修改部门中角色的权限以让部门成员拥有该权限；若为该部门移除了某一权限，则该部门中拥有该权限的角色也会失去该权限，确认要保存吗？', '编辑部门信息', {
            confirmButtonText: '确认',
            confirmButtonClass: 'el-button--danger',
            cancelButtonText: '取消',
        }).then(() => {
            this.requestUpdate(permissions);
        }).catch();
    }

    private requestUpdate(permissions) {
        let url = '/api/v1/authority-manage/add-department';
        if (!this.isAddDepartment) {
            url = '/api/v1/authority-manage/edit-department';
        }
        this.submitting = true;
        axios.post(url, {
            ...this.department,
            permissions,
        }).then(() => {
            this.$message({
                type: 'success',
                message: '更新部门信息成功',
            });
            this.showDialog = false;
            this.$emit('department-updated', this.department);
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.submitting = false;
        });
    }
}
