
import Vue from 'vue';
import { Provide, Component, Ref } from 'vue-property-decorator';
import axios from '../../plugins/axios.js';
import UpdateInstructorModal from './modals/UpdateInstructor.vue';

@Component({
    components: {
        UpdateInstructorModal,
    },
})

export default class Instructors extends Vue {
    @Provide() private instructors = [];
    @Provide() private queriedCondition: any = {
        phone: '',
        name: '',
        page: 1,
        certificateStatus: '',
    };
    @Provide() private pageSize: number = 10;
    @Provide() private totalInstructors: number = 0;
    @Provide() private loading: boolean = true;
    @Provide() private selectedInstructor = {};
    @Provide() private certificateStatuses: object[] = [
        {
            status: '未参加教师资格考试',
            text: '未参加教师资格考试',
            color: 'danger',
        },
        {
            status: '通过教师资格证笔试',
            text: '通过教师资格证笔试',
            color: 'warning',
        },
        {
            status: '已取得教师资格考试合格证明',
            text: '已取得教师资格考试合格证明',
            color: 'warning',
        },
        {
            status: '已取得教师资格证',
            text: '教师资格证编号：',
            color: 'success',
        },
    ];
    @Ref() private readonly updateInstructorModal;

    private mounted() {
        this.getInstructors();
    }

    private queryInstructor() {
        this.queriedCondition.page = 1;
        this.getInstructors();
    }

    private changePage(page: number) {
        this.queriedCondition.page = page;
        this.getInstructors();
    }

    private getInstructors() {
        this.loading = true;
        axios.get('/api/v1/instructors', this.queriedCondition).then(({ instructors, total }) => {
            this.instructors = instructors;
            this.totalInstructors = total;
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.loading = false;
        });
    }

    private initializeInstructorAndOpenUpdateModal() {
        const selectedInstructor = {
            id: 0,
            name: '',
            avatar: '' ,
            remarks: '',
            phone: '',
            disabled: 0,
            certificateStatus: null,
            certificateNumber: '',
            introduction: '',
            trialLectureVideo: '',
        };
        this.openUpdateInstructorModal(selectedInstructor);
    }
    private openUpdateInstructorModal(selectedInstructor) {
        this.selectedInstructor = selectedInstructor;
        this.updateInstructorModal.$emit('open');
    }

    private openConfirmDeleteInstructorModal(instructor) {
        this.$confirm('教师被禁用后，将无法被添加为某一门课的课程教师，是否继续？', '禁用教师', {
            confirmButtonText: '确定',
            confirmButtonClass: 'el-button--danger',
            cancelButtonText: '取消',
        }).then(() => {
            this.deleteInstructor(instructor);
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消禁用',
            });
        });
    }

    private deleteInstructor(instructor) {
        axios.delete(`/api/v1/instructor/${instructor.id}`).then(() => {
            this.$message({
                type: 'success',
                message: '禁用教师成功',
            });
            this.queryInstructor();
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    private formattedInstructorCertificateStatusClass(instructor) {
        return `st-color-${(this.certificateStatuses[instructor.certificateStatus] as any).color}`;
    }

    private formattedInstructorCertificateStatus(instructor) {
        return `${(this.certificateStatuses[instructor.certificateStatus] as any).text}${instructor.certificateNumber}`;
    }
}
