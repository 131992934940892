
import Vue from 'vue';
import { Provide, Component, Prop } from 'vue-property-decorator';
import StudentPausedCourses from '../widgets/StudentPausedCourses.vue';
import StudentCouponVouchers from '../widgets/StudentCouponVouchers.vue';
import StudentBenefitsVouchers from '../widgets/StudentBenefitsVouchers.vue';

@Component({
    components: {
        StudentPausedCourses,
        StudentCouponVouchers,
        StudentBenefitsVouchers,
    },
})
export default class StudentVouchers extends Vue {
    @Prop() public student!: any;
    @Provide() public showDialog: boolean = false;
    @Provide() public activeTab: string = 'pausedCourses';

    private created() {
        this.$on('open', () => {
            this.showDialog = true;
        });
    }

    private handleStudentCourseUpdated() {
        this.$emit('student-course-updated');
    }
}
