
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Prop } from 'vue-property-decorator';
@Component({})
export default class StudentBenefitsVouchers extends Vue {
    @Prop() public student!: any;
    @Provide() public loading: boolean = false;
    @Provide() public vouchers: any = [];

    private created() {
        this.getVouchers();
    }

    private getVouchers() {
        this.loading = true;
        axios.get('/api/v1/advisor/student-benefits-vouchers', {
            studentUuid: this.student.uuid,
        }).then((vouchers) => {
            this.vouchers = vouchers;
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.loading = false;
        });
    }

    private toggleVoucherUsedStatus(voucher) {
        axios.post('/api/v1/advisor/redeem-student-voucher', {
            redeemType: 'benefits',
            userVoucherId: voucher.userVoucherId,
        }).catch((error) => {
            voucher.isUsed = voucher.isUsed ? 0 : 1;
            this.$message.error(error);
        });
    }
}
