
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Ref } from 'vue-property-decorator';
import MultipleInputModal from '../modals/MultipleInput.vue';

@Component({
    components: {
        MultipleInputModal,
    },
})
export default class InputMultipleCustomers extends Vue {
    @Provide() private records: object[] = [];
    @Provide() private queriedCondition: { [index: string]: any } = {
        sort: 'descending',
        page: 1,
    };
    @Provide() private pageSize: number = 10;
    @Provide() private totalRecords: number = 0;
    @Provide() private loading: boolean = true;
    @Ref() private readonly recordsTable;
    @Ref() private readonly multipleInputModal;

    private mounted() {
        this.getRecords();
    }

    private changePage(page: number) {
        this.queriedCondition.page = page;
        this.getRecords();
    }

    private changeSort(val: any) {
        this.queriedCondition.sort = val.order ? val.order : 'descending';
        this.queriedCondition.page = 1;
        this.getRecords();
    }

    private refreshRecords() {
        this.queriedCondition.page = 1;
        this.getRecords();
    }

    private getRecords() {
        this.loading = true;
        axios.get('/api/v1/crm/multiple-input-record', this.queriedCondition).then(({ records, total }) => {
            this.records = records;
            this.totalRecords = total;
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.loading = false;
        });
    }

    private openMultipleInputModal() {
        this.multipleInputModal.$emit('open');
    }

    private downloadTemplate() {
        window.location.href = 'https://static.jisuanke.com/file/%E5%AE%A2%E6%88%B7%E4%BF%A1%E6%81%AF%E6%89%B9%E9%87%8F%E5%BD%95%E5%85%A52022.xlsx';
    }

    private downloadFailedFile(id) {
        window.location.href = `${process.env.VUE_APP_API_DOMAIN}/api/v1/crm/customer-input-failed-file?id=${id}`;
    }
}
