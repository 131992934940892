
import Vue from 'vue';
import axios from '@/plugins/axios.js';
import { Provide, Component, Prop, Watch, Ref } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import AdForm from '../widgets/AdForm.vue';
import _ from 'lodash';

@Component({
    components: {
        AdForm,
    },
})
export default class UpdateAd extends Vue {
    @Prop() private selectedAd!: any;
    @Prop() private adChannels!: object[];
    @Prop() private adChannelsConfig!: object[];
    @Provide() private showDialog: boolean = false;
    @Provide() private submitting: boolean = false;
    @Provide() private adInfo: any = {};
    @Ref() private readonly adFormContainer;

    private created() {
        this.$on('open', () => {
            this.showDialog = true;
        });
    }

    @Watch('selectedAd', { deep: true })
    private updateSelectedAd() {
        this.adInfo = _.cloneDeep(this.selectedAd);
    }

    private get isAddAd() {
        return this.adInfo.id === 0;
    }

    private get title() {
        if (this.isAddAd) {
            return '添加广告';
        }
        return '编辑广告';
    }

    private get updateAdUrl() {
        if (this.isAddAd) {
            return '/api/v1/ad-setting/create-ad';
        }
        return '/api/v1/ad-setting/edit-ad';
    }
    private get adForm() {
        return this.adFormContainer.$refs.adForm as ElForm;
    }


    private clearValidate() {
        this.adForm.clearValidate();
    }

    private getSubmittedParams() {
        let params: any = {};
        params.id = this.adInfo.id;
        params.url = this.adInfo.url;
        params.channel = this.adInfo.channel;
        params.start_at = this.adInfo.startDateTime;
        params.end_at = this.adInfo.endDateTime ? this.adInfo.endDateTime : null;
        let channelConfig: any = this.adChannelsConfig[this.adInfo.channel];
        if (channelConfig.titleType === 'titleOnly') {
            params.title = this.adInfo.adCopy;
        } else if (channelConfig.titleType === 'titleAndLink') {
            params.title = [this.adInfo.adCopy, this.adInfo.copyUrl];
        }
        if (channelConfig.pcImage.show) {
            params.pc_img = this.adInfo.pcImage.length ? this.adInfo.pcImage[0].url : null;
        }
        if (channelConfig.mobileImage.show) {
            params.mobile_img = this.adInfo.mobileImage.length ? this.adInfo.mobileImage[0].url : null;
        }
        return params;
    }

    private updateAd() {
        this.adForm.validate((valid) => {
            if (!valid) {
                return false;
            }
            const params = this.getSubmittedParams();
            this.submitting = true;
            axios.post(this.updateAdUrl, params).then(() => {
                this.$message({
                    type: 'success',
                    message: '更新广告成功',
                });
                this.submitting = false;
                this.showDialog = false;
                this.adInfo = {};
                this.$emit('refresh-ads');
            }).catch((error) => {
                this.submitting = false;
                this.$message.error(error);
            });
        });
    }
}
