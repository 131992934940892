
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Prop, InjectReactive } from 'vue-property-decorator';
import moment from 'moment';

@Component
export default class SendMessageToParent extends Vue {
    @InjectReactive() public userName;
    @Prop() private student!: any;
    @Provide() private messageContent: any = '';
    @Provide() private showDialog: boolean = false;
    @Provide() private isRequesting: boolean = false;
    @Provide() private currentTime = moment().format('YYYY-MM-DD HH:mm');

    private created() {
        this.$on('open', () => {
            this.showDialog = true;
            this.messageContent = '';
        });
    }

    private submit() {
        if (!this.messageContent) {
            this.$message.error('请填写通知内容');
            return;
        }
        this.isRequesting = true;
        axios.post('/api/v1/advisor/send-message-to-parent', {
            studentUuid: this.student.uuid,
            courseId: this.student.courseId,
            content: this.messageContent,
        }).then(() => {
            this.$message({
                type: 'success',
                message: '家长消息通知已发送成功',
            });
            this.showDialog = false;
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.isRequesting = false;
        });
    }
}
