
import axios from '../../../plugins/axios.js';
import { Provide, Component, Ref } from 'vue-property-decorator';
import RedeemVoucherToCourseModal from '../modals/RedeemVoucherToCourse.vue';
import StudentsWithFinishedLiveCourses from './StudentsWithFinishedLiveCourses.vue';
import ConfirmSwitchCourseModal from '../modals/ConfirmSwitchCourse.vue';

@Component({
    components: {
        RedeemVoucherToCourseModal,
        ConfirmSwitchCourseModal,
    },
})
export default class StudentsWithPausedCourses extends StudentsWithFinishedLiveCourses {
    @Provide() public type: string = 'paused';

    @Provide() private selectedVoucher: any = {};
    @Provide() private selectedCourse: any = {};

    @Ref() private readonly redeemVoucherToCourseModal;
    @Ref() private readonly confirmSwitchCourseModal;

    private openRedeemVoucherToCourseModal(voucher) {
        this.selectedVoucher = voucher;
        this.redeemVoucherToCourseModal.$emit('open');
    }

    private openSwitchCourseModal(selectedStudent, selectedCourse) {
        this.selectedCourse = selectedCourse;
        this.selectedStudent = selectedStudent;
        this.confirmSwitchCourseModal.$emit('open');
    }

    private openDeleteStudentVoucherConfirmModal(voucher) {
        this.$confirm(`确认对用户《${voucher.title}》执行退班操作吗？该操作无法恢复`, '确认退班', {
            confirmButtonText: '确认',
            confirmButtonClass: 'el-button--danger',
            cancelButtonText: '取消',
        }).then(() => {
            this.deleteStudentVoucher(voucher);
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消退班',
            });
        });
    }

    private deleteStudentVoucher(voucher) {
        axios.post('/api/v1/advisor/delete-student-voucher', {
            userVoucherId: voucher.userVoucherId,
        }).then(() => {
            this.$message({
                type: 'success',
                message: '用户退班成功',
            });
            this.queryStudents();
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    private openDropStudentCourseConfirmModal(student, course) {
        this.$confirm(`确认对用户《${course.title}》班级执行退班操作吗？该操作无法恢复`, '确认退班', {
            confirmButtonText: '确认',
            confirmButtonClass: 'el-button--danger',
            cancelButtonText: '取消',
        }).then(() => {
            this.confirmDrop(student, course);
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消退班',
            });
        });
    }

    private confirmDrop(student, course) {
        axios.post('/api/v1/advisor/drop-student-course', {
            studentUuid: student.uuid,
            courseId: course.courseId,
        }).then(() => {
            this.$message({
                type: 'success',
                message: '用户退班成功',
            });
            this.queryStudents();
        }).catch((error) => {
            this.$message.error(error);
        });
    }
}
