
import Vue from 'vue';
import { Provide, Component, Prop, Ref } from 'vue-property-decorator';
@Component
export default class InputExpressMixins extends Vue {
    // 物流公司列表
    @Prop() public channels!: { [index: string]: string };
    @Provide() public showDialog: boolean = false;
    @Provide() public expressInfo: { [index: string]: string } = {
        channel: '',
        number: '',
    };
    @Provide() public rules: { [index: string]: object[] } = {
        channel: [
            { required: true, message: '请选择物流公司', trigger: 'change' },
        ],
        number: [
            { required: true, message: '请填写快递单号', trigger: 'blur' },
        ],
    };
    @Ref() public readonly expressForm;
    public created() {
        this.$on('open', () => {
            this.showDialog = true;
        });
    }
    public clearValidate() {
        this.expressForm.clearValidate();
    }
}
