
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Prop, Ref } from 'vue-property-decorator';
@Component
export default class UpdateLecture extends Vue {
    @Prop() private courseId!: number;
    @Provide() private lecture: any = {};
    @Provide() private showDialog: boolean = false;
    @Provide() private placeholderOfVideoIdInput: string = '请填写回放视频ID';
    @Provide() private rules: { [index: string]: object[] } = {
        time: [
            { required: true, message: '请选择上课时间' },
        ],
        date: [
            { required: true, message: '请选择上课日期' },
        ],
    };
    @Ref() private lectureForm;

    private created() {
        this.$on('open', (selectedLecture) => {
            this.showDialog = true;
            this.lecture = {
                time: selectedLecture.start_time,
                date: selectedLecture.course_date,
                minutes: selectedLecture.minutes,
                videoId: selectedLecture.videoId,
                chapterId: selectedLecture.chapter_id,
                chapterLessonId: selectedLecture.chapterLessonId,
            };
        });
    }

    private clearValidate() {
        this.lectureForm.clearValidate();
    }

    private updateLecture() {
        this.lectureForm.validate((valid) => {
            if (!valid) {
                return false;
            }
            this.$emit('update-lecture', this.lecture);
            this.showDialog = false;
        });
    }

    private jumpToVideo() {
        window.open(`${process.env.VUE_APP_MAINSITE_HREF}/course/${this.courseId}/${this.lecture.chapterId}/${this.lecture.chapterLessonId}`, '_blank');
    }
}
