
import { Component, Provide, Vue } from 'vue-property-decorator';
import axios from '../../plugins/axios.js';

@Component
export default class DropCourseRecord extends Vue {
    @Provide() private queriedCondition: { [index: string]: any } = {
        courseId: '',
        uuid: '',
        phone: '',
        sort: 'descending',
        page: '1',
    };
    @Provide() private currentPage: number = 1;
    @Provide() private pageSize: number = 10;
    @Provide() private totalRecords: number = 0;
    @Provide() private loading: boolean = false;
    @Provide() private records: any[] = [];
    private mounted() {
        this.getRecords();
    }
    private changeSort(sort: any) {
        this.queriedCondition.sort = sort.order ? sort.order : 'descending';
        this.queriedCondition.page = '1';
        this.getRecords();
    }
    private changePage(page: number) {
        this.queriedCondition.page = page.toString();
        this.getRecords();
    }
    private queryRecord() {
        this.queriedCondition.page = '1';
        this.getRecords();
    }
    private getRecords() {
        this.loading = true;
        const url = '/api/v1/drop-course-record/get-records';
        axios.get(url, this.queriedCondition).then((response: any) => {
            this.records = response.records;
            this.totalRecords = response.total;
            this.currentPage = parseInt(this.queriedCondition.page, 10);
            this.loading = false;
        }).catch((error) => {
            this.loading = false;
            this.$message.error(error);
        });
    }
}
