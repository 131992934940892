
import axios from '@/plugins/axios.js';
import { Provide, Component, Prop, Mixins } from 'vue-property-decorator';
import InputExpressMixins from '@/views/shared/InputExpressMixins.vue';
interface ExpressInfo {
    uuid: string;
    name?: string;
    phone?: string;
    address?: string;
}
@Component
export default class InputExpress extends Mixins(InputExpressMixins) {
    @Provide() public expressInfo: { [index: string]: string } = {
        content: '',
        channel: '',
        number: '',
    };
    @Provide() public rules: { [index: string]: object[] } = {
        content: [
            { required: true, message: '请输入寄件内容', trigger: 'blur' },
        ],
        channel: [
            { required: true, message: '请选择物流公司', trigger: 'change' },
        ],
        number: [
            { required: true, message: '请填写快递单号', trigger: 'blur' },
        ],
    };
    @Prop() private selectedExpress!: ExpressInfo;
    private submit() {
        this.expressForm.validate((valid) => {
            if (!valid) {
                return false;
            } else {
                const url = '/api/v1/express/create-record';
                const params = {
                    content: this.expressInfo.content,
                    uuid: this.selectedExpress.uuid,
                    channel: this.expressInfo.channel,
                    express_number: this.expressInfo.number,
                };
                axios.post(url, params).then(() => {
                    this.$message({
                        type: 'success',
                        message: '快递信息录入成功，短信与服务号通知已发出',
                    });
                    this.expressInfo.number = '';
                    this.showDialog = false;
                }).catch((error) => {
                    this.$message.error(error);
                });
            }
        });
    }

}
