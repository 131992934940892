
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Prop } from 'vue-property-decorator';
@Component
export default class RedeemVoucherToCourse extends Vue {
    @Prop() private voucher;
    @Provide() private courseId: any = null;
    @Provide() private showDialog: boolean = false;
    @Provide() private submitting: boolean = false;

    public created() {
        this.$on('open', () => {
            this.showDialog = true;
        });
    }

    private redeemVoucherToCourse() {
        if (!this.courseId) {
            this.$message.error('请选择要转入的班级');
            return;
        }
        this.submitting = true;
        axios.post('/api/v1/advisor/redeem-student-voucher', {
            courseId: this.courseId,
            redeemType: 'course',
            userVoucherId: this.voucher.userVoucherId,
        }).then(() => {
            this.$message({
                type: 'success',
                message: '转入班级成功',
            });
            this.submitting = false;
            this.showDialog = false;
            this.$emit('redeem-completed');
        }).catch((error) => {
            this.submitting = false;
            this.$message.error(error);
        });
    }
}
