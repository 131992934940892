
import Vue from 'vue';
import moment from 'moment';
import { Provide, Component, Prop } from 'vue-property-decorator';

@Component
export default class CourseLecture extends Vue {
    @Prop() private lecture!: any;
    @Provide() private showDialog: boolean = false;

    private created() {
        this.$on('open', () => {
            this.showDialog = true;
        });
    }

    private get lectureTitle() {
        return `#${this.lecture.course_id} ${this.lecture.title}`;
    }

    private get lectureCourseCategory() {
        return `${this.lecture.main_classify} / ${this.lecture.sub_classify}`;
    }

    private get lectureDuration() {
        const startAt = `${this.lecture.course_date} ${this.lecture.start_time}`;
        const endAt = moment(startAt).add(this.lecture.minutes, 'm').format('yyyy-MM-DD HH:mm:ss');
        return `${startAt} ~ ${endAt}`;
    }
}
