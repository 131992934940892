
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Provide, Component } from 'vue-property-decorator';

@Component
export default class MultipleInput extends Vue {
    @Provide() private showDialog: boolean = false;
    @Provide() private files: any[] = [];
    @Provide() private progress: number = 0;
    @Provide() private submitting: boolean = false;

    private created() {
        this.$on('open', () => {
            this.showDialog = true;
        });
    }

    private beforeUpload(newFile) {
        if (newFile.size > 10 * 1024 * 1024) {
            this.$message.error('请上传文件大小不超过 10 MB 的文档');
        } else if (this.files.length > 0) {
            this.$message.error('每次只能上传一个文件');
        } else {
            this.files.push({ name: newFile.name, url: '', file: newFile, status: 'ready' });
        }
        return false;
    }

    private removeFile(file, files) {
        if (file.hasOwnProperty('file')) {
            for (let index in this.files) {
                if (this.files[index].uid === file.uid) {
                    this.files.splice(Number(index), 1);
                    return;
                }
            }
        }
    }

    private inputCustomer() {
        if (this.files.length === 0) {
            return this.$message.error('请先选取文件，再录入客户信息');
        }
        this.submitting = true;
        const url = '/api/v1/crm/file-multiple-input';
        const params = new FormData();
        params.append('file', this.files[0].file);
        axios.post(url, params).then(() => {
            this.$message({
                type: 'success',
                message: '正在录入客户信息，稍后请刷新网页查看录入记录',
            });
            this.submitting = false;
            this.files = [];
            this.$emit('refreshRecords');
            this.showDialog = false;
        }).catch((error) => {
            this.submitting = false;
            this.$message.error(error);
        });
    }
}
