
import Vue from 'vue';
import _ from 'lodash';
import axios from '../../../plugins/axios.js';
import { InjectReactive, Prop, Provide, Component, Ref } from 'vue-property-decorator';
import MarkdownEditor from '../../../components/shared/MarkdownEditor.vue';

@Component({
    components: {
        MarkdownEditor,
    },
})
export default class EditLiveCourse extends Vue {
    @InjectReactive() private advisorsOptions;
    @InjectReactive() private liveSubClassifiesOptions;

    @Prop() private courseType!: any;
    @Prop() private courseClassifyOptions!: any;

    @Provide() private course: any = {};
    @Provide() private showDialog: boolean = false;
    @Provide() private rules: { [index: string]: object[] } = {
        title: [
            { required: true, message: '请填写课程名', trigger: 'blur' },
        ],
        background: [
            { required: true, message: '请上传课程头图', trigger: 'blur' },
        ],
        price: [
            { required: true, message: '请填写整课价格', trigger: 'blur' },
        ],
        classify: [
            { required: true, message: '请选择课程分类', trigger: 'change' },
        ],
        numberOfLectures: [
            { required: true, message: '请填写总讲次', trigger: 'blur' },
        ],
    };

    @Ref() private readonly courseForm;
    @Ref() private readonly introductionEditor;
    @Ref() private readonly backgroundUpload;

    private created() {
        this.$on('open', (course) => {
            this.showDialog = true;
            this.course = _.cloneDeep(course);
            this.$nextTick(() => {
                this.course.advisorUid = this.course.advisorUid ? this.course.advisorUid : null;
                this.introductionEditor.setValue(course.introduction);
            });
        });
    }

    private updateCourse() {
        this.courseForm.validate((valid) => {
            if (!valid) {
                return false;
            }
            axios.post('/api/v1/courses-management/update-course', {
                ...this.course,
                advisorUid: this.course.advisorUid ? this.course.advisorUid : 0,
                introduction: this.introductionEditor.getValue(),
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '更新成功',
                });
                this.$emit('refresh-courses');
                this.showDialog = false;
            }).catch((error) => {
                this.$message.error(error);
            });
        });
    }

    private uploadCourseBackground(file) {
        try {
            this.isLegalFileType(file);
            this.isLegalFileSize(file);
            this.isLegalFileScale(file);
            return false;
        } catch (error: any) {
            this.$message.error(error.message);
            return false;
        }
    }

    private isLegalFileType(file) {
        const isLegalFileType = [
            'image/png',
            'image/png8',
            'image/png24',
            'image/x-png',
            'image/png',
        ].includes(file.type);
        if (!isLegalFileType) {
            throw new Error('只能上传 .png 格式文件');
        }
    }

    private isLegalFileSize(file) {
        const isLegalFileSize = file.size / 1024 < 500;
        if (!isLegalFileSize) {
            throw new Error('文件大小不能超过 500KB');
        }
    }

    private isLegalFileScale(file) {
        const _URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = () => {
            if (img.width !== 700 || img.height !== 320) {
                return this.$message.error('图片尺寸不符合要求');
            }
            this.uploadImage(file);
        };
        img.src = _URL.createObjectURL(file);
    }

    private uploadImage($file) {
        const file = new FormData();
        file.append('file', $file);
        axios.post('/api/v1/courses-management/upload-background', file).then(({ path }) => {
            this.backgroundUpload.clearFiles();
            this.course.background = path;
        }).catch((error) => {
            this.$message.error(error);
        });
    }
}
