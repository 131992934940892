
import Vue from 'vue';
import { Provide, Component, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';

@Component
export default class ExpressInfo extends Vue {
    @Prop() private selectedInvoice!: any;
    @Provide() private showDialog: boolean = false;
    @Provide() private expressDetail: object[] = [];

    private created() {
        this.$on('open', () => {
            this.showDialog = true;
        });
    }

    @Watch('selectedInvoice', { deep: true })
    private updateExpressDetail() {
        this.expressDetail = [];
        for (const detail of this.selectedInvoice.express_details.data) {
            let time = moment(detail.time).format('HH:mm');
            let date = moment(detail.time).format('YYYY-MM-DD');
            let today = moment().format('YYYY-MM-DD');
            if (moment(date).isSame(today, 'day')) {
                date = '今天';
            }
            this.expressDetail.push({
                context: detail.context,
                date,
                time,
            });
        }
    }

    private formatAddress(consigneeAddress) {
        let address: string = '';
        if (consigneeAddress) {
            if (consigneeAddress.province.substr(-1) === '市') {
                consigneeAddress.city = consigneeAddress.province;
            }
            const tempConsigneeAddress = `${consigneeAddress.province}${consigneeAddress.city}${consigneeAddress.district}${consigneeAddress.detail}`;
            address = `${address}${tempConsigneeAddress}`;
        }
        return address;
    }

    private tableRowClassName({row, rowIndex}) {
        if (rowIndex === 0) {
            return 'st-color-success';
        }
        return '';
    }
}
