
import moment from 'moment';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Prop, Mixins } from 'vue-property-decorator';
import CourseClassifyMixins from '@/views/shared/CourseClassifyMixins.vue';
@Component
export default class RedeemVoucherToCourse extends Mixins(CourseClassifyMixins) {
    @Prop() private voucher;
    @Prop() private coupon;
    @Provide() private showDialog: boolean = false;
    @Provide() private submitting: boolean = false;

    public created() {
        this.$on('open', (courseClassify) => {
            this.showDialog = true;
            this.courseClassify = courseClassify;
        });
    }

    private get endAt() {
        return moment().add(this.coupon.expireSeconds, 's').format('YYYY-MM-DD HH:mm');
    }

    private get couponCourseClassifies() {
        return this.returnFormattedCourseClassifies(this.coupon.classifyIds).split(',');
    }

    private redeemVoucherToCoupon() {
        this.submitting = true;
        axios.post('/api/v1/advisor/redeem-student-voucher', {
            redeemType: 'coupon',
            userVoucherId: this.voucher.userVoucherId,
        }).then(() => {
            this.$message({
                type: 'success',
                message: '使用成功',
            });
            this.submitting = false;
            this.showDialog = false;
            this.$emit('redeem-completed');
        }).catch((error) => {
            this.submitting = false;
            this.$message.error(error);
        });
    }
}
