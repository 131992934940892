
import Vue from 'vue';
import axios from '../../plugins/axios.js';
import { Provide, Component, Ref } from 'vue-property-decorator';
import QRCode from 'qrcode';
import StudentCoursesAndVouchers from './widgets/StudentCoursesAndVouchers.vue';

@Component({
    components: {
        StudentCoursesAndVouchers,
    },
})
export default class PayInvitation extends Vue {
    @Provide() private salesPayInvitation: { [index: string]: any } = {
        phone: '',
        courseIds: [],
        remark: '',
        voucherIds: [],
    };
    @Provide() private courseId: string = '';
    @Provide() private requesting: boolean = false;
    @Provide() private courses: any[] = [];
    @Provide() private vouchers: any[] = [];
    @Provide() private isPayInvitationCreated: boolean = false;
    @Provide() private payInvitationUrl: any = '';
    @Provide() private payInvitationAmount: any = null;
    @Provide() private student: any = null;
    @Provide() private rules: { [index: string]: object[] } = {
        phone: [
            { required: true, message: '请填写用户手机号', trigger: 'blur' },
        ],
    };
    @Ref() private readonly payInvitation;

    private mounted() {
        this.getEnabledVouchers();
    }

    private getEnabledVouchers() {
        axios.get('/api/v1/sales/vouchers').then((vouchers) => {
            this.vouchers = vouchers;
        });
    }

    private getStudent() {
        if (!this.salesPayInvitation.phone) {
            return;
        }
        axios.get('/api/v1/sales/student', {
            account: this.salesPayInvitation.phone,
        }).then((student) => {
            this.student = null;
            this.$nextTick(() => {
                this.student = student;
            });
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    private beforeAddingCourseId(courseId) {
        if (!courseId) {
            return;
        }
        if (!(/^\+?(0|[1-9]\d*)$/).test(courseId)) {
            return this.$message.error('课号必须为纯数字');
        }
        if (this.salesPayInvitation.courseIds.length >= 10) {
            return this.$message.error('单个订单最多支持 10 门课程');
        }
        const formatCourseId = parseInt(courseId, 0);
        if (this.salesPayInvitation.courseIds.indexOf(formatCourseId) !== -1) {
            return this.$message.error('当前课程已添加至课程列表');
        }
        this.checkCourse(formatCourseId);
    }

    private checkCourse(newCourseId) {
        axios.get('/api/v1/sales/get-available-course', {
            userAccount: this.salesPayInvitation.phone,
            courseId: newCourseId,
            selectedCourseIds: this.salesPayInvitation.courseIds,
        }).then(({ course }) => {
            this.courses.push(course);
            this.salesPayInvitation.courseIds.push(newCourseId);
            this.courseId = '';
        }).catch((error) => {
            this.courseId = '';
            this.$message.error(error);
        });
    }

    private removeCourse(courseId) {
        this.courses = this.courses.filter((course) => course.courseId !== courseId);
        this.salesPayInvitation.courseIds = this.salesPayInvitation.courseIds.filter((courseIdInInvitation) => courseIdInInvitation !== courseId);
    }

    private createPayInvitation() {
        this.payInvitation.validate((valid) => {
            if (!valid) {
                return false;
            }
            this.requesting = true;
            axios.post('/api/v1/sales/create-pay-invitation', this.salesPayInvitation).then(({ url, amount }) => {
                this.$message({
                    type: 'success',
                    message: '创建支付邀请成功',
                });
                this.requesting = false;
                this.payInvitationUrl = url;
                this.payInvitationAmount = amount;
                this.isPayInvitationCreated = true;
                this.$nextTick(() => {
                    QRCode.toCanvas(this.$refs.qrCodeOfPayInvitation, this.payInvitationUrl, (error) => {
                        if (error) {
                            this.$message.error(error);
                        }
                    });
                });
            }).catch((error) => {
                this.requesting = false;
                this.$message.error(error);
            });
        });
    }

    private createNewPayInvitation() {
        this.clearForm();
        this.isPayInvitationCreated = false;
    }

    private onCopy() {
        this.$message({
            type: 'success',
            message: '支付邀请链接复制成功',
        });
    }

    private clearForm() {
        this.student = null;
        this.salesPayInvitation = {
            phone: '',
            courseIds: [],
            remark: '',
            voucherIds: [],
        };
        this.courses = [];
        this.courseId = '';
    }
}
