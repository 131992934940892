
import Vue from 'vue';
import axios from '../../plugins/axios.js';
import { Provide, Component, Ref } from 'vue-property-decorator';
import UpdateAdModal from './modals/UpdateAd.vue';
@Component({
    components: {
        UpdateAdModal,
    },
})
export default class AdSetting extends Vue {
    @Provide() private ads: any = [];
    @Provide() private adChannels: any = [];
    @Provide() private adChannelsConfig: any = [];
    @Provide() private queriedCondition: { [index: string]: any } = {
        title: '',
        channel: '',
        page: '1',
    };
    @Provide() private currentPage: number = 1;
    @Provide() private pageSize: number = 10;
    @Provide() private loading: boolean = true;
    @Provide() private totalAds: number = 0;
    @Provide() private selectedAd: any = {};
    @Ref() private readonly updateAdModal;

    private mounted() {
        this.getAds();
    }
    private queryAd() {
        this.queriedCondition.page = '1';
        this.getAds();
    }
    private changePage(page: number) {
        this.queriedCondition.page = page.toString();
        this.getAds();
    }
    private getAds() {
        const url = '/api/v1/ad-setting/get-ads';
        const params = this.queriedCondition;
        this.loading = true;
        axios.get(url, params).then((response: any) => {
            this.updateAdInfo(response.ads);
            this.adChannels = response.channels;
            this.adChannelsConfig = response.channels_config;
            this.totalAds = response.total;
            this.currentPage = parseInt(this.queriedCondition.page, 0);
            this.loading = false;
        }).catch((error) => {
            this.$message.error(error);
        });
    }
    private updateAdInfo(ads: any) {
        this.ads = [];
        for (const ad of ads) {
            const adInfo = ad;
            adInfo.pcImageUrl = ad.pcImage;
            adInfo.mobileImageUrl = ad.mobileImage;
            if (ad.pcImage) {
                adInfo.pcImage = [
                    {
                        name: 'Image',
                        url: ad.pcImage,
                    },
                ];
            } else {
                adInfo.pcImage = [];
            }
            if (ad.mobileImage) {
                adInfo.mobileImage = [
                    {
                        name: 'Image',
                        url: ad.mobileImage,
                    },
                ];
            } else {
                adInfo.mobileImage = [];
            }
            if (typeof(ad.title) !== 'string') {
                adInfo.adCopy = ad.title[0];
                adInfo.copyUrl = ad.title[1];
            } else {
                adInfo.adCopy = ad.title;
            }
            this.ads.push(adInfo);
        }
    }

    private initializeAdAndOpenUpdateModal() {
        const selectedAd = {
            id: 0,
            config: {
                titleType: 'titleOnly',
                pcImage: [],
                mobileImage: [],
                hasUrl: false,
                imageType: [],
            },
            adCopy: '',
            copyUrl: '',
            url: '',
            pcImage: [],
            mobileImage: [],
            channel: '',
            startDateTime: '',
            endDateTime: '',
        };
        this.openUpdateAdModal(selectedAd);
    }

    private openUpdateAdModal(selectedAd) {
        this.selectedAd = selectedAd;
        this.updateAdModal.$emit('open');
    }
    private deleteAd(selectedAd) {
        this.$confirm('广告删除后将无法找回，是否继续？', '删除广告', {
            confirmButtonText: '确定',
            confirmButtonClass: 'el-button--danger',
            cancelButtonText: '取消',
        }).then(() => {
            const url = '/api/v1/ad-setting/delete-ad';
            const params = {
                id: selectedAd.id,
            };
            axios.post(url, params).then(() => {
                this.$message({
                    type: 'success',
                    message: '删除广告成功',
                });
                this.getAds();
            });
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除',
            });
        });
    }
}

