import { render, staticRenderFns } from "./Instructors.vue?vue&type=template&id=328a9777&scoped=true"
import script from "./Instructors.vue?vue&type=script&lang=ts"
export * from "./Instructors.vue?vue&type=script&lang=ts"
import style0 from "./Instructors.vue?vue&type=style&index=0&id=328a9777&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "328a9777",
  null
  
)

export default component.exports