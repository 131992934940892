
import Vue from 'vue';
import { Provide, Component } from 'vue-property-decorator';
import axios from '../../plugins/axios.js';
import moment from 'moment';

@Component({})
export default class TutoringManage extends Vue {
    @Provide() private questions: any = [];
    @Provide() private conditionedTotals: any = [];
    // 查询、排序条件
    @Provide() private queriedCondition: { [index: string]: any } = {
        courseId: null,
        latestInteractTime: [],
        tutor: '',
        student: '',
        page: '1',
    };
    // 当前页码
    @Provide() private currentPage: number = 1;
    // 每页显示数据条数
    @Provide() private pageSize: number = 10;
    // 教师总数
    @Provide() private totalQuestions: number = 0;
    // 是否显示加载遮罩层
    @Provide() private loading: boolean = false;
    @Provide() private pickerOptions = {};
    private mounted() {
        this.initPickerOptions();
        this.getQuestions();
    }

    private initPickerOptions() {
        const that = this;
        this.pickerOptions = {
            shortcuts: [
                {
                    text: '昨晚段',
                    onClick(picker) {
                        const startTime = moment().subtract(2, 'day').startOf('day').add(22, 'hour').format('YYYY-MM-DD HH:mm');
                        const endTime = moment().subtract(1, 'day').startOf('day').add(23 + 59 / 60, 'hour').format('YYYY-MM-DD HH:mm');
                        picker.$emit('pick', [startTime, endTime]);
                    },
                },
                {
                    text: '昨晨段(假日)',
                    onClick(picker) {
                        const startTime = moment().subtract(2, 'day').startOf('day').add(22, 'hour').format('YYYY-MM-DD HH:mm');
                        const endTime = moment().subtract(1, 'day').startOf('day').add(14 + 29 / 60, 'hour').format('YYYY-MM-DD HH:mm');
                        picker.$emit('pick', [startTime, endTime]);
                    },
                },
                {
                    text: '昨午段(假日)',
                    onClick(picker) {
                        const startTime = moment().subtract(1, 'day').startOf('day').add(14 + 30 / 60, 'hour').format('YYYY-MM-DD HH:mm');
                        const endTime = moment().subtract(1, 'day').startOf('day').add(18 + 59 / 60, 'hour').format('YYYY-MM-DD HH:mm');
                        picker.$emit('pick', [startTime, endTime]);
                    },
                },
                {
                    text: '昨晚段(假日)',
                    onClick(picker) {
                        const startTime = moment().subtract(2, 'day').startOf('day').add(23, 'hour').format('YYYY-MM-DD HH:mm');
                        const endTime = moment().subtract(1, 'day').startOf('day').add(23 + 59 / 60, 'hour').format('YYYY-MM-DD HH:mm');
                        picker.$emit('pick', [startTime, endTime]);
                    },
                },
                {
                    text: '今日段',
                    onClick(picker) {
                        const startTime = moment().startOf('day').add(0, 'hour').format('YYYY-MM-DD HH:mm');
                        const endTime = moment().startOf('day').add(23 + 59 / 60, 'hour').format('YYYY-MM-DD HH:mm');
                        picker.$emit('pick', [startTime, endTime]);
                    },
                },
            ],
        };
    }
    private hrefOfQuestion(question) {
        return `https://www.jisuanke.com/admin/tutor?questionId=${question.questionId}`;
    }
    private courseOrNantiOfQuestion(question) {
        if (question.courseId === 0) {
            return `${question.nantiId}《${question.nantiTitle}》`;
        }
        return `${question.courseId}《${question.courseTitle}》`;
    }
    private studentOfQuestion(question) {
        return `${question.studentName}(${question.studentUUid})`;
    }
    private tutorOfQuestion({ tutors }) {
        const tutorsName: string[] = [];
        tutors.forEach((tutor) => {
            tutorsName.push(`${tutor.name}(${tutor.uuid})`);
        });

        return tutorsName.join(',');
    }
    private setQuestionSolveStatus(question) {
        const url = '/api/v1/tutoring-manage/set-solve';
        const params = {
            questionId: question.questionId,
            isSolved: question.isSolved,
        };
        axios.post(url, params).catch((error) => {
            question.isSolved = !question.isSolved;
            this.$message.error(error);
        });
    }
    private setQuestionFoldStatus(question) {
        const url = '/api/v1/tutoring-manage/set-fold';
        const params = {
            questionId: question.questionId,
            isFolded: question.isFolded,
        };
        axios.post(url, params).catch((error) => {
            if (question.isFolded === 1) {
                question.isFolded = 0;
            } else {
                question.isFolded = 1;
            }
            this.$message.error(error);
        });
    }
    private queryQuestions() {
        this.queriedCondition.page = '1';
        this.getQuestions();
    }
    private changePage(page: number) {
        this.queriedCondition.page = page.toString();
        this.getQuestions();
    }
    private getQuestions() {
        this.loading = true;
        const url = '/api/v1/tutoring-manage/get-questions';
        const params = this.queriedCondition;
        if (this.queriedCondition.latestInteractTime.length === 0) {
            const isYesterdayInWeekend = moment().subtract(1, 'day').isoWeekday() > 5;
            let startTime = moment().subtract(2, 'day').startOf('day').add(22, 'hour').format('YYYY-MM-DD HH:mm');
            const endTime = moment().subtract(1, 'day').startOf('day').add(23 + 59 / 60, 'hour').format('YYYY-MM-DD HH:mm');
            if (isYesterdayInWeekend) {
                startTime = moment().subtract(2, 'day').startOf('day').add(23, 'hour').format('YYYY-MM-DD HH:mm');
            }
            this.queriedCondition.latestInteractTime = [startTime, endTime];
        }
        params.start = this.queriedCondition.latestInteractTime[0];
        params.end = this.queriedCondition.latestInteractTime[1];
        axios.get(url, params).then((response: any) => {
            this.questions = response.questions;
            this.totalQuestions = response.total;
            this.conditionedTotals = [response.conditionedTotals];
            this.currentPage = parseInt(this.queriedCondition.page, 0);
            this.loading = false;
        }).catch((error) => {
            this.$message.error(error);
        });
    }
}
