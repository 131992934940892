
import Vue from 'vue';
import { Provide, Component, Watch } from 'vue-property-decorator';
@Component
export default class CourseClassifyMixins extends Vue {
    @Provide() public courseClassify: { [index: string]: any } = {};
    @Provide() public courseClassifyOptions: any[] = [];
    @Watch('courseClassify', { deep: true })
    public updateCourseClassify() {
        this.courseClassifyOptions = this.formattedClassifiesOptions(this.courseClassify);
    }

    public formattedClassifiesOptions(classify) {
        let options: any = [];
        for (const mainClassify of Object.keys(classify.main_classify)) {
            let option = {value: parseInt(mainClassify, 0), label: classify.main_classify[mainClassify], children: new Array()};
            for (const subClassify of Object.keys(classify.sub_classify[mainClassify])) {
                option.children.push({ value: parseInt(subClassify, 0), label: classify.sub_classify[mainClassify][subClassify]});
            }
            options.push(option);
        }
        return options;
    }

    public returnFormattedCourseClassifies(courseClassifies) {
        let classifies: any = [];
        courseClassifies.forEach((classifyId) => {
            const classifyTitle = `${this.courseClassify.main_classify[classifyId[0]]}/${this.courseClassify.sub_classify[classifyId[0]][classifyId[1]]}`;
            classifies.push(classifyTitle);
        });
        return classifies.join(', ');
    }
}
