
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Prop, InjectReactive } from 'vue-property-decorator';
import _ from 'lodash';

@Component
export default class UpdateMember extends Vue {
    @InjectReactive() private isAdmin;
    @InjectReactive() private departmentRoles;

    @Prop() private departmentId;

    @Provide() private isAddMember: boolean = false;
    @Provide() private showDialog: boolean = false;
    @Provide() private submitting: boolean = false;
    @Provide() private member: any = {};
    @Provide() private originalRoles: any = [];
    @Provide() private rules: { [index: string]: object[] } = {
        phone: [
            { required: true, message: '请填写注册手机号', trigger: 'blur' },
            { validator: this.validatePhone, trigger: 'blur' },
        ],
    };

    private created() {
        this.$on('open', (member) => {
            this.showDialog = true;
            this.member = _.cloneDeep(member);
            this.originalRoles = _.cloneDeep(member.roles);
            this.isAddMember = !member.uuid;
        });
    }

    private validatePhone(rule: any, value: any, callback: any) {
        if (!value) {
          return callback(new Error('请填写注册手机号'));
        }
        axios.get('/api/v1/authority-manage/member', {
            phone: value,
            departmentId: this.departmentId,
        }).then(({ name, nickname }) => {
            this.member.name = name;
            this.member.nickname = nickname;
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    private submitEditMember() {
        if (!this.member.name || this.member.roles.length === 0) {
            return;
        }
        this.submitting = true;
        const url = this.isAddMember ? '/api/v1/authority-manage/add-member' : '/api/v1/authority-manage/edit-member';
        axios.post(url, {
            departmentId: this.departmentId,
            originalRoles: this.originalRoles,
            ...this.member,
        }).then(() => {
            this.$message({
                type: 'success',
                message: '成员信息更新成功',
            });
            this.showDialog = false;
            this.$emit('refresh-members');
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.submitting = false;
        });
    }
}
