
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Prop, Ref } from 'vue-property-decorator';

@Component
export default class AddWebsite extends Vue {
    @Prop() private webTypeOptions!: any[];
    @Provide() private showDialog: boolean = false;
    @Provide() private website: { [index: string]: any } = {
        support: '',
        description: '',
        webType: null,
    };
    @Provide() private rules: { [index: string]: object[] } = {
        webType: [
            { type: 'array', required: true, message: '请选择安全策略/指令类型', trigger: 'change'},
        ],
        support: [
            { required: true, message: '请填写支持的跨域网址格式', trigger: 'blur'},
        ],
    };
    @Ref() private readonly websiteForm;

    private created() {
        this.$on('open', () => {
            this.showDialog = true;
        });
    }
    private updateWebType(value) {
        this.website.webType = value;
    }
    private submitAddWebsite() {
        this.websiteForm.validate((valid) => {
            if (!valid) {
                return false;
            }
            const url = '/api/v1/crossdomain-allowlist-management/create-website';
            const params = {
                security_policy: this.website.webType[0],
                command_type: this.website.webType[1],
                website_support: this.website.support,
                website_description: this.website.description,
            };
            axios.post(url, params).then(() => {
                this.$message({
                    type: 'success',
                    message: '添加跨域网址成功',
                });
                this.clearForm();
                this.showDialog = false;
                this.$emit('refreshWebsites');
            }).catch((error) => {
                this.$message.error(error);
            });
        });
    }
    private clearValidate() {
        this.websiteForm.clearValidate();
    }
    private clearForm() {
        this.website = {
            support: '',
            description: '',
            webType: [0, 0],
        };
    }
}
