
import Vue from 'vue';
import { Component, Provide } from 'vue-property-decorator';
import NavMenu from '@/components/NavMenu.vue';
import BreadCrumb from '@/components/BreadCrumb.vue';

@Component({
    components: {
        NavMenu, BreadCrumb,
    },
})
export default class App extends Vue {
    @Provide() private isAsideCollapse: boolean = false;
    @Provide() private isMobile: boolean = require('is-mobile')({
        tablet: true,
    });
}
