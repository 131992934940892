
import { Component, Ref } from 'vue-property-decorator';
import StudentsManagement from './StudentsManagement.vue';
import StudentsInCourse from './widgets/StudentsInCourse.vue';

@Component({
    components: {
        StudentsInCourse,
    },
})
export default class CourseStudentsManagement extends StudentsManagement {
    @Ref() private studentsInCourse!: any;

    public handleStudentCourseUpdated() {
        this.studentsInCourse.queryStudents();
    }
}
