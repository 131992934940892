
import { Provide, Component, Vue } from 'vue-property-decorator';
import axios from '../../../plugins/axios.js';
@Component({})
export default class ReferralRecords extends Vue {
    @Provide() private isManager: boolean = false;
    @Provide() private referrals: any[] = [];
    @Provide() private queriedCondition: { [index: string]: any } = {
        sort: 'descending',
        page: 1,
        followUserIds: [],
    };
    @Provide() private pageSize: number = 10;
    @Provide() private loading: boolean = true;
    @Provide() private totalReferrals: number = 0;
    @Provide() private followUsers: any[] = [];

    private mounted() {
        this.getReferrals();
    }

    private changePage(page: number) {
        this.queriedCondition.page = page;
        this.getReferrals();
    }

    private changeFilter(filter: any) {
        if (filter.hasOwnProperty('followUserIds')) {
            this.queriedCondition.followUserIds = filter.followUserIds;
        }
        this.queriedCondition.page = 1;
        this.getReferrals();
    }

    private getReferrals() {
        this.loading = true;
        axios.get('/api/v1/referrals', this.queriedCondition).then(({ isManager, referrals, total, followUsers }) => {
            this.loading = false;
            this.referrals = referrals;
            this.totalReferrals = total;
            this.followUsers = followUsers;
            this.isManager = isManager;
        }).catch((error) => {
            this.loading = false;
            this.$message.error(error);
        });
    }

    private toggleReferrerGiftGiven(referral) {
        axios.post('/api/v1/toggle-referrer-gift-given', {
            referrerPhone: referral.referrerPhone,
            isReferrerGiftGiven: referral.isReferrerGiftGiven,
        }).catch((error) => {
            referral.isReferrerGiftGiven = !referral.isReferrerGiftGiven;
            this.$message.error(error);
        });
    }
}
