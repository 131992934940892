
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Ref, Prop } from 'vue-property-decorator';
import ConfirmSwitchCourseModal from './ConfirmSwitchCourse.vue';

@Component({
    components: {
        ConfirmSwitchCourseModal,
    },
})
export default class StudentCourses extends Vue {
    @Provide() public isAdvisor: boolean = true;
    @Prop() private student!: any;
    @Provide() private showDialog: boolean = false;
    @Provide() private courses: any[] = [];
    @Provide() private selectedCourse: any = {};
    @Provide() private loading: boolean = false;
    @Ref() private confirmSwitchCourseModal;

    private created() {
        this.$on('open', () => {
            this.showDialog = true;
            this.$nextTick(() => {
                this.getCourses();
            });
        });
        if (!this.isAdvisor) {
            this.getCourses();
        }
    }

    private getCourses() {
        this.loading = true;
        axios.get('/api/v1/advisor/student-courses', {
            studentUuid: this.student.uuid,
        }).then(({ courses }) => {
            this.courses = courses;
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.loading = false;
        });
    }

    private handleCommand({ student, type }) {
        if (type === 'switch') {
            this.openConfirmSwitchModal(student);
        } else if (type === 'drop') {
            this.openDropConfirmModal(student);
        } else if (type === 'toSwitch') {
            this.openToSwitchConfirmModal(student);
        }
    }

    private openConfirmSwitchModal(selectedCourse) {
        this.selectedCourse = selectedCourse;
        this.confirmSwitchCourseModal.$emit('open');
    }

    private handleStudentCourseUpdated() {
        this.$emit('student-course-updated');
        this.getCourses();
    }

    private openDropConfirmModal({ courseId, title }) {
        this.$confirm(`确认对用户《${title}》班级执行退班操作吗？该操作无法恢复`, '确认退班', {
            confirmButtonText: '确认',
            confirmButtonClass: 'el-button--danger',
            cancelButtonText: '取消',
        }).then(() => {
            this.confirmDrop(courseId);
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消退班',
            });
        });
    }

    private confirmDrop(courseId) {
        axios.post('/api/v1/advisor/drop-student-course', {
            studentUuid: this.student.uuid,
            courseId,
        }).then(() => {
            this.$message({
                type: 'success',
                message: '用户退班成功',
            });
            this.handleStudentCourseUpdated();
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    private openToSwitchConfirmModal({ courseId, title }) {
        this.$confirm(`确认将用户从《${title}》班级转入待入班？`, '确认转班', {
            confirmButtonText: '确认',
            confirmButtonClass: 'el-button--danger',
            cancelButtonText: '取消',
        }).then(() => {
            this.confirmToSwitch(courseId);
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消转入待入班',
            });
        });
    }

    private confirmToSwitch(courseId) {
        axios.post('/api/v1/advisor/student-course-to-switch', {
            studentUuid: this.student.uuid,
            courseId,
        }).then(() => {
            this.$message({
                type: 'success',
                message: '用户转入待入班成功',
            });
            this.handleStudentCourseUpdated();
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    private jumpToStudentTranscript(course) {
        const userType = this.isAdvisor ? 'advisor' : 'sales';
        window.open(`https://www.jisuanke.com/admin/${userType}/students/${course.courseId}?studentUuid=${this.student.uuid}`);
    }
}
