
import Vue from 'vue';
import { Provide, Component } from 'vue-property-decorator';
import axios from '../../plugins/axios.js';
import EditSalesModal from './modals/EditSales.vue';
import '@/views/crm/SalesSchedulingFilter';

interface Sale {
    id: number;
    name?: string;
    account?: string;
    level?: number;
    inSchedulingWorkingDuration?: number;
    monthInSchedulingRecordCount?: number;
    todayInSchedulingRecordCount?: number;
    yesterdayInSchedulingRecordCount?: number;
    notinSchedulingWorkingDuration?: number;
    monthNotinSchedulingRecordCount?: number;
    todayNotinSchedulingRecordCount?: number;
    yesterdayNotinSchedulingRecordCount?: number;
    monthReferralCount?: number;
}

@Component({
    components: {
        EditSalesModal,
    },
})

export default class SalesSchedulingStatistics extends Vue {
    // 销售员工数据
    @Provide() private sales: Sale[] = [];
    // 排序条件
    @Provide() private queriedCondition: { [index: string]: any } = {
        sort: { prop: 'id', order: 'ascending' },
    };
    // 是否显示加载遮罩层
    @Provide() private loading: boolean = true;

    private mounted() {
        this.getSales();
    }

    /**
     * 根据查询条件、排序方式、页码获取销售员工数据
     */
    private getSales() {
        this.loading = true;
        const url = '/api/v1/crm/employee-statistics';
        axios.get(url).then(({ crm_employees }) => {
            this.updateSales(crm_employees);
        }).catch((error) => {
            this.loading = true;
            this.$message.error(error);
        });
    }

    /**
     * 更新销售员工表格的信息
     * @param courses {any} 后端传入的课程信息
     */
    private updateSales(salemen: any) {
        this.sales = [];
        for (const saleman of salemen) {
            const sale: Sale = {
                id: parseInt(saleman.employee_id, 0),
                name: saleman.name,
                account: saleman.account,
                level: saleman.reception_level,
                inSchedulingWorkingDuration: saleman.in_scheduling_working_duration,
                notinSchedulingWorkingDuration: saleman.notin_scheduling_working_duration,
                monthReferralCount: saleman.month_referral_count,
                monthInSchedulingRecordCount: saleman.month_in_scheduling_record_count,
                todayInSchedulingRecordCount: saleman.today_in_scheduling_record_count,
                yesterdayInSchedulingRecordCount: saleman.yesterday_in_scheduling_record_count,
                monthNotinSchedulingRecordCount: saleman.month_notin_scheduling_record_count,
                todayNotinSchedulingRecordCount: saleman.today_notin_scheduling_record_count,
                yesterdayNotinSchedulingRecordCount: saleman.yesterday_notin_scheduling_record_count,
            };
            this.sales.push(sale);
        }
        this.loading = false;
    }
}
