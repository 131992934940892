
import Vue from 'vue';
import axios from '../../plugins/axios.js';
import { Provide, Component, Ref } from 'vue-property-decorator';
import moment from 'moment';

@Component({})
export default class LaunchCluesStatistic extends Vue {
    // 投放线索相关信息
    @Provide() private launchCluesInfo: { [index: string]: any } = {
        launchDate: '',
    };
    // 首单消费金额最小值
    @Provide() private amount: number = 0;
    // 合计总消费最小值
    @Provide() private totalAmount: number = 0;
    @Provide() private pollingKey: any = null;
    @Provide() private downloading: boolean = false;
    // 文本解码器
    @Provide() private utf8Decoder: any = new TextDecoder('utf-8');
    @Provide() private rules: { [index: string]: object[] } = {
        launchDate: [
            { required: true, message: '请选择日期和时间范围', trigger: 'blur' },
        ],
    };
    @Ref() private readonly launchCluesForm;
    private exportData() {
        this.launchCluesForm.validate((valid) => {
            if (!valid) {
                return false;
            }
            if (moment(this.launchCluesInfo.launchDate[1]).diff(moment(this.launchCluesInfo.launchDate[0]), 'years')) {
                return this.$message.error('时间范围不能超过 1 年');
            }
            this.downloading = true;
            const url = '/api/v1/launch-clues-statistic/export';
            const params = {
                start: moment(this.launchCluesInfo.launchDate[0]).format('YYYY-MM-DD HH:mm:ss'),
                end: moment(this.launchCluesInfo.launchDate[1]).format('YYYY-MM-DD HH:mm:ss'),
                amount: this.amount,
                total_amount: this.totalAmount,
            };
            axios.post(url, params).then(({ result_key }) => {
                this.pollingKey = result_key;
                this.getStatisticData();
            }).catch((error) => {
                this.downloading = false;
                this.$message.error(error);
            });
        });
    }

    private getStatisticData() {
        if (!this.downloading) {
            return;
        }
        const params = {
            result_key: this.pollingKey,
        };
        const url = '/api/v1/launch-clues-statistic/download';
        axios.download(url, params).then((response: any) => {
            try {
                // 如果JSON.parse(enc.decode(new Uint8Array(data)))不报错，说明后台返回的是json对象，则弹框提示;
                // 如果报错，说明返回的是文件流，进入catch，下载文件
                const resp = JSON.parse(this.utf8Decoder.decode(new Uint8Array(response)));
                if (resp.status === 'success') {
                    if (resp.data.status === 'finish') {
                        this.downloading = false;
                        this.$message.error(resp.data.message);
                    } else {
                        this.getStatisticData();
                    }
                } else {
                    this.$message.error(resp.data.message);
                }
            } catch (err) {
                this.downloading = false;
                const blob = new Blob([response], {
                    type: 'application/octet-stream',
                });
                const filename = '投放成单统计.xlsx';
                if (typeof (window.navigator as any).msSaveBlob !== 'undefined') {
                    (window.navigator as any).msSaveBlob(blob, filename);
                } else {
                    const blobURL = window.URL.createObjectURL(blob);
                    const tempLink = document.createElement('a');
                    tempLink.href = blobURL;
                    tempLink.download = filename;
                    document.body.appendChild(tempLink);
                    tempLink.click();
                    document.body.removeChild(tempLink);
                    window.URL.revokeObjectURL(blobURL);
                }
            }
        }).catch((error) => {
            this.downloading = false;
            this.$message.error(error);
        });
    }
    private beforeDestroy() {
        this.downloading = false;
    }
}
