
import Vue from 'vue';
import _ from 'lodash';
import { ProvideReactive, Provide, Component, Watch, Ref } from 'vue-property-decorator';
import DepartmentsTree from './widgets/DepartmentsTree.vue';
import DepartmentMembers from './widgets/DepartmentMembers.vue';
import DepartmentRoles from './widgets/DepartmentRoles.vue';

@Component({
    components: {
        DepartmentsTree,
        DepartmentMembers,
        DepartmentRoles,
    },
})
export default class AuthorityManage extends Vue {
    @ProvideReactive() private isAdmin: boolean = false;
    @ProvideReactive() private departmentPermissionsTree: any = [];
    @ProvideReactive() private departmentRoles: any = [];
    @ProvideReactive() private departmentMembers: any = [];

    @Provide() private selectedDepartment: { [index: string]: any } = {
        id: -1,
        title: '',
        permissions: [],
    };
    @Provide() private departmentPermissions: any = [];
    @Provide() private activeTab = 'member';
    @Provide() private addMemberOrRole: any = '添加成员';

    @Ref() private readonly updateDepartmentModal;
    @Ref() private readonly department;
    @Ref() private readonly departmentMembersTable;
    @Ref() private readonly departmentRolesTable;

    private get isTopDepartment() {
        return this.selectedDepartment.parent_id === 0;
    }

    private get hasSelectedDepartment() {
        return !!this.selectedDepartment.title;
    }

    @Watch('activeTab', {deep: true})
    private refreshOpenModelContent() {
        this.addMemberOrRole = this.activeTab === 'member' ? '添加成员' : '添加角色';
    }

    @Watch('selectedDepartment', {deep: true})
    private refreshActiveTab() {
        if (this.isTopDepartment) {
            this.activeTab = 'member';
        }
    }

    private updateSelectedDepartment({ department, departmentPermissionsTree }) {
        this.selectedDepartment = department;
        this.departmentPermissionsTree = departmentPermissionsTree;
        this.$nextTick(() => {
            this.getMemberAndRoles();
        });
    }

    private openAddMemberOrRoleModal() {
        if (this.activeTab === 'member') {
            this.departmentMembersTable.openAddMemberModal();
            return;
        }
        this.departmentRolesTable.openAddRoleModal();
    }

    private getMemberAndRoles() {
        if (!this.hasSelectedDepartment) {
            return;
        }
        this.departmentMembersTable.getDepartmentMembers();
        this.departmentRolesTable.getDepartmentRoles();
    }

    private updateDepartmentRoles(roles) {
        this.departmentRoles = roles;
    }

    private updateDepartmentMembers(members) {
        this.departmentMembers = members;
    }

    private refreshRoles() {
        this.departmentRolesTable.getDepartmentRoles();
    }
}
