
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Prop, InjectReactive, Component, Ref, Provide } from 'vue-property-decorator';
import UpdateRoleModal from '../modals/UpdateRole.vue';

@Component({
    components: {
        UpdateRoleModal,
    },
})
export default class DepartmentRoles extends Vue {
    @InjectReactive() public isAdmin;
    @Prop() private departmentId;

    @Provide() private isLoading: boolean = false;
    @Provide() private roles: any[] = [];
    @Provide() private selectedDepartment;

    @Ref() private readonly updateRoleModal;

    private getDepartmentRoles() {
        this.isLoading = true;
        axios.get('/api/v1/authority-manage/roles', {
            departmentId : this.departmentId,
        }).then((roles) => {
            this.roles = roles;
            this.$emit('roles-loaded', roles);
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.isLoading = false;
        });
    }

    private openAddRoleModal() {
        this.openUpdateRoleModal({
            id: 0,
            name: '',
            specification: '',
            isManager: false,
        });
    }

    private openUpdateRoleModal(role) {
        this.updateRoleModal.$emit('open', role);
    }

    private deleteRole(role) {
        if (role.total !== 0) {
            return this.$message.error('该角色中仍有成员存在，无法移除该角色');
        }
        this.$confirm('角色移除后将无法找回，是否继续？', '移除角色', {
            confirmButtonText: '确定',
            confirmButtonClass: 'el-button--danger',
            cancelButtonText: '取消',
        }).then(() => {
            this.requestDeleteRole(role);
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消移除',
            });
        });
    }

    private requestDeleteRole(role) {
        axios.post('/api/v1/authority-manage/delete-role', {
            departmentId : this.departmentId,
            roleId: role.id,
        }).then(() => {
            this.$message({
                type: 'success',
                message: '移除角色成功',
            });
            this.getDepartmentRoles();
        });
    }
}
