import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Raven from 'raven-js';
import RavenVue from 'raven-js/plugins/vue';
import VueClipboard from 'vue-clipboard2';
import { mockServer } from './mock';
import Element from 'element-ui';
import './element-variables.scss';

Vue.use(Element);
Vue.use(VueClipboard);
Vue.config.productionTip = false;

if (process.env.NODE_ENV === 'production') {
    Raven
        .config('https://ac2b7eeabf1a472c9696208b520140bc@sentry.jisuan.ren/7')
        .addPlugin(RavenVue, Vue)
        .install();
}

if (process.env.NODE_ENV === 'development') {
  mockServer();
}

Vue.config.errorHandler = (err, vm, info) => {
  if (process.env.NODE_ENV === 'production') {
    Raven.captureException(err);
  }
};

router.beforeEach((to, from, next) => {
  Vue.prototype.pageMissing = false;
  if (to.name === '404') {
    Vue.prototype.pageMissing = true;
  }
  next(); // 如果匹配到正确跳转
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
