import axios from './axios.js';

export default {
    assignTitle(to, from, next) {
        if (to.meta.title) {
            document.title = to.meta.title;
        }
        if (to.path !== '/404') {
            axios.get(`/api/v1${to.path}`)
        }
        next();
    },
    checkInvoicePermission(to, from, next) {
        if (to.meta.title) {
            document.title = to.meta.title;
        }
        axios.get('/api/v1/invoice-manage').then((response) => {
            for (let key in response) {
                to.query[key] = response[key];
            }
            next();
        }).catch(() => {
            next({ path: '/404', replace: true });
        })
    }
}
