
import axios from '../../plugins/axios.js';
import { Provide, Component, Mixins, Ref } from 'vue-property-decorator';
import CourseClassifyMixins from '@/views/shared/CourseClassifyMixins.vue';
import PartnerAssignCourseOperations from './widgets/AssignCourseOperations.vue';
import PartnerDropCourseOperations from './widgets/DropCourseOperations.vue';

@Component({
    components: {
        PartnerAssignCourseOperations,
        PartnerDropCourseOperations,
    },
})
export default class PartnerOperationApproval extends Mixins(CourseClassifyMixins) {
    @Provide() private operationsType: any = 'assignCourse';
    @Provide() private queriedCondition: { [index: string]: any } = {
        partnerCode: null,
        courseId: null,
        classifies: [],
    };

    @Ref() private readonly assignCourseOperationsTable;
    @Ref() private readonly dropCourseOperationsTable;

    private mounted() {
        this.getCourseClassifies();
    }

    private getCourseClassifies() {
        axios.get('/api/v1/partner/course-classifies').then((classifies) => {
            this.courseClassify = classifies;
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    private queryApplications() {
        this.assignCourseOperationsTable.queryApplications();
        this.dropCourseOperationsTable.queryApplications();
    }
}
