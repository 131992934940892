
import Vue from 'vue';
import axios from '../../../../plugins/axios.js';
import { Provide, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';
@Component({})
export default class RemarkDetail extends Vue {
    @Prop() private lessonTypes: any;
    @Provide() private showDialog: boolean = false;
    @Provide() private preparation: any = {};
    @Provide() private isLoading: boolean = false;

    private created() {
        this.$on('open', (preparationId) => {
            this.showDialog = true;
            this.requestPreparation(preparationId);
        });
    }

    private requestPreparation(preparationId) {
        this.isLoading = true;
        axios.get('/api/v1/lecture-preparation', {
            preparationId,
        }).then((preparation) => {
            this.preparation = preparation;
            this.isLoading = false;
        }).catch((error) => {
            this.isLoading = false;
            this.$message.error(error);
        });
    }

    private get formattedInstructingAt() {
        const startAt = moment.unix(this.preparation.liveStartAt).format('YYYY-MM-DD HH:mm:ss');
        const endAt = moment.unix(this.preparation.liveEndAt).format('YYYY-MM-DD HH:mm:ss');
        const instructingAt = `${startAt} ~ ${endAt}`;
        const startDiffMinutes = parseInt(this.preparation.startDiffMinutes, 0);
        const endDiffMinutes = parseInt(this.preparation.endDiffMinutes, 0);
        let startDiff = '';
        let endDiff = '';
        if (startDiffMinutes < 0) {
            startDiff = `比排课时间晚${startDiffMinutes * -1}分钟开始，`;
        } else if (startDiffMinutes > 0) {
            startDiff = `比排课时间早${startDiffMinutes}分钟开始，`;
        }
        if (endDiffMinutes < 0) {
            endDiff += `晚${endDiffMinutes * -1}分钟结束。`;
        } else if (endDiffMinutes > 0) {
            endDiff += `早${endDiffMinutes}分钟结束。`;
        }
        const endStatus = endDiffMinutes > 5 ? '存在严重拖堂行为' : '无严重拖堂行为';
        return `${instructingAt} ${startDiff}${endDiff}${endStatus}`;
    }

    private get formattedTeachingSummary() {
        const teachingSummaries = [
            '优于备课预期',
            '与备课预期一致',
            '不及备课预期',
        ];
        return teachingSummaries[this.preparation.summary.teaching];
    }

    private formattedChapterLessonTitle({ lessonType, title }) {
        return `[${this.lessonTypes[lessonType]}]${title}`;
    }

    private hasBreak(chapterLessonId) {
      return this.preparation.breakAfterLessons && this.preparation.breakAfterLessons.some((lesson) => {
        return lesson.chapterLessonId === chapterLessonId;
      });
    }
}
