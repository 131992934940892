
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Inject } from 'vue-property-decorator';

interface LabelInfo {
    labelId: number;
    labelName: string;
}
interface LabelGroup {
    labelGroupId: number;
    labelGroupName: string;
    labelGroupType: number;
    labels: LabelInfo[];
}

@Component
export default class AddLabels extends Vue {
  // 选中的标签
  @Inject() private labels;
  // 是否显示 modal
  @Provide() private showDialog: boolean = false;
  // 数据是否加载完毕
  @Provide() private isLoading: boolean = true;
  // 所有标签用于展示
  @Provide() private labelList: LabelGroup[] = [];
  // label 的 labelId 和 labelName 映射的表，[{ labelId: labelName }]
  @Provide() private idNameMap = {};

  /**
   * 点击确定添加标签
   */
  private addLabels() {
    this.$emit('updateLabels', this.idNameMap);
    this.showDialog = false;
  }

  /**
   * radio 的点击事件
   * @param labelGroupId 选中的标签的类别 id
   * @param labelId 选中的标签的 id
   */
  private toogleSelect(labelGroupId, labelId) {
    if (this.labels[labelGroupId] === labelId) {
      this.labels[labelGroupId] = '';
    } else {
      this.labels[labelGroupId] = labelId;
    }
  }

  /**
   * 获取标签列表
   */
  private getLabelList() {
      this.isLoading = true;
      const url = '/api/v1/crm/get-label-list';
      axios.post(url).then(({ list }) => {
          this.updateLabelList(list);
        }).catch((error) => {
            this.$message.error(error);
        });
  }

  /**
   * 更新标签列表，从返回数据中格式化 labelList 列表用于展示
   * @param labelList 请求的返回数据
   */
  private updateLabelList(labelList: any) {
      for (const labelGroup of labelList) {
          const labelInfos: LabelInfo[] = [];
          for (const label of labelGroup.labelList) {
              const labelInfo: LabelInfo = {
                  labelId: label.labelId,
                  labelName: label.labelName,
              };
              labelInfos.push(labelInfo);
          }
          const labelGroupInfo: LabelGroup = {
              labelGroupId: labelGroup.labelGroupId,
              labelGroupName: labelGroup.labelGroupName,
              labels: labelInfos,
              labelGroupType: labelGroup.labelGroupType,
          };
          this.labelList.push(labelGroupInfo);
      }
      this.updateLabels();
      this.updateIdNameMap();
      this.isLoading = false;
  }

  /**
   * 初始化 labels
   */
  private updateLabels() {
    for (const labelGroup of this.labelList) {
      if (labelGroup.labelGroupType === 0) {
        this.$set(this.labels, labelGroup.labelGroupId, []);
      } else {
        this.$set(this.labels, labelGroup.labelGroupId, '');
      }
    }
  }

  /**
   * 初始化 idNameMap
   */
  private updateIdNameMap() {
    for (const labelGroup of this.labelList) {
      for (const label of labelGroup.labels) {
        this.idNameMap[label.labelId.toString()] = label.labelName;
      }
    }
  }

  private created() {
    this.$on('open', () => {
        this.showDialog = true;
    });
    this.getLabelList();
  }
}
