
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Provide, Component, InjectReactive, Ref } from 'vue-property-decorator';
import SendMessageToParentModal from '../modals/SendMessageToParent.vue';
import StudentCoursesModal from '../modals/StudentCourses.vue';
import StudentVouchersModal from '../modals/StudentVouchers.vue';
import StudentEcCommunicationsModal from '../modals/StudentEcCommunications.vue';

@Component({
    components: {
        SendMessageToParentModal,
        StudentCoursesModal,
        StudentVouchersModal,
        StudentEcCommunicationsModal,
    },
})
export default class StudentsWithFinishedLiveCourses extends Vue {
    @InjectReactive() public isAdvisorManager;
    @InjectReactive() public advisorsOptions;
    @InjectReactive() public stagesAndGradesOptions;
    @InjectReactive() public provincesOptions;
    @InjectReactive() public liveSubClassifiesOptions;
    @InjectReactive() public defaultLiveCoursesSubClassifies;
    @InjectReactive() public defaultAiCoursesSubClassifies;
    @InjectReactive() public aiSubClassifiesOptions;
    @InjectReactive() public continuousStatusesOptions;
    @InjectReactive() public learningStatusesOptions;
    @InjectReactive() public instructorsOptions;
    @InjectReactive() public studentName;
    @InjectReactive() public studentAccount;
    @InjectReactive() public schoolName;

    @Provide() public type: string = 'liveFinished';
    @Provide() public students: any[] = [];
    @Provide() public selectedStudent: any = {};
    @Provide() public pageSize: number = 10;
    @Provide() public totalStudents: number = 0;
    @Provide() public loading: boolean = true;
    @Provide() public queriedCondition: { [index: string]: any } = {
        page: 1,
        sortProp: 'default',
        sortOrder: 'ascending',
        stageAndGrades: [],
        provinces: [],
        subClassifies: [],
        continuousStatuses: [],
        boundWithParentService: [],
        hasJoinedWxGroup: [],
        learningStatuses: [],
        countOfDeliveredLectures: [],
        instructors: [],
    };

    @Ref() public readonly studentsTable;
    @Ref() public readonly sendMessageToParentModal;
    @Ref() public readonly studentCoursesModal;
    @Ref() public readonly studentVouchersModal;
    @Ref() public readonly studentEcCommunicationsModal;

    public get lectureOptions() {
        const countOfLectures = 12;
        const lectureOptions: any = [];
        for (let i = 0; i <= countOfLectures; i++) {
            lectureOptions.push({
                text: i.toString(),
                value: i.toString(),
            });
        }
        return lectureOptions;
    }

    public get areAiCourseStudentsVisible() {
        return this.type === 'ai';
    }

    public get classifiesOptions() {
        return this.areAiCourseStudentsVisible ? this.aiSubClassifiesOptions : this.liveSubClassifiesOptions;
    }

    public get defaultFilteredClassifies() {
        return this.areAiCourseStudentsVisible ? this.defaultAiCoursesSubClassifies : this.defaultLiveCoursesSubClassifies;
    }

    public mounted() {
        this.queriedCondition.subClassifies = this.defaultFilteredClassifies;
        this.getStudents();
    }

    public changePage(page: number) {
        this.queriedCondition.page = page;
        this.getStudents();
    }

    public changeSort({ prop, order }) {
        this.queriedCondition.sortProp = prop ? prop : 'default';
        this.queriedCondition.sortOrder = order ? order : 'ascending';
        this.queriedCondition.page = 1;
        this.getStudents();
    }

    public changeFilter(filter) {
        if (filter.hasOwnProperty('advisors')) {
            this.queriedCondition.advisors = filter.advisors;
        }
        if (filter.hasOwnProperty('stageAndGrades')) {
            this.queriedCondition.stageAndGrades = filter.stageAndGrades;
        }
        if (filter.hasOwnProperty('provinces')) {
            this.queriedCondition.provinces = filter.provinces;
        }
        if (filter.hasOwnProperty('subClassifies')) {
            this.queriedCondition.subClassifies = filter.subClassifies;
        }
        if (filter.hasOwnProperty('continuousStatuses')) {
            this.queriedCondition.continuousStatuses = filter.continuousStatuses;
        }
        if (filter.hasOwnProperty('learningStatuses')) {
            this.queriedCondition.learningStatuses = filter.learningStatuses;
        }
        if (filter.hasOwnProperty('boundWithParentService')) {
            this.queriedCondition.boundWithParentService = filter.boundWithParentService;
        }
        if (filter.hasOwnProperty('hasJoinedWxGroup')) {
            this.queriedCondition.hasJoinedWxGroup = filter.hasJoinedWxGroup;
        }
        if (filter.hasOwnProperty('countOfDeliveredLectures')) {
            this.queriedCondition.countOfDeliveredLectures = filter.countOfDeliveredLectures;
        }
        if (filter.hasOwnProperty('instructors')) {
            this.queriedCondition.instructors = filter.instructors;
        }
        this.queriedCondition.page = 1;
        this.getStudents();
    }

    public queryStudents() {
        this.queriedCondition.page = 1;
        this.getStudents();
    }

    public handleStudentCourseUpdated() {
        this.$emit('student-course-updated');
    }

    public getStudents() {
        this.loading = true;
        axios.get('/api/v1/advisor/students', {
            ...this.queriedCondition,
            type: this.type,
            studentName: this.studentName,
            studentAccount: this.studentAccount,
            schoolName: this.schoolName,
        }).then(({ students, total }) => {
            this.students = students;
            this.totalStudents = total;
            this.$emit('update:countOfStudents', total);
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.loading = false;
        });
    }

    public formattedStudentClassName({ row }) {
        const dangerSortValue = 1;
        const warningSortValue = 100;
        const successSortValue = 1000000;
        if (this.type === 'liveFinished' || this.type === 'paused') {
            if (row.sortValue === dangerSortValue) {
                return 'row-danger';
            } else if (row.sortValue === warningSortValue) {
                return 'row-warning';
            } else if (row.sortValue === successSortValue) {
                return 'row-success';
            }
        }
        return '';
    }

    public formattedStudentNameWithUuid(student: any) {
        return `${student.name}(${student.uuid})`;
    }

    public copyStudentUuidSuccessfully() {
        this.$message.success('学生 UUID 已复制');
    }

    public formattedCourseTitle({ courseTitle, courseId }) {
        return `${courseTitle}(${courseId})`;
    }

    public formattedCourseSubClassify(student) {
        const subClassify = this.classifiesOptions.find((option) => parseInt(option.value, 0) === parseInt(student.subClassify, 0))?.text;
        if (student.isExpired) {
            return `${subClassify}(已过期)`;
        }
        return subClassify;
    }

    public copyCourseIdSuccessfully() {
        this.$message.success('班级 ID 已复制');
    }

    public jumpToStudentTranscript(student: any) {
        if (student.isExpired) {
            return;
        }
        window.open(`https://www.jisuanke.com/admin/advisor/students/${student.courseId}?studentUuid=${student.uuid}`);
    }

    public jumpToStudentAttemptedContests(student: any) {
        if (student.isExpired) {
            return;
        }
        window.open(`https://www.jisuanke.com/admin/advisor/students/${student.courseId}?studentUuid=${student.uuid}&tab=contests`);
    }

    public jumpToStudentAttemptedProblems(student: any) {
        if (student.isExpired) {
            return;
        }
        window.open(`https://www.jisuanke.com/admin/advisor/students/${student.courseId}?studentUuid=${student.uuid}&tab=problems`);
    }

    public openSendMessageToParentModal(student) {
        this.selectedStudent = student;
        this.sendMessageToParentModal.$emit('open');
    }

    public handleCommand({ student, type }) {
        if (type === 'courses') {
            this.openStudentCoursesModal(student);
        } else if (type === 'vouchers') {
            this.openStudentVouchersModal(student);
        } else if (type === 'message') {
            this.openSendMessageToParentModal(student);
        } else if (type === 'ecCommunications') {
            this.openStudentEcCommunicationsModal(student);
        }
    }

    public openStudentCoursesModal(student) {
        this.selectedStudent = student;
        this.studentCoursesModal.$emit('open');
    }

    public openStudentVouchersModal(student) {
        this.selectedStudent = student;
        this.studentVouchersModal.$emit('open');
    }

    public openStudentEcCommunicationsModal(student) {
        this.selectedStudent = student;
        this.studentEcCommunicationsModal.$emit('open');
    }
}
