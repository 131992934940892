
import Vue from 'vue';
import axios from '@/plugins/axios.js';
import { Provide, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';

@Component({})
export default class PartnerTransaction extends Vue {
    @Prop() private partnerCode: any;
    @Provide() private transactions: any = [];
    @Provide() private queriedCondition: { [index: string]: any } = {
        partnerId: null,
        types: [],
        dateTimeRange: [
            moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
            moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        ],
        minimumAmount: null,
        maximumAmount: null,
        remark: '',
        page: 1,
    };
    @Provide() private pageSize: number = 20;
    @Provide() private loading: boolean = true;
    @Provide() private total: number = 0;
    @Provide() private transactionTypes: any[] = [
        {
            text: '扣费',
            value: '0',
        },
        {
            text: '充值',
            value: '1',
        },
    ];
    @Provide() private pickerOptions = {
        shortcuts: [
            {
                text: '本月',
                onClick(picker) {
                    const start = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
                    const end = moment().endOf('month').format('YYYY-MM-DD HH:mm:ss');
                    picker.$emit('pick', [start, end]);
                },
            },
            {
                text: '前一月',
                onClick(picker) {
                    const start = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss');
                    const end = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss');
                    picker.$emit('pick', [start, end]);
                },
            },
        ],
    };
    private mounted() {
        this.getTransactions();
    }

    private changePage(page: number) {
        this.queriedCondition.page = page;
        this.getTransactions();
    }

    private changeFilter(filter: any) {
        if (filter.hasOwnProperty('types')) {
            this.queriedCondition.types = filter.types;
        }
        this.queryTransactions();
    }

    private queryTransactions() {
        this.queriedCondition.page = 1;
        this.getTransactions();
    }
    private getTransactions() {
        if (!this.queriedCondition.dateTimeRange) {
            return this.$message.error('请选择筛选时间');
        }
        if (moment(this.queriedCondition.dateTimeRange[1]).diff(moment(this.queriedCondition.dateTimeRange[0]), 'days') > 100) {
            return this.$message.error('汇总时间最大可选时间跨度为 100 天');
        }
        this.loading = true;
        axios.get('/api/v1/partner-manage/transactions', {
            ...this.queriedCondition,
            partnerCode: this.partnerCode,
            startTime: this.queriedCondition.dateTimeRange[0],
            endTime: this.queriedCondition.dateTimeRange[1],
        }).then(({ transactions, total }) => {
            this.transactions = transactions;
            this.total = total;
            this.loading = false;
        }).catch((error) => {
            this.$message.error(error);
        });
    }
}
