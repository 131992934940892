
import Vue from 'vue';
import { Provide, Component, Prop, Ref } from 'vue-property-decorator';
import axios from '../../plugins/axios.js';

interface PayInfo {
    pay_time: string;
    swift_id: string;
    amount: string;
    channel: string;
}

@Component
export default class CreateInvoice extends Vue {
    @Prop() private swiftId!: number;
    @Provide() private loading: boolean = false;
    @Provide() private payments: PayInfo[] = [];
    @Provide() private invoiceInfo: { [index: string]: any } = {};
    @Provide() private amountSum: string = '';
    @Provide() private rules: { [index: string]: object[] } = {
        invoice_code: [
            { required: true, message: '请填写发票号码', trigger: 'blur' },
        ],
    };
    @Ref() private readonly invoiceForm;

    private mounted() {
        this.getInvoiceDetail();
    }

    private getInvoiceDetail() {
        this.loading = true;
        this.payments = [];
        this.invoiceInfo = {};
        axios.get('/api/v1/invoice-manage/invoice-detail', {
            swift_id: this.swiftId,
        }).then((response: any) => {
            this.payments = response.pay_info_list;
            this.invoiceInfo = response.invoice;
            this.amountSum = response.amount;
            this.loading = false;
        }).catch((error) => {
            this.loading = false;
            this.$message.error(error);
        });
    }

    private getSummaries(param) {
        const { columns } = param;
        return columns.map((column, index) => {
            if (index === 0) {
                return '合计';
            }
            if (column.property === 'amount') {
                return this.amountSum;
            }
            return '';
        });
    }

    private submit() {
        this.invoiceForm.validate((valid) => {
            if (!valid) {
                return false;
            }
            this.$confirm('确认已完成该订单所有付款记录发票的开具？', '完成开票', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                axios.post('/api/v1/invoice-manage/create-invoice', {
                    swift_id: this.swiftId,
                    invoice_code: this.invoiceInfo.invoice_code,
                }).then(() => {
                    this.$message({ message: '发票开具已完成', type: 'success' });
                    setTimeout(() => {
                        this.$router.push('/invoice-manage');
                    }, 2000);
                });
            }).catch();
        });
    }

    private deleteInvoice() {
        this.$confirm('确认拒绝该订单所有付款记录发票申请？', '拒绝发票申请', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
        }).then(() => {
            axios.post('/api/v1/invoice-manage/delete-invoice', {
                swift_id: this.swiftId,
            }).then(() => {
                this.$message({ message: '拒绝发票申请成功', type: 'success' });
                this.$router.push('/invoice-manage');
            }).catch((error) => {
                this.$message.error(error);
            });
        }).catch();
    }
}
