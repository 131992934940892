import { render, staticRenderFns } from "./UpdateCouponTemplate.vue?vue&type=template&id=85c02488&scoped=true"
import script from "./UpdateCouponTemplate.vue?vue&type=script&lang=ts"
export * from "./UpdateCouponTemplate.vue?vue&type=script&lang=ts"
import style0 from "./UpdateCouponTemplate.vue?vue&type=style&index=0&id=85c02488&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85c02488",
  null
  
)

export default component.exports