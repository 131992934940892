
import Vue from 'vue';
import axios from '../../plugins/axios.js';
import { Provide, Component } from 'vue-property-decorator';

@Component({})

export default class MultimediaUpload extends Vue {
    @Provide() private uploadedFilePath: string = '';

    private uploadMultimedia($file) {
        this.uploadedFilePath = '';
        if ($file.type !== 'image/jpeg' &&
            $file.type !== 'image/png' &&
            $file.type !== 'audio/mp3' &&
            $file.type !== 'video/mp4' &&
            $file.type !== 'image/jpg' &&
            $file.type !== 'image/jpeg' &&
            $file.type !== 'image/png8' &&
            $file.type !== 'image/png24' &&
            $file.type !== 'image/x-png' &&
            $file.type !== 'image/pjpeg' &&
            $file.type !== 'image/png' &&
            $file.type !== 'image/gif' &&
            $file.type !== 'image/svg+xml' &&
            $file.type !== 'image/png') {
            this.$message.error('上传文件的格式尚未支持');
        } else {
            const file = new FormData();
            file.append('file', $file);
            axios.post('/api/v1/common/upload-multimedia', file).then(({ file_path }) => {
                this.uploadedFilePath = `https:${file_path}`;
            }).catch((error) => {
                this.$message.error(error);
            });
        }
        return false;
    }

    private onCopy() {
        this.$message({
            type: 'success',
            message: '文件地址复制成功',
        });
    }
}
