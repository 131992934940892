
import { Component, Vue, Provide } from 'vue-property-decorator';
import axios from '../plugins/axios.js';

@Component
export default class NavMenu extends Vue {
    @Provide() private permissions: any = null;

    private created() {
        this.getUserPermissions();
    }
    private getUserPermissions() {
        const url = '/api/v1/';
        axios.get(url).then(({ permissions }) => {
            this.permissions = permissions;
        }).catch((error) => {
            this.$message.error(error);
        });
    }
}
