import { render, staticRenderFns } from "./AddVoucher.vue?vue&type=template&id=28a455dc&scoped=true"
import script from "./AddVoucher.vue?vue&type=script&lang=ts"
export * from "./AddVoucher.vue?vue&type=script&lang=ts"
import style0 from "./AddVoucher.vue?vue&type=style&index=0&id=28a455dc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28a455dc",
  null
  
)

export default component.exports