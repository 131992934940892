
import axios from '../../plugins/axios.js';
import { Provide, Component, Ref, Mixins, ProvideReactive } from 'vue-property-decorator';
import SendMessageToCourseModal from './modals/SendMessageToCourse.vue';
import CourseClassifyMixins from '@/views/shared/CourseClassifyMixins.vue';

@Component({
    components: {
        SendMessageToCourseModal,
    },
})

export default class ClassManage extends Mixins(CourseClassifyMixins) {
    @ProvideReactive() private userName: string = '';
    @Provide() private queriedCondition: { [index: string]: any } = {
        courseId: null,
        title: null,
        primaryInstructor: null,
        sortProp: 'courseId',
        sortOrder: 'descending',
        classifies: [],
        advisors: [],
        instructors: [],
        endDate: [],
        page: 1,
    };
    @Provide() private isAdvisorManager: boolean = false;
    @Provide() private advisorsOptions: any[] = [];
    @Provide() private loading: boolean = true;
    @Provide() private areCoursesSummaryLoading: boolean = true;
    @Provide() private downloading: boolean = false;

    @Provide() private courses: any[] = [];
    @Provide() private coursesSummary: any = [];
    @Provide() private pageSize: number = 10;
    @Provide() private totalCourses: number = 0;
    @Provide() private selectedCourse: any = {};
    @Provide() private coursesInstructors: any = [];
    @Provide() private validInstructors: any = [];

    @Ref() private readonly sendMessageToCourseModal;

    private mounted() {
        this.requestFilterOptions();
        this.updateQueryParams();
        this.$nextTick(() => {
            this.requestCourses();
            this.requestCoursesAdministrativeSummary();
        });
    }

    private requestFilterOptions() {
        axios.get('/api/v1/advisor/filter-options').then((response) => {
            this.userName = response.userName;
            this.courseClassify = response.courseClassify;
            this.isAdvisorManager = response.isAdvisorManager;
            this.advisorsOptions = this.generateAdvisorsOptions(response.advisors);
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    private updateQueryParams() {
        if (this.$route.query.hasOwnProperty('courseId') && this.$route.query.courseId) {
            this.queriedCondition.courseId = this.$route.query.courseId;
        }
        if (this.$route.query.hasOwnProperty('title') && this.$route.query.title) {
            this.queriedCondition.title = this.$route.query.title;
        }
        if (this.$route.query.hasOwnProperty('classifies')) {
            const classifies = this.$route.query.classifies;
            if (classifies instanceof Array) {
                for (const classify of classifies) {
                    this.queriedCondition.classifies.push(classify?.split(','));
                }
            } else {
                this.queriedCondition.classifies.push(classifies.split(','));
            }
        }
    }

    private generateAdvisorsOptions(advisors) {
        const options: any = [];
        for (const advisor of advisors) {
            options.push({
                text: advisor.name,
                value: advisor.uid,
            });
        }
        return options;
    }

    private queryCourses() {
        const query = {};
        if (this.queriedCondition.courseId) {
            query['courseId'] = this.queriedCondition.courseId;
        }
        if (this.queriedCondition.title) {
            query['title'] = this.queriedCondition.title;
        }
        if (this.queriedCondition.classifies.length > 0) {
            query['classifies'] = this.queriedCondition.classifies;
        }
        this.$router.push({ path: '/class-manage', query });
        this.queriedCondition.page = 1;
        this.requestCourses();
        this.requestCoursesAdministrativeSummary();
    }

    private changeSort(sort: any) {
        this.queriedCondition.sortProp = sort.prop ? sort.prop : 'courseId';
        this.queriedCondition.sortOrder = sort.order ? sort.order : 'descending';
        this.queriedCondition.page = 1;
        this.requestCourses();
    }

    private changeFilter(filter: any) {
        if (filter.hasOwnProperty('advisors')) {
            this.queriedCondition.advisors = filter.advisors;
        }
        if (filter.hasOwnProperty('instructors')) {
            this.queriedCondition.instructors = filter.instructors;
        }
        this.queriedCondition.page = 1;
        this.requestCourses();
        this.requestCoursesAdministrativeSummary();
    }

    private changePage(page: number) {
        this.queriedCondition.page = page;
        this.requestCourses();
    }

    private requestCourses() {
        this.loading = true;
        axios.get('/api/v1/advisor/courses', this.queriedCondition).then(({
            courses,
            total,
            coursesInstructors,
            validInstructors,
        }) => {
            this.courses = courses;
            this.totalCourses = total;
            this.formatCoursesInstructorsFilterOptions(coursesInstructors);
            this.formatValidInstructorsFilterOptions(validInstructors);
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.loading = false;
        });
    }

    private requestCoursesAdministrativeSummary() {
        this.areCoursesSummaryLoading = true;
        axios.get('/api/v1/advisor/courses-administrative-summary', this.queriedCondition).then((response) => {
            this.coursesSummary = response.coursesSummary;
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.areCoursesSummaryLoading = false;
        });
    }

    private formatCoursesInstructorsFilterOptions(instructors) {
        const options: any[] = [];
        for (const key in instructors) {
            if (instructors.hasOwnProperty(key) && instructors[key].name) {
                options.push({
                    text: instructors[key].name,
                    value: instructors[key].id,
                });
            }
        }
        this.coursesInstructors = options;
    }

    private formatValidInstructorsFilterOptions(instructors) {
        const options: any[] = [];
        for (const key in instructors) {
            if (instructors.hasOwnProperty(key) && instructors[key].name) {
                options.push({
                    label: instructors[key].name,
                    value: instructors[key].id,
                });
            }
        }
        this.validInstructors = options;
    }

    private jumpToCourseStudents(course) {
        this.$router.push(`/class-student-manage?courseId=${course.courseId}`);
    }

    private jumpToAdvisorLive(course) {
        window.open(`https://www.jisuanke.com/admin/advisor/live/${course.courseId}`);
    }

    private jumpToAdvisorStudents(course) {
        window.open(`https://www.jisuanke.com/admin/advisor/students/${course.courseId}`);
    }

    private handleCommand({ course, type }) {
        if (type === 'message') {
            this.openSendMessageToCourseModal(course);
        }
    }

    private openSendMessageToCourseModal(course) {
        this.selectedCourse = course;
        this.sendMessageToCourseModal.$emit('open');
    }

    private exportClassData() {
        this.downloading = true;
        const url = '/api/v1/advisor/export-courses';
        axios.download(url, this.queriedCondition).then((response: any) => {
            const blob = new Blob([response], {
                type: 'application/octet-stream',
            });
            const filename = '班级信息.xlsx';
            const blobURL = window.URL.createObjectURL(blob);
            const tempLink = document.createElement('a');
            tempLink.href = blobURL;
            tempLink.download = filename;
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
            this.downloading = false;
        }).catch((error) => {
            this.downloading = false;
            this.$message.error(error);
        });
    }
}
