
import { Component, Provide, Vue, Watch, Ref } from 'vue-property-decorator';
import CourseLectureModal from './modals/CourseLecture.vue';
import axios from '../../plugins/axios.js';
import ExportCoursesLecturesModal from './modals/ExportCoursesLecturesModal.vue';

@Component({
    components: {
        CourseLectureModal,
        ExportCoursesLecturesModal,
    },
})
export default class CoursesLectures extends Vue {
    @Provide() private lecturesDate: any = new Date();
    @Provide() private lectures: any = {};
    @Provide() private selectedLecture: any = {};
    @Ref() private readonly courseLectureModal;
    @Ref() private readonly exportCoursesLecturesModal;

    private openCourseLectureModal(lecture: any) {
        this.selectedLecture = lecture;
        this.courseLectureModal.$emit('open');
    }

    private created() {
        this.getLectures();
    }

    @Watch('lecturesDate')
    private getLectures() {
        axios.get('/api/v1/courses-lectures', {
            lecturesDate: this.lecturesDate,
        }).then((lectures) => {
          this.lectures = {};
          let courseDate = '';
          for (const lecture of lectures) {
              if (lecture.course_date !== courseDate) {
                courseDate = lecture.course_date;
                this.lectures[courseDate] = [];
              }
              this.lectures[courseDate].push(lecture);
          }
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    private formattedDate(day) {
        return day.split('-').slice(1).join('-');
    }

    private countOfLecture(day) {
        if (this.lectures[day]) {
            return `(${this.lectures[day].length})`;
        }
        return '';
    }

    private openExportCoursesLecturesModal() {
        this.exportCoursesLecturesModal.$emit('open');
    }
}
