
import { Component, Provide, Vue, Ref } from 'vue-property-decorator';
import axios from '../../plugins/axios.js';

interface MessageInfo {
    sendDatetime: string;
    content: string;
    senderName: string;
    senderPhone: string;
    senderEmail: string;
    recipientName: string;
    recipientPhone: string;
    recipientEmail: string;
}

@Component
export default class ParentMessage extends Vue {
    @Provide() private messages: MessageInfo[] = [];
    // 查询、排序条件
    @Provide() private queriedCondition: { [index: string]: string } = {
        senderName: '',
        senderAccount: '',
        recipientName: '',
        recipientAccount: '',
        sort: 'descending',
        page: '1',
    };
    @Provide() private types: any[] = [];
    @Provide() private currentPage: number = 1;
    @Provide() private pageSize: number = 10;
    @Provide() private totalMessages: number = 0;
    @Provide() private loading: boolean = false;
    @Ref() private readonly messagesTable;
    private mounted() {
        this.getMessages();
    }
    private queryMessage() {
        this.queriedCondition.sort = 'descending';
        this.messagesTable.sort('id', 'descending');
        this.queriedCondition.page = '1';
        this.getMessages();
    }
    private changeSort(val: any) {
        if (val.order === null) {
            this.queriedCondition.sort = 'descending';
        } else {
            this.queriedCondition.sort = val.order;
        }
        this.queriedCondition.page = '1';
        this.getMessages();
    }
    private changePage(page: number) {
        this.queriedCondition.page = page.toString();
        this.getMessages();
    }
    private getMessages() {
        this.loading = true;
        const url = '/api/v1/parent-notification/';
        const params = this.queriedCondition;
        axios.get(url, params).then((response: any) => {
            this.messages = response.messages;
            this.totalMessages = response.total;
            this.types = response.types;
            this.currentPage = parseInt(this.queriedCondition.page, 0);
            this.loading = false;
        }).catch((error) => {
            this.$message.error(error);
        });
    }
}
