
import { Vue, InjectReactive, Prop, Provide, Component, Ref } from 'vue-property-decorator';
import axios from '../../../plugins/axios.js';
import AddLiveCourseModal from '../modals/AddLiveCourse.vue';
import EditLiveCourseModal from '../modals/EditLiveCourse.vue';

@Component({
    components: {
        AddLiveCourseModal,
        EditLiveCourseModal,
    },
})
export default class LiveCourses extends Vue {
    @Provide() public coursesType: any = 'live';

    @InjectReactive() private liveSubClassifiesOptions;
    @InjectReactive() private nonLiveClassifiesOptions;

    @Prop() private courseClassifyOptions;
    @Provide() private courses: any[] = [];
    @Provide() private queriedCondition: { [index: string]: any } = {
        type: 'live',
        courseId: '',
        title: '',
        lectureStatus: [],
        lectureSchedule: '',
        classifies: [],
        page: 1,
    };
    @Provide() private pageSize: number = 10;
    @Provide() private loading: boolean = true;
    @Provide() private totalCourses: number = 0;
    @Provide() private selectedCourse: any = {};
    @Provide() private templateCourses: any = [];

    @Provide() private lectureStatusFilters: any = [
        {
            text: '完成排课',
            value: 'has_arranged',
        },
        {
            text: '未完成排课',
            value: 'can_arrange',
        },
        {
            text: '无法排课',
            value: 'cant_arrange',
        },
    ];

    @Ref() private readonly coursesTable;
    @Ref() private readonly addCourseModal;
    @Ref() private readonly editCourseModal;

    private mounted() {
        this.getCourses();
    }

    private getCourses() {
        this.loading = true;
        axios.get('api/v1/courses-management/courses', {
            ...this.queriedCondition,
            type: this.coursesType,
        }).then(({
            courses,
            total,
            templateCourses,
        }) => {
            this.courses = courses;
            this.totalCourses = total;
            this.templateCourses = templateCourses;
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.loading = false;
        });
    }

    private formattedCourseTitle(course) {
        if (course.isClosed) {
            return `（已关班）${course.title}`;
        }
        return course.title;
    }

    private lectureStatusType(lectureStatus) {
        if (lectureStatus === 'has_arranged') {
            return 'primary';
        } else if (lectureStatus === 'can_arrange') {
            return 'warning';
        }
        return 'danger';
    }

    private lectureStatusContent(lectureStatus) {
        if (lectureStatus === 'has_arranged') {
            return '完成排课';
        } else if (lectureStatus === 'can_arrange') {
            return '未完成排课';
        }
        return '无法排课';
    }

    private queryCourses() {
        this.queriedCondition.lectureStatus = [];
        this.queriedCondition.page = 1;
        this.coursesTable.clearFilter();
        this.getCourses();
    }

    private openAddCourseModal() {
        this.addCourseModal.$emit('open');
    }

    private openEditCourseModal(course: any) {
        this.editCourseModal.$emit('open', course);
    }

    private refreshCourses() {
        this.queriedCondition = {
            courseId: '',
            title: '',
            lectureStatus: [],
            lectureSchedule: '',
            page: 1,
        };
        this.queryCourses();
    }

    private changeFilter(filter: any) {
        if (filter.hasOwnProperty('lectureStatus')) {
            this.queriedCondition.lectureStatus = filter.lectureStatus;
        }
        this.queriedCondition.page = 1;
        this.getCourses();
    }

    private changePage(page: number) {
        this.queriedCondition.page = page;
        this.getCourses();
    }

    private jumpToCourseLectures({ lectureStatus, courseId }) {
        if (lectureStatus === 'cant_arrange') {
            return this.$message.error('无法排课');
        }
        this.$router.push(`/course-lectures-management?courseId=${courseId}`);
    }

    private deleteCourseLectures({ courseId, studentCount }) {
        if (studentCount > 0) {
            return this.$message.error('该班级已有学生，无法删除');
        }
        this.$confirm('此操作会将该班级删除并无法恢复，原则上，我们只有在当班级介绍,内容中出现违法内容时进行此操作，是否继续？', '删除班级', {
            confirmButtonText: '确定',
            confirmButtonClass: 'el-button--danger',
            cancelButtonText: '取消',
        }).then(() => {
            axios.post('/api/v1/courses-management/delete-course', {
                courseId,
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '删除班级成功',
                });
                this.queryCourses();
            });
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除',
            });
        });
    }

    private closeCourse({ courseId }) {
        this.$confirm('此操作会将该班级关闭并无法恢复，是否继续？', '关闭班级', {
            confirmButtonText: '确定',
            confirmButtonClass: 'el-button--danger',
            cancelButtonText: '取消',
        }).then(() => {
            axios.post('/api/v1/courses-management/close-course', {
                courseId,
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '关闭班级成功',
                });
                this.queryCourses();
            });
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消关闭',
            });
        });
    }
}
