
import Vue from 'vue';
import axios from '../../plugins/axios.js';
import { Provide, Component, Ref } from 'vue-property-decorator';

@Component
export default class CustomerInputStatistics extends Vue {
    @Provide() private queriedCondition: { [index: string]: any } = {
        month: null,
        operator: '',
        sortProp: 'month',
        sortOrder: 'descending',
        page: 1,
    };
    @Provide() private operators: object[] = [];
    @Provide() private records: object[] = [];
    @Provide() private pageSize: number = 10;
    @Provide() private totalRecords: number = 0;
    @Provide() private loading: boolean = true;
    @Ref() private readonly recordsTable;

    private changePage(page: number) {
        this.queriedCondition.page = page;
        this.getRecords();
    }

    private queryRecord() {
        this.recordsTable.sort('month', 'descending');
    }

    private changeSort({ prop, order }) {
        this.queriedCondition.sortProp = prop ? prop : 'month';
        this.queriedCondition.sortOrder = order ? order : 'descending';
        this.queriedCondition.page = 1;
        this.getRecords();
    }

    private mounted() {
        this.getOperators();
        this.getRecords();
    }

    private getOperators() {
        axios.get('/api/v1/crm/customer-input-operators').then(({ operators }) => {
            this.operators = operators;
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    private getRecords() {
        this.loading = true;
        axios.get('api/v1/crm/customer-input-records', this.queriedCondition).then((response: any) => {
            this.records = response.data;
            this.totalRecords = response.total;
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.loading = false;
        });
    }
}
