
import Vue from 'vue';
import * as echarts from 'echarts/core';
import { LineChart, BarChart } from 'echarts/charts';
import { TooltipComponent, GridComponent, ToolboxComponent, LegendComponent  } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { UniversalTransition } from 'echarts/features';
import { Provide, Component, Prop, Watch, Ref } from 'vue-property-decorator';
echarts.use([
  TooltipComponent,
  GridComponent,
  LineChart,
  BarChart,
  UniversalTransition,
  CanvasRenderer,
  ToolboxComponent,
  LegendComponent,
]);
@Component({})
export default class DataSummaryTrendsChart extends Vue {
    @Prop() private dailyTrends!: any;
    @Provide() private trendsChart: any = null;
    @Provide() private trends: any = {
        color: ['#5470C6'],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
            },
        },
        grid: {
            left: '5%',
            right: '5%',
        },
        legend: {
            data: ['新开账号机构数', '新增阶段总数', '新增新生阶段数', '新增老生阶段数', '退课阶段总数'],
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true,
                },
                data: [],
            },
        ],
        yAxis: [
            {
                type: 'value',
                position: 'left',
                axisLine: {
                    show: true,
                },
            },
            {
                type: 'value',
                position: 'right',
                axisLine: {
                    show: true,
                },
            },
        ],
        series: [
            {
                name: '新开账号机构数',
                type: 'bar',
                yAxisIndex: 1,
                data: [],
                itemStyle: {
                    normal: {
                        color: '#0180ff',
                    },
                },
                z: 10,
            },
            {
                name: '新增阶段总数',
                type: 'line',
                symbol: 'none',
                itemStyle: {
                    normal: {
                        color: '#bff199',
                        lineStyle: {
                            color: '#bff199',
                        },
                    },
                },
                areaStyle: {
                    color: '#bff199',
                },
                data: [],
            },
            {
                name: '新增新生阶段数',
                type: 'line',
                symbol: 'none',
                data: [],
                itemStyle: {
                    normal: {
                        color: '#41b146',
                        lineStyle: {
                            color: '#41b146',
                        },
                    },
                },
            },
            {
                name: '新增老生阶段数',
                type: 'line',
                symbol: 'none',
                data: [],
                itemStyle: {
                    normal: {
                        color: '#41b146',
                        lineStyle: {
                            color: '#41b146',
                            type: 'dashed',
                        },
                    },
                },
            },
            {
                name: '退课阶段总数',
                type: 'line',
                data: [],
                symbol: 'none',
                itemStyle: {
                    normal: {
                        color: '#f33b29',
                        lineStyle: {
                            color: '#f33b29',
                        },
                    },
                },
                areaStyle: {
                    color: '#f33b29',
                },
            },
        ],
    };
    @Ref() private readonly trendsChartConatiner;
    @Watch('dailyTrends', { deep: true })
    private refreshChart(trends) {
        this.trends.xAxis[0].data = trends.dates;
        this.trends.series[0].data = trends.countsOfPartnersWithNewStudentInDaily;
        this.trends.series[1].data = trends.countsOfStudentsInDaily;
        this.trends.series[2].data = trends.countsOfNewStudentsInDaily;
        this.trends.series[3].data = trends.countsOfOldStudentsInDaily;
        this.trends.series[4].data = trends.countsOfDropStudentsInDaily;
        this.trendsChart.setOption(this.trends);
    }
    private mounted() {
        this.initTrendsChart();
    }
    private initTrendsChart() {
        // 执行图表对象的初始化
        this.trendsChart = echarts.init(this.trendsChartConatiner);
        // 为图标表对象赋值
        this.trendsChart.setOption(this.trends);
        // 当尺寸发生变化时，图像也跟随变化
        window.addEventListener('resize', this.trendsChart.resize);
    }
    private beforeDestroy() {
        window.removeEventListener('resize', this.trendsChart.resize);
    }
}
