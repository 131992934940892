
import axios from '../../plugins/axios.js';
import { Provide, Component, Prop, Ref, Mixins } from 'vue-property-decorator';
import CourseClassifyMixins from '@/views/shared/CourseClassifyMixins.vue';
import UpdateCourseModal from './modals/UpdateCourse.vue';

@Component({
    components: {
        UpdateCourseModal,
    },
})

export default class PartnerCourses extends Mixins(CourseClassifyMixins) {
    @Prop() private partnerCode!: any;
    @Provide() private courses: any = [];
    @Provide() private queriedCondition: { [index: string]: any } = {
        courseId: '',
        title: '',
        classifies: [],
        page: '1',
    };
    @Provide() private currentPage: number = 1;
    @Provide() private pageSize: number = 10;
    @Provide() private loading: boolean = true;
    @Provide() private totalCourses: number = 0;
    @Provide() private selectedCourse: any = {};

    @Ref() private readonly updateCourseModal;

    private mounted() {
        this.getCourses();
    }
    private changePage(page: number) {
        this.queriedCondition.page = page.toString();
        this.getCourses();
    }
    private queryCourses() {
        this.queriedCondition.page = '1';
        this.getCourses();
    }
    private getCourses() {
        const url = '/api/v1/partner-manage/courses';
        const params = this.queriedCondition;
        this.loading = true;
        axios.get(url, {
            ...params,
            partnerCode: this.partnerCode,
        }).then(({ courses, total, courseClassify }) => {
            this.courses = courses;
            this.totalCourses = total;
            this.courseClassify = courseClassify;
            this.currentPage = parseInt(this.queriedCondition.page, 0);
            this.loading = false;
        }).catch((error) => {
            this.$message.error(error);
        });
    }
    private openUpdateCourseModal(selectedCourse) {
        this.selectedCourse = selectedCourse;
        this.updateCourseModal.$emit('open');
    }
}
