
import Vue from 'vue';
import axios from '@/plugins/axios.js';
import { Provide, Component, Prop, Watch, Ref } from 'vue-property-decorator';
@Component({})
export default class AddVoucher extends Vue {
    @Prop() private courseClassifyOptions;
    @Prop() private couponTemplates;
    @Provide() private voucher: { [index: string]: any } = {
        title: '',
        description: '',
        price: null,
        duration: null,
        type: 'courseIds',
        courseIds: null,
        courseClassifies: null,
        couponTemplate: null,
        benefits: null,
    };
    @Provide() private selectedCouponTemplateIndex: any = null;
    @Provide() private showDialog: boolean = false;
    @Provide() private submitting: boolean = false;
    @Provide() private courseId: string = '';
    @Provide() private courses: any[] = [];
    @Provide() private types: any[] = [
        {
            title: '课程 ID',
            value: 'courseIds',
        },
        {
            title: '课程分类',
            value: 'courseClassifies',
        },
        {
            title: '优惠券',
            value: 'couponTemplate',
        },
        {
            title: '权益',
            value: 'benefits',
        },
    ];
    @Ref() private readonly voucherForm;
    @Provide() private rules: { [index: string]: object[] } = {
        title: [
            { required: true, message: '请填写标题', trigger: 'blur' },
        ],
        price: [
            { required: true, message: '请填写价格', trigger: 'blur' },
        ],
        duration: [
            { required: true, message: '请填写有效时长', trigger: 'blur' },
        ],
        courseIds: [
            { validator: this.validateCourseIds, trigger: 'blur' },
        ],
        courseClassifies: [
            { validator: this.validateCourseClassifies, trigger: 'blur' },
        ],
        couponTemplate: [
            { validator: this.validateCouponTemplate, trigger: 'blur' },
        ],
        benefits: [
            { validator: this.validateBenefits, trigger: 'blur' },
        ],
    };

    private validateCourseIds(rule: any, value: any, callback: any) {
        if (this.voucher.type === 'courseIds' && value.length === 0) {
            return callback(new Error('请填写可转入的课程 ID'));
        }
        return callback();
    }

    private validateCourseClassifies(rule: any, value: any, callback: any) {
        if (this.voucher.type === 'courseClassifies' && value.length === 0) {
            return callback(new Error('请选择可转入的课程分类'));
        }
        return callback();
    }

    private validateCouponTemplate(rule: any, value: any, callback: any) {
        if (this.voucher.type === 'couponTemplate' && !value) {
            return callback(new Error('请选择可兑换的优惠券模版'));
        }
        return callback();
    }

    private validateBenefits(rule: any, value: any, callback: any) {
        if (this.voucher.type === 'benefits' && value.length === 0) {
            return callback(new Error('请填写可兑换的权益'));
        }
        return callback();
    }

    private handleChange(value) {
        this.voucher.courseClassifies = value;
    }

    private created() {
        this.$on('open', () => {
            this.showDialog = true;
        });
    }

    @Watch('voucher.type', { deep: true })
    private voucherTypeUpdated() {
        this.courses = [];
        this.courseId = '';
        this.voucher.courseIds = null;
        this.voucher.courseClassifies = null;
        this.voucher.couponTemplate = null;
        this.voucher.benefits = null;
    }

    @Watch('courses', { deep: true })
    private updateVoucherCourseIds() {
        this.voucher.courseIds = this.courses.map((course) => course.courseId);
    }

    @Watch('selectedCouponTemplateIndex', { deep: true })
    private updateVoucherCouponTemplate() {
        this.voucher.couponTemplate = this.couponTemplates[this.selectedCouponTemplateIndex];
    }

    private beforeAddingCourseId(courseId) {
        if (!(/^\+?(0|[1-9]\d*)$/).test(courseId)) {
            return this.$message.error('课号必须为纯数字');
        }
        this.getCourseTitle(parseInt(courseId, 0));
    }

    private removeCourse(removedCourseId) {
        this.courses = this.courses.filter((course) => course.courseId !== removedCourseId);
    }

    private getCourseTitle(courseId) {
        axios.get('/api/v1/sales/get-course', {
            courseId,
        }).then(({ title }) => {
            this.courseId = '';
            this.courses.push({
                courseId,
                title,
            });
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    private clearValidate() {
        this.voucherForm.clearValidate();
    }

    private addVoucher() {
        this.voucherForm.validate((valid) => {
            if (!valid) {
                return false;
            }
            this.submitting = true;
            axios.post('/api/v1/voucher', this.voucher).then(() => {
                this.$message({
                    type: 'success',
                    message: '添加权益成功',
                });
                this.clearForm();
                this.showDialog = false;
                this.submitting = false;
                this.$emit('refresh-vouchers');
            }).catch((error) => {
                this.submitting = false;
                this.$message.error(error);
            });
        });
    }

    private clearForm() {
        this.voucher = {
            title: '',
            description: '',
            price: null,
            duration: null,
            type: 'courseIds',
            courseIds: null,
            courseClassifies: null,
            couponTemplate: null,
            benefits: null,
        };
        this.courses = [];
        this.courseId = '';
    }
}
