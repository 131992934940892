
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { InjectReactive, Provide, Prop, Component, Ref } from 'vue-property-decorator';
import MarkdownEditor from '../../../components/shared/MarkdownEditor.vue';

@Component({
    components: {
        MarkdownEditor,
    },
})
export default class AddLiveCourse extends Vue {
    @InjectReactive() private advisorsOptions;

    @Prop() private courseType!: any;
    @Prop() private templateCourses!: any;

    @Provide() private showDialog: boolean = false;
    @Provide() private course: { [index: string]: any } = {
        templateCourseId: null,
        title: '',
        classify: [],
        advisorUid: null,
        isEnrollable: true,
        hasTutor: true,
        hasLiveSupport: true,
        isForSale: false,
        hasSelfService: false,
        price: 0,
        numberOfLectures: 0,
    };
    @Ref() private readonly introductionEditor;

    private created() {
        this.$on('open', () => {
            this.showDialog = true;
        });
    }

    private confirm() {
        if (!this.course.templateCourseId) {
            return this.$message.error('请选择一个开班模板');
        }
        axios.post('/api/v1/courses-management/add-course', {
            ...this.course,
            introduction: this.introductionEditor.getValue(),
        }).then(() => {
            this.$message({
                type: 'success',
                message: '新增班级成功',
            });
            this.clearForm();
            this.$emit('refresh-courses');
            this.showDialog = false;
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    private clearForm() {
        this.course = {
            templateCourseId: null,
            title: '',
            introduction: '',
            classify: [],
            advisorUid: null,
            isEnrollable: true,
            hasTutor: true,
            hasLiveSupport: true,
            isForSale: false,
            hasSelfService: false,
            price: 0,
            numberOfLectures: 0,
        };
        this.introductionEditor.setValue('');
    }
}
