import Vue from 'vue';
Vue.filter('filterLevelType', (index: number) => {
    switch (index) {
        case 0: {
            return 'info';
        }
        case 1: {
            return 'success';
        }
        case 2: {
            return 'warning';
        }
        case 3: {
            return 'danger';
        }
    }
    return '';
});
Vue.filter('filterLevelText', (index: number) => {
    switch (index) {
        case 0: {
            return '无';
        }
        case 1: {
            return '初级';
        }
        case 2: {
            return '中级';
        }
        case 3: {
            return '高级';
        }
    }
    return '';
});
