
import axios from '@/plugins/axios.js';
import { Provide, Component, Prop, Mixins } from 'vue-property-decorator';
import AddCouponMixins from './AddCouponMixins.vue';
@Component
export default class AddCoupon extends Mixins(AddCouponMixins) {
    // 课程分类
    @Prop() private courseClassifyOptions;
    // 优惠券限制类型
    @Provide() private couponConstraints: any[] = [
        {
            title: '不限制',
            value: 'noConstraint',
        },
        {
            title: '限制课程 ID',
            value: 'courseConstraint',
        },
        {
            title: '限制课程类型',
            value: 'categoryConstraint',
        },
    ];
    @Provide() private rules: { [index: string]: object[] } = {
        title: [
            { required: true, message: '请填写优惠券标题', trigger: 'blur' },
        ],
        discount: [
            { required: true, message: '请填写优惠券金额', trigger: 'blur' },
        ],
        phone: [
            { required: true, message: '请填写用户手机号', trigger: 'blur' },
        ],
        couponConstraint: [
            { required: true, message: '请选择优惠券限制条件', trigger: 'blur' },
        ],
        courseIds: [
            { validator: this.validateCourseIds, trigger: 'blur' },
        ],
        classifyIds: [
            { validator: this.validateClassifyIds, trigger: 'blur' },
        ],
        endAt: [
            { required: true, message: '请选择优惠券到期时间', trigger: 'blur' },
        ],
    };

    private validateCourseIds(rule: any, value: any, callback: any) {
        if (this.coupon.couponConstraint === 'courseConstraint' && value.length === 0) {
            return callback(new Error('请填写限制课程 ID'));
        }
        return callback();
    }

    private validateClassifyIds(rule: any, value: any, callback: any) {
        if (this.coupon.couponConstraint === 'categoryConstraint' && value.length === 0) {
            return callback(new Error('请选择限制课程类型'));
        }
        return callback();
    }

    private handleChange(value) {
        this.coupon.classifyIds = value;
    }

    private couponLimitChanged(value) {
        this.coupon.classifyIds = [];
        this.coupon.courseIds = [];
        this.courses = [];
        this.courseId = '';
    }

    private beforeAddingCourseId(courseId) {
        if (!(/^\+?(0|[1-9]\d*)$/).test(courseId)) {
            return this.$message.error('课号必须为纯数字');
        }
        this.getCourseTitle(parseInt(courseId, 0));
    }

    private removeCourse(courseId) {
        this.courses = this.courses.filter((course) => course.courseId !== courseId);
        this.coupon.courseIds = this.coupon.courseIds.filter((courseIdInInvitation) => courseIdInInvitation !== courseId);
    }

    private getCourseTitle(newCourseId) {
        const url = '/api/v1/sales/get-course';
        const params = {
            courseId: newCourseId,
        };
        axios.get(url, params).then(({ title }) => {
            this.courses.push({
                courseId: newCourseId,
                title,
            });
            this.coupon.courseIds.push(newCourseId);
        }).catch((error) => {
            this.$message.error(error);
        });
    }
}
