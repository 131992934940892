
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Ref, Prop } from 'vue-property-decorator';
import RedeemVoucherToCourseModal from '../modals/RedeemVoucherToCourse.vue';
import ConfirmSwitchCourseModal from '../modals/ConfirmSwitchCourse.vue';
@Component({
    components: {
        RedeemVoucherToCourseModal,
        ConfirmSwitchCourseModal,
    },
})
export default class StudentPausedCourses extends Vue {
    @Prop() public student!: any;
    @Provide() public loading: boolean = false;
    @Provide() public pausedCourses: any = [];
    @Provide() public selectedVoucher: any = null;
    @Provide() private selectedCourse: any = {};
    @Ref() private readonly redeemVoucherToCourseModal;
    @Ref() private readonly confirmSwitchCourseModal;

    private created() {
        this.getPausedCourses();
    }

    private getPausedCourses() {
        this.loading = true;
        axios.get('/api/v1/advisor/student-paused-courses', {
            studentUuid: this.student.uuid,
        }).then((pausedCourses) => {
            this.pausedCourses = pausedCourses;
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.loading = false;
        });
    }

    private openRedeemVoucherToCourseModal(voucher) {
        this.selectedVoucher = voucher;
        this.redeemVoucherToCourseModal.$emit('open');
    }

    private openDeleteStudentVoucherConfirmModal(voucher) {
        this.$confirm(`确认对用户《${voucher.title}》执行退班操作吗？该操作无法恢复`, '确认退班', {
            confirmButtonText: '确认',
            confirmButtonClass: 'el-button--danger',
            cancelButtonText: '取消',
        }).then(() => {
            this.deleteStudentVoucher(voucher);
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消退班',
            });
        });
    }

    private deleteStudentVoucher(voucher) {
        axios.post('/api/v1/advisor/delete-student-voucher', {
            userVoucherId: voucher.userVoucherId,
        }).then(() => {
            this.$message({
                type: 'success',
                message: '用户退班成功',
            });
            this.handelStudentCourseUpdated();
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    private openSwitchCourseModal(selectedCourse) {
        this.selectedCourse = selectedCourse;
        this.confirmSwitchCourseModal.$emit('open');
    }

    private openDropStudentCourseConfirmModal(course) {
        this.$confirm(`确认对用户《${course.title}》班级执行退班操作吗？该操作无法恢复`, '确认退班', {
            confirmButtonText: '确认',
            confirmButtonClass: 'el-button--danger',
            cancelButtonText: '取消',
        }).then(() => {
            this.confirmDrop(course);
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消退班',
            });
        });
    }

    private confirmDrop(course) {
        axios.post('/api/v1/advisor/drop-student-course', {
            studentUuid: this.student.uuid,
            courseId: course.courseId,
        }).then(() => {
            this.$message({
                type: 'success',
                message: '用户退班成功',
            });
            this.handelStudentCourseUpdated();
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    private handelStudentCourseUpdated() {
        this.$emit('student-course-updated');
        this.getPausedCourses();
    }
}
