
import Vue from 'vue';
import moment from 'moment';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Prop, Ref } from 'vue-property-decorator';

@Component
export default class ConfirmSwitchCourse extends Vue {
    @Prop() private selectedCourse!: any;
    @Prop() private studentUuid!: any;

    @Provide() private newCourse: { [index: string]: any } = {
        courseId: null,
        title: null,
        availableTime: null,
    };
    @Provide() private showDialog: boolean = false;
    @Provide() private submitting: boolean = false;
    @Provide() private pickerOptions = {
        shortcuts: [
            {
                text: '1 分钟后',
                onClick(picker) {
                    const OneMinute = 1;
                    picker.$emit('pick', moment().add(OneMinute, 'm').format('YYYY-MM-DD HH:mm'));
                },
            },
        ],
    };
    @Ref() private readonly switchForm;

    private created() {
        this.$on('open', () => {
            this.clearForm();
            this.showDialog = true;
        });
    }

    private get needToSetNewCourseAvailableTime() {
        return parseInt(this.newCourse.courseId, 0) !== parseInt(this.selectedCourse.courseId, 0) || !this.newCourse.title;
    }

    private requestCourseTitle() {
        if (this.newCourse.title) {
            return this.$message.error('最多支持换到一个班级');
        }
        axios.post('/api/v1/advisor/student-course-permission', {
            originalCourseId: this.selectedCourse.courseId,
            newCourseId: this.newCourse.courseId,
            studentUuid: this.studentUuid,
        }).then(({ courseTitle }) => {
            this.newCourse.title = courseTitle;
        }).catch((error) => {
            this.newCourse.courseId = null;
            this.$message.error(error);
        });
    }

    private confirm() {
        if (!this.newCourse.courseId || !this.newCourse.title) {
            return this.$message.error('请填写要换到的班级 ID');
        }
        if (this.needToSetNewCourseAvailableTime && !this.newCourse.availableTime) {
            return this.$message.error('请输入新班级开始可访问时间');
        }
        this.submitting = true;
        axios.post('/api/v1/advisor/switch-student-course', {
            originalCourseId: this.selectedCourse.courseId,
            newCourseId: this.newCourse.courseId,
            newCourseAvailableTime: this.newCourse.availableTime,
            studentUuid: this.studentUuid,
        }).then(() => {
            this.$message({
                type: 'success',
                message: '转班成功',
            });
            this.clearForm();
            this.showDialog = false;
            this.$emit('switch-completed');
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.submitting = false;
        });
    }

    private clearForm() {
        this.newCourse = {
            courseId: null,
            title: null,
            availableTime: null,
        };
    }

    private clearValidate() {
        this.switchForm.clearValidate();
    }
}
