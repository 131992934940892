
import Vue from 'vue';
import axios from '../../plugins/axios.js';
import { Provide, Component, Ref } from 'vue-property-decorator';
import UpdateCampaignModal from './modals/UpdateCampaignModal.vue';
@Component({
    components: {
        UpdateCampaignModal,
    },
})
export default class MarketingCampaignManagement extends Vue {
    @Provide() private campaigns: any = [];
    @Provide() private vouchers: any = [];
    @Provide() private currentPage: number = 1;
    @Provide() private pageSize: number = 10;
    @Provide() private loading: boolean = true;
    @Provide() private total: number = 0;
    @Ref() private readonly updateCampaignModal;

    private mounted() {
        this.requestEnabledVouchers();
        this.requestCampaigns();
    }

    private requestEnabledVouchers() {
        axios.get('/api/v1/marketing-campaign-management/enabled-vouchers').then((vouchers) => {
            this.vouchers = vouchers;
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    private requestCampaigns() {
        this.loading = true;
        axios.get('/api/v1/marketing-campaign-management/campaigns', {
            params: {
                page: this.currentPage,
            },
        }).then(({ campaigns, total }) => {
            this.campaigns = campaigns;
            this.total = total;
            this.loading = false;
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    private changePage(page: number) {
        this.currentPage = page;
        this.requestCampaigns();
    }

    private toggleCampaignActiveStatus(campaign) {
        axios.post('/api/v1/marketing-campaign-management/toggle-campaign-active', {
            id: campaign.id,
        }).catch((error) => {
            campaign.isActive = campaign.isActive ? 0 : 1;
            this.$message.error(error);
        });
    }

    private urlOfMiniapp(campaign) {
        return `pages/marketing-campaign/index?campaignId=${campaign.id}`;
    }

    private copySuccessfully() {
        this.$message({
            type: 'success',
            message: '小程序入口链接复制成功',
        });
    }

    private openCreateCampaignModal() {
        this.updateCampaignModal.$emit('open', {
            id: 0,
            title: '',
            urlOfPoster: '',
            urlOfSmallThumbnail: '',
            urlOfBigThumbnail: '',
            voucherId: null,
            isImmediate: 0,
            instruction: '',
            buttonColor: '#4EC53D',
            isActive: 0,
        });
    }

    private openUpdateCampaignModal(selectedCampaign) {
        this.updateCampaignModal.$emit('open', selectedCampaign);
    }
}

