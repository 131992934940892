
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Ref } from 'vue-property-decorator';
import AddLabels from '../modals/AddLabels.vue';

@Component({
    components: {
        AddLabels,
    },
})
export default class InputSimpleCustomer extends Vue {
    @Provide() private labels: { [index: string]: any } = {};
    @Provide() private sources: { [index: string]: any } = {};
    @Provide() private followUsers: { [index: string]: any } = {};
    @Provide() private customerInfo: { [index: string]: any } = {
        name: '',
        phoneNumber: '',
        labels: [],
        source: '',
        account: '',
        remark: '',
        followUserId: '',
    };
    @Provide() private submitting: boolean = false;
    @Provide() private rules: { [index: string]: object[] } = {
        name: [
            { required: true, message: '请输入客户的姓名', trigger: 'blur' },
            { max: 15, message: '长度不应超过 15 个字符', trigger: 'blur' },
        ],
        phoneNumber: [
            { required: true, message: '请输入客户手机号', trigger: 'blur' },
            { validator: this.validatePhone, trigger: 'blur' },
        ],
        source: [
            {required: true, message: '请输入客户来源', trigger: 'blur' },
        ],
        remark: [
            { max: 500, message: '备注长度不超过 500 个字符', trigger: 'blur'  },
        ],
    };

    @Ref() private readonly customerForm;
    @Ref() private readonly addLabelsModal;

    private mounted() {
        this.getSources();
        this.getFollowUsers();
    }

    private getSources() {
        const url = '/api/v1/crm/get-channel-source';
        axios.get(url).then(({ list }) => {
            this.sources = list;
        }).catch((error) => {
            this.$message.error(error);
        });
    }
    private getFollowUsers() {
        axios.get('/api/v1/crm/get-follow-users').then((followUsers) => {
            this.followUsers = followUsers;
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    private validatePhone(rule: any, value: any, callback: any) {
        if (!value) {
            return callback();
        }
        const phoneReg = /^\d{11}$|^\+\d{8,}$/;
        if (!phoneReg.test(value)) {
            callback(new Error('请填写正确的手机号码'));
        }
        callback();
    }

    private openAddLabelsModal() {
        this.addLabelsModal.$emit('open');
    }

    private updateLabels(idNameMap) {
        this.customerInfo.labels = [];
        for (const labelGroup in this.labels) {
            // 添加多选标签
            if (Array.isArray(this.labels[labelGroup])) {
                for (const label of this.labels[labelGroup]) {
                    this.customerInfo.labels.push({
                        classId: label,
                        className: idNameMap[label],
                        groupId: labelGroup,
                    });
                }
            } else {
                // 如果某类别单选标签为 '' 则不添加
                if (this.labels[labelGroup] !== '') {
                    this.customerInfo.labels.push({
                        classId: this.labels[labelGroup],
                        className: idNameMap[this.labels[labelGroup]],
                        groupId: labelGroup,
                    });
                }
            }
        }
    }
    private removeLabel(targetClassId) {
        // 从选中的标签 labels 中移除
        for (const labelGroup in this.labels) {
            if (Array.isArray(this.labels[labelGroup])) {
                this.labels[labelGroup].forEach((classId, index) => {
                    if (classId === targetClassId) {
                        this.labels[labelGroup].splice(index, 1);
                    }
                });
            } else {
                if (targetClassId === this.labels[labelGroup]) {
                    this.labels[labelGroup] = '';
                }
            }
        }
        // 从 customerInfo 中移除
        this.customerInfo.labels.forEach((label, index) => {
            if (targetClassId === label.classId) {
                this.customerInfo.labels.splice(index, 1);
            }
        });
    }
    private submitForm() {
        this.customerForm.validate((valid) => {
            if (!valid) {
                return false;
            }
            this.submitting = true;
            const paramLabels = new Array();
            // 对 labels 数据进行处理
            for (const label of this.customerInfo.labels) {
                paramLabels.push(Number(label.classId));
            }
            axios.post('/api/v1/crm/customer-input', {
                ...this.customerInfo,
                labels: paramLabels,
                phone: this.customerInfo.phoneNumber.trim(),
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '客户录入成功',
                });
                this.clearForm();
            }).catch((error) => {
                this.$message.error(error);
            }).then(() => {
                this.submitting = false;
            });
        });
    }
    private clearForm() {
        this.customerInfo = {
            name: '',
            phoneNumber: '',
            wechat: '',
            labels: [],
            qq: '',
            account: '',
            source: '',
            remark: '',
            followUserId: '',
        };
        this.addLabelsModal.updateLabels();
        this.customerForm.clearValidate();
    }
}

