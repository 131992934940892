
import Vue from 'vue';
import { Provide, Component, Ref } from 'vue-property-decorator';
import axios from '../../plugins/axios.js';
import InputExpressModal from './modals/InputExpress.vue';

interface ExpressInfo {
    uuid: string;
    name?: string;
    phone?: string;
    address?: string;
}

@Component({
    components: {
        InputExpressModal,
    },
})
export default class ExpressInput extends Vue {
    @Provide() private expresses: ExpressInfo[] = [];
    @Provide() private channels: { [index: string]: string } = {};
    @Provide() private queriedCondition: { [index: string]: string } = {
        course_id: '',
        uuid: '',
        phone: '',
        page: '1',
    };
    @Provide() private currentPage: number = 1;
    @Provide() private pageSize: number = 10;
    @Provide() private totalExpresses: number = 0;
    @Provide() private loading: boolean = false;
    @Provide() private showTable: boolean = false;
    @Provide() private selectedExpress: ExpressInfo = { uuid: '' };
    @Ref() private readonly inputExpressModal;

    private changePage(page: number) {
        this.queriedCondition.page = page.toString();
        this.getExpresses();
    }
    private queryExpress() {
        this.queriedCondition.page = '1';
        this.getExpresses();
    }
    private getExpresses() {
        this.loading = true;
        const url = '/api/v1/express';
        const params = this.queriedCondition;
        axios.get(url, params).then((response: any) => {
            this.showTable = true;
            this.updateExpressInfo(response.info_list);
            this.channels = response.express_channels;
            this.totalExpresses = response.total;
            this.currentPage = parseInt(this.queriedCondition.page, 0);
            this.loading = false;
        }).catch((error) => {
            this.expresses = [];
            this.showTable = false;
            this.loading = false;
            this.$message.error(error);
        });
    }
    private updateExpressInfo(expresses: any) {
        this.expresses = [];
        for (const express of expresses) {
            let address: string = '';
            if (express.consignee_name) {
                address = `${address}${express.consignee_name}`;
            }
            if (express.consignee_phone) {
                address = `${address} ${express.consignee_phone}`;
            }
            if (express.address) {
                if (express.address.province.substr(-1) === '市') {
                    express.address.city = express.address.province;
                }
                const expressAddress = `${express.address.province} ${express.address.city} ${express.address.district} ${express.address.detail}`;
                address = `${address} ${expressAddress}`;
            }
            const expressInfo: ExpressInfo = {
                uuid: express.uuid,
                phone: express.phone,
                name: express.name,
                address: address ? address : '-',
            };
            this.expresses.push(expressInfo);
        }
    }
    private onCopy() {
        this.$message({
            type: 'success',
            message: '收件地址复制成功',
        });
    }
    private inputExpress(express: ExpressInfo) {
        this.selectedExpress = express;
        this.inputExpressModal.$emit('open');
    }
}
