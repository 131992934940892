
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Prop, Provide, Component, Ref } from 'vue-property-decorator';
import UpdateDepartmentModal from '../modals/UpdateDepartment.vue';
import _ from 'lodash';

@Component({
    components: {
        UpdateDepartmentModal,
    },
})
export default class DepartmentsTree extends Vue {
    @Prop() private isAdmin;

    @Provide() private departments: any = [];
    @Provide() private permissions: any[] = [];
    @Provide() private selectedDepartment: { [index: string]: any } = {
        id: -1,
        title: '',
        permissions: [],
    };
    @Provide() private departmentPermissions: any = [];

    @Provide() private loading: boolean = true;
    @Provide() private loadingPermissions: boolean = false;

    @Ref() private readonly departmentsTree;
    @Ref() private readonly updateDepartmentModal;

    private mounted() {
        this.getPermissions();
        this.getDepartments();
    }

    private getPermissions() {
        axios.get('/api/v1/authority-manage/permissions').then((permissions) => {
            this.permissions = this.setPermissions(permissions);
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    private openAddDepartmentModal() {
        this.updateDepartmentModal.$emit('open', {
            department: {
                id: 0,
                title: '',
            },
            departmentPermissions: [],
        });
    }

    private openUpdateDepartmentModal() {
        this.updateDepartmentModal.$emit('open', {
            department: this.selectedDepartment,
            departmentPermissions: this.departmentPermissions,
        });
    }

    private setPermissions(nodes) {
        let permissions: any = [];
        for (let item in nodes) {
            if (item === '蒜台-管理功能') {
                continue;
            }
            let permission: any = {
                id: null,
                label: item,
                disabled: true,
                children: [],
            };
            for (let node of nodes[item]) {
                let newPermission: any = {
                    id: node.value,
                    label: node.content,
                    disabled: false,
                };
                if (node.hasOwnProperty('children')) {
                    newPermission.children = [];
                    for (let child of node.children) {
                        newPermission.children.push({
                            id: child.value,
                            label: child.content,
                        });
                    }
                }
                permission.children.push(newPermission);
            }
            permissions.push(permission);
        }
        return permissions;
    }

    private getDepartments() {
        this.loading = true;
        axios.get('/api/v1/authority-manage/departments').then(({ departments, isAdmin }) => {
            this.loading = false;
            this.setDepartmentTree(departments);
            this.$emit('update:isAdmin', isAdmin);
        }).catch((error) => {
            this.loading = false;
            this.$message.error(error);
        });
    }

    private setDepartmentTree(departments) {
        this.departments = new Array();
        const map = departments.reduce((res, v) => {
            res[v.id] = v;
            return res;
        }, {});
        for (const item of departments) {
            if (item.parent_id === 0) {
                this.departments.push(item);
                continue;
            }
            if (item.parent_id in map) {
                const parent = map[item.parent_id];
                parent.children = parent.children || [];
                parent.children.push(item);
            }
        }
    }

    private showDepartment(department) {
        if (this.loadingPermissions) {
            return;
        }
        this.selectedDepartment = department;
        if (this.selectedDepartment.parent_id !== 0) {
            this.getDepartmentPermissions();
        }
    }

    private getDepartmentPermissions() {
        this.loadingPermissions = true;
        axios.get('/api/v1/authority-manage/department-permissions', {
            department_id: this.selectedDepartment.id,
        }).then(({ permissions, permissionsTree }) => {
            this.departmentPermissions = permissions;
            this.$emit('department-selected', {
                department: this.selectedDepartment,
                departmentPermissionsTree: this.setPermissions(permissionsTree),
            });
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.loadingPermissions = false;
        });
    }
    private handleCommand({ value, operation }) {
        if (this.loadingPermissions) {
            return;
        }
        if (operation === 'edit') {
            return this.openUpdateDepartmentModal();
        }
        return this.openConfirmDeleteDepartmentModal(value);
    }

    private handleDepartmentUpdated(department) {
        this.getDepartments();
        if (department.id) {
            this.$nextTick(() => {
                this.showDepartment(department);
            });
        }
    }

    private openConfirmDeleteDepartmentModal(department) {
        this.$confirm('部门删除后，部门中的所有角色将被一同删除且无法找回，确认要删除吗？', '删除部门', {
            confirmButtonText: '确认',
            confirmButtonClass: 'el-button--danger',
            cancelButtonText: '取消',
        }).then(() => {
            this.deleteDepartment(department);
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除',
            });
        });
    }

    private deleteDepartment(department) {
        axios.post('/api/v1/authority-manage/delete-department', {
            departmentId: department.id,
        }).then(() => {
            this.$message({
                type: 'success',
                message: '部门删除成功',
            });
            this.getDepartments();
            this.$emit('department-selected', {
                department: {
                    id: -1,
                    title: '',
                    permissions: [],
                },
                departmentPermissionsTree: [],
            });
        }).catch((error) => {
            this.$message.error(error);
        });
    }
}
