
import Vue from 'vue';
import { Provide, Component, Prop } from 'vue-property-decorator';
import axios from '../../../plugins/axios.js';
import moment from 'moment';

@Component({})
export default class StudentEcCommunications extends Vue {
    @Prop() private student!: any;
    @Provide() private showDialog: boolean = false;
    @Provide() private communications: any[] = [];
    @Provide() private loading: boolean = false;
    @Provide() private time: any[] = [];

    private created() {
        this.$on('open', () => {
            this.showDialog = true;
            this.time = [
                moment().subtract(1, 'months').format('YYYY-MM-DD HH:mm:ss'),
                moment().format('YYYY-MM-DD HH:mm:ss'),
            ];
            this.$nextTick(() => {
                this.getCommunications();
            });
        });
    }

    private getCommunications() {
        if (this.time.length !== 2) {
            return this.$message.error('请选择时间范围');
        }
        if (moment(this.time[1]).diff(moment(this.time[0]), 'days') > 31) {
            return this.$message.error('时间范围不能超过 31 天');
        }
        this.loading = true;
        axios.get('/api/v1/advisor/student-communications', {
            startTime: this.time[0],
            endTime: this.time[1],
            studentUuid: this.student.uuid,
        }).then((communications) => {
            this.communications = communications;
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.loading = false;
        });
    }
}
