
import { Component, Provide, Vue } from 'vue-property-decorator';
import axios from '../../plugins/axios.js';

@Component
export default class SwitchCourseRecord extends Vue {
    @Provide() private queriedCondition: { [index: string]: any } = {
        originalCourseId: '',
        newCourseId: '',
        studentAccount: '',
        switchedAtRange: [],
        sortOrder: 'descending',
        page: 1,
    };
    @Provide() private pageSize: number = 10;
    @Provide() private totalRecords: number = 0;
    @Provide() private loading: boolean = false;
    @Provide() private records: any[] = [];

    private mounted() {
        this.getRecords();
    }

    private changeSort({ order }) {
        this.queriedCondition.sortOrder = order ? order : 'descending';
        this.queriedCondition.page = 1;
        this.getRecords();
    }

    private changePage(page: number) {
        this.queriedCondition.page = page;
        this.getRecords();
    }

    private queryRecord() {
        this.queriedCondition.page = 1;
        this.getRecords();
    }

    private getRecords() {
        this.loading = true;
        axios.get('/api/v1/switch-course-record/records', this.queriedCondition).then((response) => {
            this.records = response.records;
            this.totalRecords = response.total;
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.loading = false;
        });
    }
}
