
import Vue from 'vue';
import { Provide, Component, Prop} from 'vue-property-decorator';

@Component
export default class AddTemplateCourses extends Vue {
    @Prop() private courses;
    @Provide() private selectedTemplateCourses: any = [];
    @Provide() private showDialog: boolean = false;
    private created() {
        this.$on('open', () => {
            this.showDialog = true;
        });
    }
    private addTemplateCourses() {
        if (this.selectedTemplateCourses.length === 0) {
            this.$message({
                type: 'warning',
                message: '请选择课程',
            });
            return;
        }
        this.$emit('add-template-courses', this.selectedTemplateCourses);
        this.showDialog = false;
    }

    private refreshSelectedTemplateCourses() {
        this.selectedTemplateCourses = [];
    }
}
