
import { Provide, Component, Ref, Mixins } from 'vue-property-decorator';
import axios from '../../plugins/axios.js';
import CourseClassifyMixins from '@/views/shared/CourseClassifyMixins.vue';
import UpdateCouponTemplateModal from './modals/UpdateCouponTemplate.vue';
@Component({
    components: {
        UpdateCouponTemplateModal,
    },
})
export default class CouponTemplateManagement extends Mixins(CourseClassifyMixins) {
    @Provide() private couponTemplates: any[] = [];
    @Provide() private queriedCondition: { [index: string]: any } = {
        page: 1,
    };
    @Provide() private pageSize: number = 10;
    @Provide() private loading: boolean = true;
    @Provide() private total: number = 0;
    @Ref() private readonly updateCouponTemplateModal;

    private mounted() {
        this.getCouponTemplates();
    }

    private refreshCouponTemplates() {
        this.queriedCondition.page = 1;
        this.getCouponTemplates();
    }

    private changePage(page: number) {
        this.queriedCondition.page = page;
        this.getCouponTemplates();
    }

    private openUpdateCouponTemplateModal(couponTemplate = { id: 0, duration: 0 }) {
        this.updateCouponTemplateModal.$emit('open', couponTemplate);
    }

    private getCouponTemplates() {
        this.loading = true;
        axios.get('/api/v1/coupon-templates', this.queriedCondition).then(({ couponTemplates, total, courseClassify }) => {
            this.loading = false;
            this.couponTemplates = couponTemplates;
            this.total = total;
            this.courseClassify = courseClassify;
        }).catch((error) => {
            this.loading = false;
            this.$message.error(error);
        });
    }

    private returnCouponDurationAsDays(duration: number) {
        return `${Math.floor(duration / 86400)} 天`;
    }

    private toggleCouponTemplateEnabledStatus(couponTemplate) {
        axios.post('/api/v1/coupon-template/enabled', {
            couponTemplateId: couponTemplate.id,
            isEnabled: couponTemplate.isEnabled,
        }).catch((error) => {
            couponTemplate.isEnabled = couponTemplate.isEnabled ? 0 : 1;
            this.$message.error(error);
        });
    }

    private deleteCouponTemplate(couponTemplate) {
        this.$confirm('该优惠券模版将会被删除且无法还原，是否继续？', '删除优惠券模版', {
            confirmButtonText: '确定',
            confirmButtonClass: 'el-button--danger',
            cancelButtonText: '取消',
        }).then(() => {
            axios.post('/api/v1/coupon-template/delete', {
                couponTemplateId: couponTemplate.id,
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '删除成功',
                });
                this.getCouponTemplates();
            }).catch((error) => {
                this.$message.error(error);
            });
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除',
            });
        });
    }
}
