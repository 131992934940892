
import Vue from 'vue';
import { Provide, Component, Prop } from 'vue-property-decorator';

@Component
export default class InvoiceInfo extends Vue {
    @Prop() private selectedInvoice!: any;
    @Provide() private showDialog: boolean = false;

    private created() {
        this.$on('open', () => {
            this.showDialog = true;
        });
    }

}
