
import axios from '@/plugins/axios.js';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import InputExpressMixins from '@/views/shared/InputExpressMixins.vue';
@Component
export default class InputExpress extends Mixins(InputExpressMixins) {
    @Prop() private selectedInvoice!: any;
    private submit() {
        this.expressForm.validate((valid) => {
            if (!valid) {
                return false;
            }
            const url = '/api/v1/invoice-manage/create-express-record';
            const params = {
                invoice_id: this.selectedInvoice.id,
                channel: this.expressInfo.channel,
                express_number: this.expressInfo.number,
            };
            axios.post(url, params).then(() => {
                this.$message({
                    type: 'success',
                    message: '快递信息录入成功，短信与服务号通知已发出',
                });
                this.expressInfo.number = '';
                this.showDialog = false;
                this.$emit('refreshCreatedInvoices');
            }).catch((error) => {
                this.$message.error(error);
            });
        });
    }
}
