
import Vue from 'vue';
import { Provide, Component } from 'vue-property-decorator';

@Component
export default class ExportCoursesLecturesModal extends Vue {
    @Provide() private showDialog: boolean = false;
    @Provide() private lecturesDate: string[] = [];

    private created() {
        this.$on('open', () => {
            this.showDialog = true;
        });
    }

    private exportLectures() {
        if (this.lecturesDate.length === 0) {
            return this.$message.error('请选择统计日期');
        }
        window.open(`${process.env.VUE_APP_API_DOMAIN}/api/v1/courses-lectures/export?start_date=${this.lecturesDate[0]}&end_date=${this.lecturesDate[1]}`);
    }
}
