
import Vue from 'vue';
import { Provide, Component, Ref } from 'vue-property-decorator';
import axios from '../../plugins/axios.js';
import InputExpressModal from './modals/InputExpress.vue';
import InvoiceInfoModal from './modals/InvoiceInfo.vue';
import ExpressInfoModal from './modals/ExpressInfo.vue';

interface InvoiceInfo {
    id?: number;
    swift_id: string;   // 支付记录号
    amount?: string;   // 订单金额
    created_at?: string;   // 申请时间
    consignee_name?: string;   // 收件人
    consignee_phone?: string;   // 收件手机号
    consignee_address?: any;   // 收件地址
    address?: string;  // 格式化后的收件地址
    title_type?: string;  // 抬头类型
    invoice_type?: string; // 发票类型
    title?: string;  // 发票抬头
    tax_number?: string; // 发票税号
    content?: string;  // 发票内容
    register_address?: string;  // 注册地址
    register_tel?: string;  // 注册电话
    bank?: string;   // 开户银行
    bank_account?: string;  // 银行账号
    invoice_at?: string;  // 开票时间
    express_channel?: string;  // 快递物流公司
    express_number?: string;  // 快递订单号
    express_details?: any;   // 快递物流详情
}

@Component({
    components: {
        InputExpressModal, InvoiceInfoModal, ExpressInfoModal,
    },
})
export default class InvoiceManage extends Vue {
    @Provide() private canCreateInvoice: boolean = false;
    @Provide() private canSendInvoice: boolean = false;
    @Provide() private activeTab: string = 'history';
    @Provide() private loading: boolean = false;
    @Provide() private channels: { [index: string]: string } = {};
    @Provide() private uncreateInvoices: InvoiceInfo[] = [];  // 待开发票
    @Provide() private createdInvoices: InvoiceInfo[] = [];  // 待寄送发票
    @Provide() private selectedInvoice: InvoiceInfo = { swift_id: '' };  // 打开 modal 时选择的发票
    @Provide() private queriedCondition: { [index: string]: any } = {
        swift_id: '',
        page: 1,
    };
    @Provide() private historyInvoices: InvoiceInfo[] = [];  // 历史发票
    @Provide() private currentPage: number = 1;
    @Provide() private pageSize: number = 10;
    @Provide() private totalHistoryInvoices: number = 0;
    @Ref() private readonly inputExpressModal;
    @Ref() private readonly invoiceInfoModal;
    @Ref() private readonly expressInfoModal;

    private mounted() {
        this.canCreateInvoice = Boolean(this.$route.query.create_invoice);
        this.canSendInvoice = Boolean(this.$route.query.send_invoice);
        if (this.canCreateInvoice) {
            this.activeTab = 'createInvoice';
            this.getUncreateInvoices();
        } else if (this.canSendInvoice) {
            this.activeTab = 'sendInvoice';
            this.getCreatedInvoices();
        } else {
            this.activeTab = 'history';
            this.getHistoryInvoices();
        }
    }

    private changeTab(tab) {
        if (tab.name === 'createInvoice') {
            this.getUncreateInvoices();
        } else if (tab.name === 'sendInvoice') {
            this.getCreatedInvoices();
        } else {
            this.getHistoryInvoices();
        }
    }

    private getUncreateInvoices() {
        this.loading = true;
        this.uncreateInvoices = [];
        axios.get('/api/v1/invoice-manage/uncreate-invoice-list', {}).then((response: any) => {
            this.uncreateInvoices = response;
            this.loading = false;
        }).catch((error) => {
            this.$message.error(error);
            this.loading = false;
        });
    }

    private createInvoice(invoice) {
        this.$router.push('/create-invoice?swiftid=' + invoice.swift_id);
    }

    private getCreatedInvoices() {
        this.loading = true;
        this.createdInvoices = [];
        axios.get('/api/v1/invoice-manage/created-invoice-list', {}).then((response: any) => {
            this.createdInvoices = response.list;
            this.channels = response.express_channel;
            this.createdInvoices.forEach((v) => {
                v.address = this.formatAddress(v);
            });
            this.loading = false;
        }).catch((error) => {
            this.loading = false;
            this.$message.error(error);
        });
    }

    private formatAddress(invoice: InvoiceInfo) {
        let address: string = '';
        if (invoice.consignee_name) {
            address = `${address} ${invoice.consignee_name}`;
        }
        if (invoice.consignee_phone) {
            address = `${address} ${invoice.consignee_phone}`;
        }
        if (invoice.consignee_address) {
            if (invoice.consignee_address.province.substr(-1) === '市') {
                invoice.consignee_address.city = invoice.consignee_address.province;
            }
            const tempInvoiceAddress = `${invoice.consignee_address.province}${invoice.consignee_address.city}${invoice.consignee_address.district}${invoice.consignee_address.detail}`;
            address = `${address} ${tempInvoiceAddress}`;
        }
        return address;
    }

    private onCopy() {
        this.$message({
            type: 'success',
            message: '收件地址复制成功',
        });
    }

    private inputExpress(invoice: InvoiceInfo) {
        this.selectedInvoice = invoice;
        this.inputExpressModal.$emit('open');
    }

    private getHistoryInvoices(page = 1) {
        this.queriedCondition.page = page;
        this.loading = true;
        this.historyInvoices = [];
        axios.get('/api/v1/invoice-manage/invoice-history-list', this.queriedCondition).then((response: any) => {
            this.historyInvoices = response.list;
            this.totalHistoryInvoices = response.total;
            this.currentPage = this.queriedCondition.page;
            this.loading = false;
        }).catch((error) => {
            this.loading = false;
            this.$message.error(error);
        });
    }

    private checkInvoiceInfo(invoice: InvoiceInfo) {
        this.selectedInvoice = invoice;
        this.invoiceInfoModal.$emit('open');
    }

    private checkExpressInfo(invoice: InvoiceInfo) {
        this.selectedInvoice = invoice;
        this.expressInfoModal.$emit('open');
    }
}
