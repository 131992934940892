
import { Provide, Component, Ref, Watch, Vue } from 'vue-property-decorator';
import axios from '../../../plugins/axios.js';
import QRCode from 'qrcode';
@Component({})
export default class ReferralPoster extends Vue {
    @Provide() private poster: { [index: string]: any } = {
        path: '',
        phone: '',
        user: {},
    };
    @Provide() private posters: any[] = [];
    @Provide() private posterRules: { [index: string]: object[] } = {
        path: [
            { required: true, message: '请选择海报样式', trigger: 'blur' },
        ],
        phone: [
            { required: true, message: '请填写用户手机号', trigger: 'blur' },
        ],
    };
    @Provide() private isDrawing: boolean = false;
    @Provide() private isPosterDrawn: boolean = false;
    @Provide() private posterCanvasWidth: any = 0;
    @Provide() private posterCanvasHeight: any = 0;
    @Ref() private readonly posterForm;
    @Ref() private readonly posterCanvas;
    @Ref() private readonly qrcodeCanvas;
    @Ref() private readonly posterImage;

    @Watch('poster.path', { deep: true })
    private refreshPoster() {
        this.isPosterDrawn = false;
    }

    private mounted() {
        this.getReferralPosters();
    }

    private getReferralPosters() {
        axios.get('/api/v1/referral/posters').then((posters) => {
            this.posters = posters;
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    private prepareToDrawPoster() {
        this.posterForm.validate((valid) => {
            if (!valid) {
                return;
            }
            this.isDrawing = true;
            axios.get('/api/v1/referral/user', {
                phone: this.poster.phone,
            }).then((user) => {
                this.poster.user = user;
                this.drawPoster();
            }).catch((error) => {
                this.isDrawing = false;
                this.$message.error(error);
            });
        });
    }

    private drawPoster() {
        const poster = document.createElement('img');
        const pixelRatio = window.devicePixelRatio || 1;
        poster.onload = () => {
            const ctx = this.posterCanvas.getContext('2d');
            this.posterCanvasWidth = this.posterCanvas.width;
            this.posterCanvasHeight = this.posterCanvas.width * (poster.height / poster.width);
            this.posterCanvas.width = this.posterCanvasWidth * pixelRatio;
            this.posterCanvas.height = this.posterCanvasHeight * pixelRatio;
            ctx.scale(pixelRatio, pixelRatio);
            ctx.drawImage(poster, 0, 0, poster.width, poster.height, 0, 0, this.posterCanvasWidth, this.posterCanvasHeight);
            ctx.restore();
            this.$nextTick(() => {
                this.drawQrcode();
            });
        };
        poster.src = this.poster.path;
    }

    private drawQrcode() {
        QRCode.toCanvas(this.qrcodeCanvas, `https://www.jisuanke.com/miniapp/referral?currentPage=receive&referrerUuid=${this.poster.user.uuid}`);
        const qrcodeImageUrl = this.qrcodeCanvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
        const qrcodeImage = document.createElement('img');
        qrcodeImage.onload = () => {
            const ctx = this.posterCanvas.getContext('2d');
            const qrcodeWidthRatio = 9.6 / 38.1; // 二维码宽度 / 海报宽度
            const qrcodeHeightRatio = 9.6 / 67.73; // 二维码高度 / 海报高度
            const qrcodeWidth = this.posterCanvasWidth * qrcodeWidthRatio;
            const qrcodeHeight = this.posterCanvasHeight * qrcodeHeightRatio;
            const qrcodeLeftRatio = 23.43 / 38.1; // 二维码左侧距离 / 海报宽度
            const qrcodeTopRatio = 54.83 / 67.73; // 二维码上侧距离 / 海报宽度
            const x = this.posterCanvasWidth * qrcodeLeftRatio;
            const y = this.posterCanvasHeight * qrcodeTopRatio;
            ctx.drawImage(qrcodeImage, 0, 0, qrcodeImage.width, qrcodeImage.height, x, y, qrcodeWidth, qrcodeHeight);
        };
        qrcodeImage.src = qrcodeImageUrl;
        this.isDrawing = false;
        this.isPosterDrawn = true;
    }
}
