
import Vue from 'vue';
import axios from '@/plugins/axios.js';
import { Provide, Component, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class UploadSingleImage extends Vue {
    @Prop() private imageUrl!: any;
    @Prop() private imageTitle!: any;
    @Prop() private imageSizeLimit!: any;
    @Provide() private images: any = [];

    @Watch('images', { deep: true })
    private updateImageUrl() {
        const imageUrl = this.images.length > 0 ? this.images[0].url : '';
        this.$emit('update:imageUrl', imageUrl);
    }

    @Watch('imageUrl', { deep: true })
    private updateImages() {
        this.images = this.imageUrl ? [{
            name: this.imageTitle,
            url: this.imageUrl,
        }] : [];
    }

    private created() {
        this.updateImages();
    }

    private beforeUploadImage(file: any) {
        if (this.images.length > 0) {
            this.$message.error(`只能上传 1 张${this.imageTitle}`);
        } else {
            this.uploadImage(file);
        }
        return false;
    }

    private removeImage(file: any, files: any) {
        this.images = files;
    }

    private uploadImage(file) {
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            this.$message.error('上传图片格式不正确');
            return false;
        }
        const _URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = (() => {
            if (img.width !== this.imageSizeLimit[0] || img.height !== this.imageSizeLimit[1]) {
                return this.$message.error(`图片尺寸限制为 ${this.imageSizeLimit[0]} * ${this.imageSizeLimit[1]}`);
            }
            const params = new FormData();
            params.append('file', file);
            axios.post('/api/v1/common/upload-multimedia', params).then((response: any) => {
                this.$message({
                    type: 'success',
                    message: '上传成功',
                });
                this.images = [{
                    name: this.imageTitle,
                    url: response.file_path,
                }];
            }).catch((error) => {
                this.$message.error(error);
            });
        });
        img.src = _URL.createObjectURL(file);
    }
}
