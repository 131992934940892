
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Prop, Watch, Ref } from 'vue-property-decorator';
import QRCode from 'qrcode';
import QrcodeDecoder from 'qrcode-reader';

interface ImageList {
    name: string;
    url: string;
}

@Component
export default class EditSales extends Vue {
    @Prop() private selectedSales!: any;
    @Provide() private showDialog: boolean = false;
    @Provide() private salesInfo: { [index: string]: any } = {
        id: '',
        name: '',
        level: '',
        qrcode: '',
        dailyGuestLimit: 0,
    };
    @Provide() private rules: { [index: string]: object[] } = {
        dailyGuestLimit: [
            { required: true, message: '单日最大接待量不能为空', trigger: 'blur' },
        ],
    };
    @Provide() private qrcodes: ImageList[] = [];
    @Provide() private levelInfo: object = [
        { text: '无' },
        { text: '初级' },
        { text: '中级' },
        { text: '高级' },
    ];
    @Ref() private readonly salesForm;

    private created() {
        this.$on('open', () => {
            this.showDialog = true;
        });
    }

    @Watch('selectedSales', { deep: true })
    private updateSalesInfo() {
        this.salesInfo.id = this.selectedSales.id;
        this.salesInfo.name = this.selectedSales.name;
        this.salesInfo.level = this.selectedSales.level;
        this.salesInfo.qrcode = this.selectedSales.qrcode;
        this.qrcodes = [];
        if (this.selectedSales.qrcode) {
            this.qrcodes.push({ name: 'qrcode.png', url: this.selectedSales.qrcode });
        }
    }

    private submitEditSales() {
        this.salesForm.validate((valid) => {
            if (!valid) {
                return false;
            } else {
                const url = '/api/v1/crm/edit-employee';
                let params: { [index: string]: any } = {
                    id: this.salesInfo.id,
                    name: this.salesInfo.name,
                    reception_level: this.salesInfo.level,
                };
                if (this.salesInfo.qrcode) {
                    params.qrcode = this.salesInfo.qrcode;
                }
                axios.post(url, params).then(() => {
                    this.$message({
                        type: 'success',
                        message: '保存员工信息成功',
                    });
                    this.showDialog = false;
                    this.$emit('refreshSales');
                }).catch((error) => {
                    this.$message.error(error);
                });
            }
        });
    }
    private removeImage(file: any, files: any) {
        this.qrcodes = files;
        this.salesInfo.qrcode = '';
    }
    private qrcodeUpload(file: any) {
        if (this.qrcodes.length >= 1) {
            this.$message.error('只能上传 1 张二维码');
        } else if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
          this.$message.error('上传图片格式不正确');
        } else {
            const url = '/api/v1/common/upload-multimedia';
            const params = new FormData();
            params.append('file', file);
            axios.post(url, params).then((response: any) => {
                this.createNewQrcode(response.file_path);
            }).catch((error) => {
                this.$message.error(error);
            });
        }
        return false;
    }

    /**
     * 解析原始的微信二维码，根据得到的内容，生成新的二维码
     * @param string 原始二维码的url
     */
    private createNewQrcode(imageUrl: string) {
        const decoder = new QrcodeDecoder();
        decoder.decode(imageUrl);
        decoder.callback = ((vm) => {
            return (error, result) => {
                if (error) {
                    return this.$message.error('请上传包含微信二维码的图片');
                }
                QRCode.toDataURL(result.result, { width: 400 },  ((root) => {
                    return (err, url) => {
                        return root.newQrcodeUpload(url);
                    };
                })(vm));
            };
        })(this);
    }

    /**
     * base64 格式转 file
     * @param string base64
     * @param string 生成文件的文件名
     * return file
     */
    private dataURLtoFile(dataurl, filename) {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    }

    /**
     * 上传新的二维码 (因为是自定义的上传方法，不使用 element-ui 提供的方法，所以最后都返回 false)
     * @param file 上传的文件
     * @returns false
     */
    private newQrcodeUpload(base64Image: any) {
        const url = '/api/v1/common/upload-multimedia';
        const params = new FormData();
        params.append('file', this.dataURLtoFile(base64Image, 'qrcode.png'));
        axios.post(url, params).then((response: any) => {
            this.$message({
                type: 'success',
                message: '上传成功',
            });
            this.qrcodes.push({ name: 'qrcode.png', url: response.file_path });
            this.salesInfo.qrcode = response.file_path;
        }).catch((error) => {
            this.$message.error(error);
        });
        return false;
    }
}
