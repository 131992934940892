
import { Provide, Component, Vue } from 'vue-property-decorator';
import ReferralRecords from './referral/Records.vue';
import ReferralPoster from './referral/Poster.vue';
import ReferralAddEcCustomer from './referral/AddEcCustomer.vue';

@Component({
    components: {
        ReferralRecords,
        ReferralPoster,
        ReferralAddEcCustomer,
    },
})

export default class ReferralManagement extends Vue {
    @Provide() private activeTab: string = 'records';
}
